import React from "react";
const UserSettingIcon = (props) => {
  const { default_color } = props;
  return (
    <svg fill="#000000" width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" transform="rotate(0)" stroke="#000000" stroke-width="0.00024000000000000003">

    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
    
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
    
    <g id="SVGRepo_iconCarrier">
    
    <path d="M2,21h8a1,1,0,0,0,0-2H3.071A7.011,7.011,0,0,1,10,13a5.044,5.044,0,1,0-3.377-1.337A9.01,9.01,0,0,0,1,20,1,1,0,0,0,2,21ZM10,5A3,3,0,1,1,7,8,3,3,0,0,1,10,5ZM22.546,15.6h-.707a.468.468,0,0,1-.428-.3l0-.006a.467.467,0,0,1,.089-.516l.5-.5a.454.454,0,0,0,0-.643l-.63-.63a.454.454,0,0,0-.643,0l-.5.5a.467.467,0,0,1-.516.089l-.006,0a.468.468,0,0,1-.3-.428v-.707A.454.454,0,0,0,18.946,12h-.892a.454.454,0,0,0-.454.454v.707a.468.468,0,0,1-.3.428l-.006,0a.467.467,0,0,1-.516-.089l-.5-.5a.454.454,0,0,0-.643,0l-.63.63a.454.454,0,0,0,0,.643l.5.5a.467.467,0,0,1,.089.516l0,.006a.468.468,0,0,1-.428.3h-.707a.454.454,0,0,0-.454.454v.892a.454.454,0,0,0,.454.454h.707a.468.468,0,0,1,.428.3l0,.006a.467.467,0,0,1-.089.516l-.5.5a.454.454,0,0,0,0,.643l.63.63a.454.454,0,0,0,.643,0l.5-.5a.467.467,0,0,1,.516-.089l.006,0a.468.468,0,0,1,.3.428v.707a.454.454,0,0,0,.454.454h.892a.454.454,0,0,0,.454-.454v-.707a.468.468,0,0,1,.3-.428l.006,0a.467.467,0,0,1,.516.089l.5.5a.454.454,0,0,0,.643,0l.63-.63a.454.454,0,0,0,0-.643l-.5-.5a.467.467,0,0,1-.089-.516l0-.006a.468.468,0,0,1,.428-.3h.707A.454.454,0,0,0,23,16.946v-.892A.454.454,0,0,0,22.546,15.6ZM18.5,17.85a1.35,1.35,0,1,1,1.35-1.35A1.349,1.349,0,0,1,18.5,17.85Z"/>
    
    </g>
    
    </svg>

    // </div>
  );
};
export default UserSettingIcon;