import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/cloudStorage";

const licenseurllocal = "/api/v1/licenseplan";
const adobeurllocal = "/api/v1/adobe";
class otherService {
  setstoragetypeAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  createTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  getstoragetypeAPI = (data) =>
    RestfulProvider.postWithOutCompanyId(`${subUrllocal}/get`, data, headers);
  deletestorageTypeAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/delete`, data, headers);
    getLicencencestoragedataApi= (data) =>
    RestfulProvider.post(`${licenseurllocal}/customstorage`, data, headers);
    setadobeStatusAPI = (data) =>
    RestfulProvider.post(`${adobeurllocal}/useAdobeSign`, data, headers);
}
// eslint-disable-next-line
export default new otherService();
