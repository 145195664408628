import { Button, Col, Result, Row, Spin } from 'antd';
import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import newLogo from "../../assets/images/newLogo.png";
import { QuestionCircleOutlined,ReloadOutlined } from '@ant-design/icons';
import { useParams } from "react-router";

import "./index.less"
import ExtraSection from './LayoutContent/ExtraSection';
import ActivitiesDetail from './LayoutContent/ActivitiesDetails/ActivitiesDetail';
import ApproverDetails from './LayoutContent/ApproverDetails/ApproverDetails';
import { getGuestTaskDetails } from '../TaskmanagementVendor/TaskmanagementVendor.Action';
import ChecklistModal from '../TaskmanagementVendor/Drawer/Detailsview/step2/checklistDetail';
import ContentSection from './LayoutContent/ContentSection';

import SignModal from "../TaskmanagementVendor/models/signature.model";
import Createvendormodel from '../Vendor/Model/CreateAndUpdate';
import { GuestgetChatRoomApi } from '../users/userActions';
import ContractViewModal from '../TaskmanagementVendor/models/contractView';
import { setModalVisible } from '../Vendor/Vendor.Slice';
import isEmpty from "lodash/isEmpty";
const Index = (props) => {
    const dispatch = useDispatch();
    const {
     
      EmailGuestlogin,
      vendor:{isVisible = false,Vendordataforemail={}},
      taskmanagementVendor:{taskTicketDetails = {},isChecklistVisible=false,isDataLoading,isSignatureVisible=false,isContractViewVisible=false},
    } = props;
    const {CurrentselectedItems}=EmailGuestlogin;
    const {guestid,tokenid}=useParams()
    const {vendorDetails={} } = taskTicketDetails;
   
   
    useEffect(() => {
      localStorage.setItem("guestToken",tokenid);
      dispatch(
        getGuestTaskDetails({
          taskId: guestid,
          isGuestUser: true,
         
        })
      );
           // eslint-disable-next-line
    }, [guestid])
 
    useEffect(() => {
       if(vendorDetails?.firstName){
        localStorage.setItem("vendorGuestToken",vendorDetails?.vendorId
        );
     
        dispatch(GuestgetChatRoomApi({
            companyId:taskTicketDetails.companyId,
            is_vendor: true,
          isGuestUser: true,

           vendor_id:vendorDetails._id}))
       }
      
            // eslint-disable-next-line 
      }, [vendorDetails])
      useEffect(() => {
        if(!isEmpty(taskTicketDetails)&&!vendorDetails?.firstName){
          dispatch(
            setModalVisible({
              type: "createfromemail",
              visible: true,
              record: taskTicketDetails?.vendorDetails || {},
            })
          );
        }
       
      }, [taskTicketDetails,dispatch,vendorDetails])
      useEffect(() => {
       
        if(Object.keys(Vendordataforemail).length>0){
        
         localStorage.setItem("vendorGuestToken",vendorDetails?.vendorId
         );
      
         dispatch(GuestgetChatRoomApi({
             companyId:taskTicketDetails.companyId,
             is_vendor: true,
          isGuestUser: true,

            vendor_id:vendorDetails?.vendorId}))
            dispatch(
             getGuestTaskDetails({
               taskId: guestid,
          isGuestUser: true,

              
             })
           );
        }
              // eslint-disable-next-line
       }, [Vendordataforemail])
  return (
    <>
        <Row className='email-login-layout' style={{height:"100vh"}}>
        <Col style={{height:"8vh",borderBottom:"2px solid #ddd",backgroundColor:"#fff"}} span={24}>
       <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",padding:"0px 15px 0px 15px",height:"8vh",}}>
        <div className='logo-onreferesh' onClick={()=>{
           window.location.reload();
        }}  >
        <img className="logo-guest" src={newLogo} alt="logo"  />
        <span className="logo-guest-txt">Verifiablecontract</span>
        </div>
        <div className='header-extra-guest'>
<div className='header-extra-guest-1'>
    <Button className='guestsignin' type='text' href='/login'>
       <span className='guestsignin-text' >Sign in</span> 
    </Button>
</div>
<div>
<QuestionCircleOutlined />
</div>
        </div>
       </div>
        </Col>  
         <Col style={{height:"92vh",borderBottom:"2px solid #ddd"}} span={24}>
         {
          Object.keys(taskTicketDetails)?.length>0||isDataLoading?
          <Row gutter={[0,6]}>
         <Col style={{height:"92vh",borderRight:"2px solid #ddd",textAlign:"left"}} lg={18} md={16}  sm={24}>
         <ContentSection  {...props}/>
         {/* <div>hygfydg</div> */}
         {/* <object className="guest-pdf-viewer" data="https://dev-verifiable-contract.s3.us-west-1.amazonaws.com/17083212626931707374221690.pdf" >Pdf</object> */}
         </Col>  
         <Col className='guestlayout-extra' style={{height:"92vh",maxHeight:"92vh", borderRight:"2px solid #ddd",}} sm={24} lg={6} md={8} >

            <Row >
            <Spin spinning={isDataLoading}>
                <Col className='layout-extra' style={{height:"92vh",maxHeight:"92vh",borderBottom:"2px solid #ddd",overflowY:"scroll"}} span={24} >
                {CurrentselectedItems==="parent"&&<ExtraSection {...props}/>}
                {CurrentselectedItems==="approvers"&&<ApproverDetails {...props}/>}
                {CurrentselectedItems==="activities"&&<ActivitiesDetail {...props}/>}
                
                </Col>
               
                </Spin>

            </Row>
         </Col>
         
         </Row>:
         <>
      <Result
      className='loadingPageerror'
    status="500"
    title="500"
    subTitle="Sorry, something went wrong."
    extra={<Button icon={<ReloadOutlined />} onClick={()=>{
           window.location.reload();
        }}  >Refresh</Button>}
  />
      </>
         }
        
         
         
         </Col> 
         
          
        </Row>
        {isChecklistVisible && <ChecklistModal {...props} />}
        {/* {isSignatureVisible &&
        (user_Detail.signature || vendor_details?.signature) && (
          <SignatureModal {...props} />
        )} */}
      {isSignatureVisible  && (
        <SignModal {...props} />
      )}
      {isVisible && <Createvendormodel {...props} />}
      {isContractViewVisible && <ContractViewModal {...props} />}

    </>
  )
}

export default connect((state) => ({
    user: state.user,
    global: state.global,
    historical_services: state.historical_services,
    EmailGuestlogin:state.EmailGuestlogin,
    taskmanagementVendor: state.taskmanagementVendor,
    vendor: state.vendor,
  }))(injectIntl(Index));
  