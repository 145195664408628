import PropTypes from "prop-types";
import "./centerBox.less";
import React from "react";

function CenterBox(props) {
  const { centerBoxClassName, children } = props;
  return (
    <div className="center-box">
      <div className={`center-box-content ${centerBoxClassName}`}>
        {children}
      </div>
    </div>
  );
}
CenterBox.propTypes = {
  centerBoxClassName: PropTypes.string,
};
CenterBox.defaultProps = {
  centerBoxClassName: "",
};

export default CenterBox;
