import { createSlice } from "@reduxjs/toolkit";
import {
  getVendordetails,
  Createvendor,
  deletevendor,
  GuestCreatevendor,
  FillandUpdateGuestvendordata,
} from "./Vendor.Action";

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  vendorTableData: [],
  isVisible: false,
  editRecord: {},
  type: "",
  vendormDetails: {},
  ispagination: false,
  totalCount: null,
  vendorPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  vendor_model_type: "",
  drawer_record_for_task: {},
  vendor_verifiedFlag: false,

  isGuestVendorVisible: false,
  vendoreditRecord: {},
  guest_vendor_model_type: "",
  guest_drawer_record_for_task: {},
  vendordatacreatedfromhistorical:{},
  Vendordataforemail:{},
  isVendorDatainlimit:{},

};
const vendorSlice = createSlice({
  name: "vendor",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      state.type=payload.type||"";
      state.vendor_model_type = payload.v_type || "";
      state.drawer_record_for_task = payload.DrawerRecord || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    setGuestVendorModalVisible: (state, { payload }) => {
      state.isGuestVendorVisible = payload.visible;
      state.vendoreditRecord = payload.record || {};
      state.guest_vendor_model_type = payload.v_type || "";
      state.guest_drawer_record_for_task = payload.DrawerRecord || {};
      if (!payload.visible) {
        state.vendoreditRecord = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.vendorPagination = {
        ...state.vendorPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.vendorPagination = {
        ...state.vendorPagination,
        search: action.payload.search || "",
      };
    },
    vendordatareset: (state, action) => {
      return initialState;
    },
    setvendordatacreatedfromhistoricalreset: (state, action) => {
     state.vendordatacreatedfromhistorical={}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendordetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVendordetails.fulfilled, (state, { payload }) => {
        state.vendorTableData = payload.data;
        state.isVendorDatainlimit = payload?.licenseQuota?.vendor;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.vendorPagination = {
          ...state.vendorPagination,
          total: payload.total,
        };
      })
      .addCase(getVendordetails.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(Createvendor.pending, (state) => {
        state.isCreateLoading = true;
        state.vendor_verifiedFlag = true;
      })
      .addCase(Createvendor.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        state.isGuestVendorVisible = false;
        state.vendor_verifiedFlag = false;
     
        if(state.type==="createfromhistorical"){
          state.vendordatacreatedfromhistorical=payload?.data||{}
        }
        if(state.type==="createfromemail"){
          state.Vendordataforemail={isnewData:true}
        }
        // responseMessageHandler()
      })
      .addCase(Createvendor.rejected, (state) => {
        state.isCreateLoading = false;
      })
      .addCase(GuestCreatevendor.pending, (state) => {
        state.isCreateLoading = true;
        state.vendor_verifiedFlag = true;
      })
      .addCase(GuestCreatevendor.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        state.isGuestVendorVisible = false;
        state.vendor_verifiedFlag = false;
        // responseMessageHandler()
      })
      .addCase(GuestCreatevendor.rejected, (state) => {
        state.isCreateLoading = false;
      })

      .addCase(deletevendor.pending, (state, { payload }) => {
        //conditions
        // responseMessageHandler()
      })
      .addCase(deletevendor.fulfilled, (state, { payload }) => {
        //conditions
      })
      .addCase(deletevendor.rejected, (state, { payload }) => {
        //conditions
      })
      .addCase(FillandUpdateGuestvendordata.pending, (state) => {
        state.isCreateLoading = true;
        state.vendor_verifiedFlag = true;
      })
      .addCase(FillandUpdateGuestvendordata.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        state.isGuestVendorVisible = false;
        state.vendor_verifiedFlag = false;
     
        if(state.type==="createfromhistorical"){
          state.vendordatacreatedfromhistorical=payload?.data||{}
        }
        if(state.type==="createfromemail"){
          state.Vendordataforemail={isnewData:true}
        }
        // responseMessageHandler()
      })
      .addCase(FillandUpdateGuestvendordata.rejected, (state) => {
        state.isCreateLoading = false;
      })
  },
});
export const {
  setModalVisible,
  vendordatareset,
  onPageChangesFilter,
  onSearchList,
  setPasswordmodal,
  setGuestVendorModalVisible,
  setvendordatacreatedfromhistoricalreset
} = vendorSlice.actions;

export default vendorSlice.reducer;
