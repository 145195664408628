import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/licensePlan";

class licenceService {
  createLicencingDataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
    getLicencingDataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/get`, data, headers);
    deleteLicencingDataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/delete`, data, headers);
}
// eslint-disable-next-line
export default new licenceService();

