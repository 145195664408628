import moment from "moment";
export const dateFormatter = (
  DDMMYYYY,
  format = "DD/MM/YYYY hh:mm A",
  tz = "Asia/Kolkata"
) => {
  return moment(new Date(DDMMYYYY)).format(format);
};
//   export const dateTimezoneFormatter = (
//     DDMMYYYY,
//     format = "DD-MM-YYYY hh:mm A",
//     tz = "Asia/Kolkata"
//   ) => {
//     return momentTimezone.tz(moment(DDMMYYYY), tz).format(format);
//   };
export const dateTimeFormatter = (
  DDMMYYYY,
  format = {
    sameDay: "[Today] hh:mm A",
    lastDay: `[Yesterday] hh:mm A`,
    lastWeek: "DD/MM/YYYY hh:mm A",
    sameElse: "DD/MM/YYYY hh:mm A",
  }
) => {
  return moment(DDMMYYYY).calendar(null, format);
};
export const downloadURL = (uri, name) => {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const findCallNumberonMessage = (text) => {
  var urrled = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im;
  var rx = /^[+-]?\d+$/;
  let regexTest = text.split(" ").filter((eve) => rx.test(eve) === true);
  text =
    regexTest.length && regexTest.length > 0
      ? regexTest.length > 1
        ? regexTest[0] + regexTest[1]
        : regexTest[0]
      : text;
  if (urrled.test(text)) {
    return text;
  } else {
    return undefined;
  }
};

export const findUrlOnMessage = (text) => {
  // var urlRegex =
  //   /(\b(https?|ftp|file:\/\/):[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*):[-A-Z0-9+&@#\u/%?=~_|!:,.;]*[-A-Z0-9+&@#\u/%=~_|])/gi;
  var urlRegex =
    /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?[-A-Z0-9+&@#\u/%?=~_|!:,.;]*[-A-Z0-9+&@#\u/%=~_|]?/gi;
  text = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  text = text.replace(/\r?\n/g, " <br/>");
  return text.replace(urlRegex, function (url) {
    let urlLink =
      url.includes("http://") || url.includes("https://")
        ? url
        : "https://" + url;
    return (
      '<a   target="_blank" rel="noopener noreferrer"  href="' +
      urlLink +
      '">' +
      url +
      "</a>"
    );
  });
};
export const urltoFile = (url, filename, mimeType) => {
  return fetch(url)
    .then((res) => {
      return res.arrayBuffer();
    })
    .then((buf) => {
      let blob = new Blob([buf], { type: mimeType });
      blob.name = filename;
      return blob;
    })
    .catch((err) => {
      console.error("urltoFile", err);
    });
};
export const downloadURLInOtherTab = (uri, name) => {
  let link = document.createElement("a");
  link.download = name;
  link.target = "_blank";
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const checkDeletedFunc = (arr = [], key, value) => {
  
  if (arr && Array.isArray(arr) && arr.length && key) {
    const dataFind = arr.find((fItem) => fItem[key] === value);
    if (dataFind && Object.keys(dataFind).length && dataFind[key]) {
      return dataFind[key];
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};
export const getStringContentFeilds = (stringContents, rTemplateFeilds) => {
 
  let rstd = [];
  const mandatoryfield = rTemplateFeilds.slice(0, 2);
  rstd.push(...mandatoryfield);

  rTemplateFeilds.forEach((eve,index) => {
  
    if ((stringContents.includes(eve.feild_label || eve.label)||eve.value)&&index>1) {
      rstd.push(eve);
    }
  });
  return rstd;
};
export const getStringContentforaddendumFeilds = (stringContents, rTemplateFeilds) => {

  let rstd = [];
 

  rTemplateFeilds.forEach((eve,index) => {
    if (stringContents.includes(eve.feild_label || eve.label)) {
      rstd.push(eve);
    }
  });
  return rstd;
};
