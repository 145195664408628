import { createAsyncThunk } from "@reduxjs/toolkit";
import otherService from "../../Services/OtherService";
import { responseMessageHandler } from "../../globals/components/notification";

export const getcronjobetails = createAsyncThunk(
  "other/getcronjobetails",
  async (data, thunkAPI) => {
    try {
      let res = await otherService.getcronjobetailsAPI();
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setcronjobDuration = createAsyncThunk(
  "other/setcronjobDuration",
  async (data, thunkAPI) => {
    try {
      let res = await otherService.cronjobDurationAPI({
        ...data,
      });
      thunkAPI.dispatch(getcronjobetails());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
