import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/obligation";
const subUrllocal1 = "/api/v1/obligationform";

class obligationService {
    deleteObligationAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/delete`, data, headers);
  createObligationAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  getObligationAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/`, data, headers);
    getObligationFormlistAPI= (data) =>
    RestfulProvider.post(`${subUrllocal1}/`, data, headers);
    createObligationFormAPI= (data) =>
    RestfulProvider.post(`${subUrllocal1}/create`, data, headers);
    deleteObligationFormApi= (data) =>
    RestfulProvider.post(`${subUrllocal1}/delete`, data, headers);
}
// eslint-disable-next-line
export default new obligationService();
