export const countriescodeList =[
    {
      "name": "Andorra",
      "code": "AD",
      "dial_code": "+376",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Andorra"
      ]
    },
    {
      "name": "United Arab Emirates (UAE)",
      "code": "AE",
      "dial_code": "+971",
      "currency_code": "AED",
      "timezones": [
        "Asia/Dubai"
      ]
    },
    {
      "name": "Afghanistan",
      "code": "AF",
      "dial_code": "+93",
      "currency_code": "AFN",
      "timezones": [
        "Asia/Kabul"
      ]
    },
    {
      "name": "Antigua and Barbuda",
      "code": "AG",
      "dial_code": "+1268",
      "currency_code": "XCD",
      "timezones": [
        "America/Antigua"
      ]
    },
    {
      "name": "Anguilla",
      "code": "AI",
      "dial_code": "+1264",
      "currency_code": "XCD",
      "timezones": [
        "America/Anguilla"
      ]
    },
    {
      "name": "Albania",
      "code": "AL",
      "dial_code": "+355",
      "currency_code": "ALL",
      "timezones": [
        "Europe/Tirane"
      ]
    },
    {
      "name": "Armenia",
      "code": "AM",
      "dial_code": "+374",
      "currency_code": "AMD",
      "timezones": [
        "Asia/Yerevan"
      ]
    },
    {
      "name": "Angola",
      "code": "AO",
      "dial_code": "+244",
      "currency_code": "AOA",
      "timezones": [
        "Africa/Luanda"
      ]
    },
    {
      "name": "Antarctica",
      "code": "AQ",
      "dial_code": "+672",
      "currency_code": "",
      "timezones": [
        "Antarctica/McMurdo",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Mawson",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok"
      ]
    },
    {
      "name": "Argentina",
      "code": "AR",
      "dial_code": "+54",
      "currency_code": "ARS",
      "timezones": [
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Cordoba",
        "America/Argentina/Salta",
        "America/Argentina/Jujuy",
        "America/Argentina/Tucuman",
        "America/Argentina/Catamarca",
        "America/Argentina/La_Rioja",
        "America/Argentina/San_Juan",
        "America/Argentina/Mendoza",
        "America/Argentina/San_Luis",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Ushuaia"
      ]
    },
    {
      "name": "American Samoa",
      "code": "AS",
      "dial_code": "+1684",
      "currency_code": "USD",
      "timezones": [
        "Pacific/Pago_Pago"
      ]
    },
    {
      "name": "Austria",
      "code": "AT",
      "dial_code": "+43",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Vienna"
      ]
    },
    {
      "name": "Australia",
      "code": "AU",
      "dial_code": "+61",
      "currency_code": "AUD",
      "timezones": [
        "Australia/Lord_Howe",
        "Antarctica/Macquarie",
        "Australia/Hobart",
        "Australia/Currie",
        "Australia/Melbourne",
        "Australia/Sydney",
        "Australia/Broken_Hill",
        "Australia/Brisbane",
        "Australia/Lindeman",
        "Australia/Adelaide",
        "Australia/Darwin",
        "Australia/Perth",
        "Australia/Eucla"
      ]
    },
    {
      "name": "Aruba",
      "code": "AW",
      "dial_code": "+297",
      "currency_code": "AWG",
      "timezones": [
        "America/Aruba"
      ]
    },
    {
      "name": "Åland Islands",
      "code": "AX",
      "dial_code": "+358",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Mariehamn"
      ]
    },
    {
      "name": "Azerbaijan",
      "code": "AZ",
      "dial_code": "+994",
      "currency_code": "AZN",
      "timezones": [
        "Asia/Baku"
      ]
    },
    {
      "name": "Bahamas The",
      "code": "BS",
      "dial_code": "+994",
      "currency_code": "+1 242",
      "timezones": [
        "Asia/Baku"
      ]
    },
    {
      "name": "Bosnia And Herzegovina",
      "code": "BA",
      "dial_code": "+387",
      "currency_code": "BAM",
      "timezones": [
        "Europe/Sarajevo"
      ]
    },
    {
      "name": "Barbados",
      "code": "BB",
      "dial_code": "+1246",
      "currency_code": "BBD",
      "timezones": [
        "America/Barbados"
      ]
    },
    {
      "name": "Bangladesh",
      "code": "BD",
      "dial_code": "+880",
      "currency_code": "BDT",
      "timezones": [
        "Asia/Dhaka"
      ]
    },
    {
      "name": "Belgium",
      "code": "BE",
      "dial_code": "+32",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Brussels"
      ]
    },
    {
      "name": "Burkina Faso",
      "code": "BF",
      "dial_code": "+226",
      "currency_code": "XOF",
      "timezones": [
        "Africa/Ouagadougou"
      ]
    },
    {
      "name": "Bulgaria",
      "code": "BG",
      "dial_code": "+359",
      "currency_code": "BGN",
      "timezones": [
        "Europe/Sofia"
      ]
    },
    {
      "name": "Bahrain",
      "code": "BH",
      "dial_code": "+973",
      "currency_code": "BHD",
      "timezones": [
        "Asia/Bahrain"
      ]
    },
    {
      "name": "Burundi",
      "code": "BI",
      "dial_code": "+257",
      "currency_code": "BIF",
      "timezones": [
        "Africa/Bujumbura"
      ]
    },
    {
      "name": "Benin",
      "code": "BJ",
      "dial_code": "+229",
      "currency_code": "XOF",
      "timezones": [
        "Africa/Porto-Novo"
      ]
    },
    {
      "name": "Saint Barthélemy",
      "code": "BL",
      "dial_code": "+590",
      "currency_code": "EUR",
      "timezones": [
        "America/St_Barthelemy"
      ]
    },
    {
      "name": "Bermuda",
      "code": "BM",
      "dial_code": "+1441",
      "currency_code": "BMD",
      "timezones": [
        "Atlantic/Bermuda"
      ]
    },
    {
      "name": "Brunei Darussalam",
      "code": "BN",
      "dial_code": "+673",
      "currency_code": "BND",
      "timezones": [
        "Asia/Brunei"
      ]
    },
    {
      "name": "Bolivia, Plurinational State Of",
      "code": "BO",
      "dial_code": "+591",
      "currency_code": "BOB",
      "timezones": [
        "America/La_Paz"
      ]
    },
    {
      "name": "Brazil",
      "code": "BR",
      "dial_code": "+55",
      "currency_code": "BRL",
      "timezones": [
        "America/Noronha",
        "America/Belem",
        "America/Fortaleza",
        "America/Recife",
        "America/Araguaina",
        "America/Maceio",
        "America/Bahia",
        "America/Sao_Paulo",
        "America/Campo_Grande",
        "America/Cuiaba",
        "America/Santarem",
        "America/Porto_Velho",
        "America/Boa_Vista",
        "America/Manaus",
        "America/Eirunepe",
        "America/Rio_Branco"
      ]
    },
    {
      "name": "Bahamas",
      "code": "BS",
      "dial_code": "+1242",
      "currency_code": "BSD",
      "timezones": [
        "America/Nassau"
      ]
    },
    {
      "name": "Bhutan",
      "code": "BT",
      "dial_code": "+975",
      "currency_code": "BTN",
      "timezones": [
        "Asia/Thimphu"
      ]
    },
    {
      "name": "Botswana",
      "code": "BW",
      "dial_code": "+267",
      "currency_code": "BWP",
      "timezones": [
        "Africa/Gaborone"
      ]
    },
    {
      "name": "Belarus",
      "code": "BY",
      "dial_code": "+375",
      "currency_code": "BYR",
      "timezones": [
        "Europe/Minsk"
      ]
    },
    {
      "name": "Belize",
      "code": "BZ",
      "dial_code": "+501",
      "currency_code": "BZD",
      "timezones": [
        "America/Belize"
      ]
    },
    {
      "name": "Canada",
      "code": "CA",
      "dial_code": "+1",
      "currency_code": "CAD",
      "timezones": [
        "America/St_Johns",
        "America/Halifax",
        "America/Glace_Bay",
        "America/Moncton",
        "America/Goose_Bay",
        "America/Blanc-Sablon",
        "America/Toronto",
        "America/Nipigon",
        "America/Thunder_Bay",
        "America/Iqaluit",
        "America/Pangnirtung",
        "America/Atikokan",
        "America/Winnipeg",
        "America/Rainy_River",
        "America/Resolute",
        "America/Rankin_Inlet",
        "America/Regina",
        "America/Swift_Current",
        "America/Edmonton",
        "America/Cambridge_Bay",
        "America/Yellowknife",
        "America/Inuvik",
        "America/Creston",
        "America/Dawson_Creek",
        "America/Fort_Nelson",
        "America/Vancouver",
        "America/Whitehorse",
        "America/Dawson"
      ]
    },
    {
      "name": "Caribbean Netherlands",
      "code": "BQ",
      "dial_code": "+599",
      "currency_code": "USD",
      "timezones": []
    },
    {
      "name": "Cocos (keeling) Islands",
      "code": "CC",
      "dial_code": "+61",
      "currency_code": "AUD",
      "timezones": [
        "Indian/Cocos"
      ]
    },
    {
      "name": "Congo (DRC)",
      "code": "CD",
      "dial_code": "+243",
      "currency_code": "CDF",
      "timezones": [
        "Africa/Kinshasa",
        "Africa/Lubumbashi"
      ]
    },
    {
      "name": "Central African Republic",
      "code": "CF",
      "dial_code": "+236",
      "currency_code": "XAF",
      "timezones": [
        "Africa/Bangui"
      ]
    },
    {
      "name": "Congo (Republic)",
      "code": "CG",
      "dial_code": "+242",
      "currency_code": "XAF",
      "timezones": [
        "Africa/Brazzaville"
      ]
    },
    {
      "name": "Switzerland",
      "code": "CH",
      "dial_code": "+41",
      "currency_code": "CHF",
      "timezones": [
        "Europe/Zurich"
      ]
    },
    {
      "name": "Côte D'ivoire",
      "code": "CI",
      "dial_code": "+225",
      "currency_code": "XOF",
      "timezones": [
        "Africa/Abidjan"
      ]
    },
    {
      "name": "Cook Islands",
      "code": "CK",
      "dial_code": "+682",
      "currency_code": "NZD",
      "timezones": [
        "Pacific/Rarotonga"
      ]
    },
    {
      "name": "Chile",
      "code": "CL",
      "dial_code": "+56",
      "currency_code": "CLP",
      "timezones": [
        "America/Santiago",
        "Pacific/Easter"
      ]
    },
    {
      "name": "Cameroon",
      "code": "CM",
      "dial_code": "+237",
      "currency_code": "XAF",
      "timezones": [
        "Africa/Douala"
      ]
    },
    {
      "name": "China",
      "code": "CN",
      "dial_code": "+86",
      "currency_code": "CNY",
      "timezones": [
        "Asia/Shanghai",
        "Asia/Urumqi"
      ]
    },
    {
      "name": "Colombia",
      "code": "CO",
      "dial_code": "+57",
      "currency_code": "COP",
      "timezones": [
        "America/Bogota"
      ]
    },
    {
      "name": "Costa Rica",
      "code": "CR",
      "dial_code": "+506",
      "currency_code": "CRC",
      "timezones": [
        "America/Costa_Rica"
      ]
    },
    {
      "name": "Cuba",
      "code": "CU",
      "dial_code": "+53",
      "currency_code": "CUP",
      "timezones": [
        "America/Havana"
      ]
    },
    {
      "name": "Cape Verde",
      "code": "CV",
      "dial_code": "+238",
      "currency_code": "CVE",
      "timezones": [
        "Atlantic/Cape_Verde"
      ]
    },
    {
      "name": "Curaçao",
      "code": "CW",
      "dial_code": "+599",
      "currency_code": "ANG",
      "timezones": [
        "America/Curacao"
      ]
    },
    {
      "name": "Christmas Island",
      "code": "CX",
      "dial_code": "+61",
      "currency_code": "AUD",
      "timezones": [
        "Indian/Christmas"
      ]
    },
    {
      "name": "Cyprus",
      "code": "CY",
      "dial_code": "+357",
      "currency_code": "EUR",
      "timezones": [
        "Asia/Nicosia"
      ]
    },
    {
      "name": "Czech Republic",
      "code": "CZ",
      "dial_code": "+420",
      "currency_code": "CZK",
      "timezones": [
        "Europe/Prague"
      ]
    },
    {
      "name": "Germany",
      "code": "DE",
      "dial_code": "+49",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Berlin",
        "Europe/Busingen"
      ]
    },
    {
      "name": "Djibouti",
      "code": "DJ",
      "dial_code": "+253",
      "currency_code": "DJF",
      "timezones": [
        "Africa/Djibouti"
      ]
    },
    {
      "name": "Denmark",
      "code": "DK",
      "dial_code": "+45",
      "currency_code": "DKK",
      "timezones": [
        "Europe/Copenhagen"
      ]
    },
    {
      "name": "Dominica",
      "code": "DM",
      "dial_code": "+1767",
      "currency_code": "XCD",
      "timezones": [
        "America/Dominica"
      ]
    },
    {
      "name": "Dominican Republic",
      "code": "DO",
      "dial_code": "+1",
      "currency_code": "DOP",
      "timezones": [
        "America/Santo_Domingo"
      ]
    },
    {
      "name": "Algeria",
      "code": "DZ",
      "dial_code": "+213",
      "currency_code": "DZD",
      "timezones": [
        "Africa/Algiers"
      ]
    },
    {
      "name": "Ecuador",
      "code": "EC",
      "dial_code": "+593",
      "currency_code": "USD",
      "timezones": [
        "America/Guayaquil",
        "Pacific/Galapagos"
      ]
    },
    {
      "name": "Estonia",
      "code": "EE",
      "dial_code": "+372",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Tallinn"
      ]
    },
    {
      "name": "Egypt",
      "code": "EG",
      "dial_code": "+20",
      "currency_code": "EGP",
      "timezones": [
        "Africa/Cairo"
      ]
    },
    {
      "name": "Eritrea",
      "code": "ER",
      "dial_code": "+291",
      "currency_code": "ERN",
      "timezones": [
        "Africa/Asmara"
      ]
    },
    {
      "name": "Spain",
      "code": "ES",
      "dial_code": "+34",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Madrid",
        "Africa/Ceuta",
        "Atlantic/Canary"
      ]
    },
    {
      "name": "Ethiopia",
      "code": "ET",
      "dial_code": "+251",
      "currency_code": "ETB",
      "timezones": [
        "Africa/Addis_Ababa"
      ]
    },
    {
      "name": "Finland",
      "code": "FI",
      "dial_code": "+358",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Helsinki"
      ]
    },
    {
      "name": "Fiji",
      "code": "FJ",
      "dial_code": "+679",
      "currency_code": "FJD",
      "timezones": [
        "Pacific/Fiji"
      ]
    },
    {
      "name": "Falkland Islands (malvinas)",
      "code": "FK",
      "dial_code": "+500",
      "currency_code": "FKP",
      "timezones": [
        "Atlantic/Stanley"
      ]
    },
    {
      "name": "Micronesia, Federated States Of",
      "code": "FM",
      "dial_code": "+691",
      "currency_code": "USD",
      "timezones": [
        "Pacific/Chuuk",
        "Pacific/Pohnpei",
        "Pacific/Kosrae"
      ]
    },
    {
      "name": "Faroe Islands",
      "code": "FO",
      "dial_code": "+298",
      "currency_code": "DKK",
      "timezones": [
        "Atlantic/Faroe"
      ]
    },
    {
      "name": "France",
      "code": "FR",
      "dial_code": "+33",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Paris"
      ]
    },
    {
      "name": "Gabon",
      "code": "GA",
      "dial_code": "+241",
      "currency_code": "XAF",
      "timezones": [
        "Africa/Libreville"
      ]
    },
    {
      "name": "United Kingdom",
      "code": "GB",
      "dial_code": "+44",
      "currency_code": "GBP",
      "timezones": [
        "Europe/London"
      ]
    },
    {
      "name": "Grenada",
      "code": "GD",
      "dial_code": "+1473",
      "currency_code": "XCD",
      "timezones": [
        "America/Grenada"
      ]
    },
    {
      "name": "Georgia",
      "code": "GE",
      "dial_code": "+995",
      "currency_code": "GEL",
      "timezones": [
        "Asia/Tbilisi"
      ]
    },
    {
      "name": "French Guyana",
      "code": "GF",
      "dial_code": "+594",
      "currency_code": "EUR",
      "timezones": [
        "America/Cayenne"
      ]
    },
    {
      "name": "Ghana",
      "code": "GH",
      "dial_code": "+233",
      "currency_code": "GHS",
      "timezones": [
        "Africa/Accra"
      ]
    },
    {
      "name": "Gibraltar",
      "code": "GI",
      "dial_code": "+350",
      "currency_code": "GIP",
      "timezones": [
        "Europe/Gibraltar"
      ]
    },
    {
      "name": "Greenland",
      "code": "GL",
      "dial_code": "+299",
      "currency_code": "DKK",
      "timezones": [
        "America/Godthab",
        "America/Danmarkshavn",
        "America/Scoresbysund",
        "America/Thule"
      ]
    },
    {
      "name": "Gambia",
      "code": "GM",
      "dial_code": "+220",
      "currency_code": "GMD",
      "timezones": [
        "Africa/Banjul"
      ]
    },
    {
      "name": "Guinea",
      "code": "GN",
      "dial_code": "+224",
      "currency_code": "GNF",
      "timezones": [
        "Africa/Conakry"
      ]
    },
    {
      "name": "Guadeloupe",
      "code": "GP",
      "dial_code": "+590",
      "currency_code": "EUR",
      "timezones": [
        "America/Guadeloupe"
      ]
    },
    {
      "name": "Equatorial Guinea",
      "code": "GQ",
      "dial_code": "+240",
      "currency_code": "XAF",
      "timezones": [
        "Africa/Malabo"
      ]
    },
    {
      "name": "Greece",
      "code": "GR",
      "dial_code": "+30",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Athens"
      ]
    },
    {
      "name": "Guatemala",
      "code": "GT",
      "dial_code": "+502",
      "currency_code": "GTQ",
      "timezones": [
        "America/Guatemala"
      ]
    },
    {
      "name": "Guernsey",
      "code": "GG",
      "dial_code": "+44",
      "currency_code": "GBP",
      "timezones": [
        "Europe/Guernsey"
      ]
    },
    {
      "name": "Guam",
      "code": "GU",
      "dial_code": "+1671",
      "currency_code": "USD",
      "timezones": [
        "Pacific/Guam"
      ]
    },
    {
      "name": "Guinea-bissau",
      "code": "GW",
      "dial_code": "+245",
      "currency_code": "XOF",
      "timezones": [
        "Africa/Bissau"
      ]
    },
    {
      "name": "Guyana",
      "code": "GY",
      "dial_code": "+592",
      "currency_code": "GYD",
      "timezones": [
        "America/Guyana"
      ]
    },
    {
      "name": "Hong Kong",
      "code": "HK",
      "dial_code": "+852",
      "currency_code": "HKD",
      "timezones": [
        "Asia/Hong_Kong"
      ]
    },
    {
      "name": "Honduras",
      "code": "HN",
      "dial_code": "+504",
      "currency_code": "HNL",
      "timezones": [
        "America/Tegucigalpa"
      ]
    },
    {
      "name": "Croatia",
      "code": "HR",
      "dial_code": "+385",
      "currency_code": "HRK",
      "timezones": [
        "Europe/Zagreb"
      ]
    },
    {
      "name": "Haiti",
      "code": "HT",
      "dial_code": "+509",
      "currency_code": "HTG",
      "timezones": [
        "America/Port-au-Prince"
      ]
    },
    {
      "name": "Hungary",
      "code": "HU",
      "dial_code": "+36",
      "currency_code": "HUF",
      "timezones": [
        "Europe/Budapest"
      ]
    },
    {
      "name": "Indonesia",
      "code": "ID",
      "dial_code": "+62",
      "currency_code": "IDR",
      "timezones": [
        "Asia/Jakarta",
        "Asia/Pontianak",
        "Asia/Makassar",
        "Asia/Jayapura"
      ]
    },
    {
      "name": "Ireland",
      "code": "IE",
      "dial_code": "+353",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Dublin"
      ]
    },
    {
      "name": "Israel",
      "code": "IL",
      "dial_code": "+972",
      "currency_code": "ILS",
      "timezones": [
        "Asia/Jerusalem"
      ]
    },
    {
      "name": "Isle Of Man",
      "code": "IM",
      "dial_code": "+44",
      "currency_code": "GBP",
      "timezones": [
        "Europe/Isle_of_Man"
      ]
    },
    {
      "name": "Iceland",
      "code": "IS",
      "dial_code": "+354",
      "currency_code": "ISK",
      "timezones": [
        "Atlantic/Reykjavik"
      ]
    },
    {
      "name": "India",
      "code": "IN",
      "dial_code": "+91",
      "currency_code": "INR",
      "timezones": [
        "Asia/Kolkata"
      ]
    },
    {
      "name": "British Indian Ocean Territory",
      "code": "IO",
      "dial_code": "+246",
      "currency_code": "USD",
      "timezones": [
        "Indian/Chagos"
      ]
    },
    {
      "name": "Iraq",
      "code": "IQ",
      "dial_code": "+964",
      "currency_code": "IQD",
      "timezones": [
        "Asia/Baghdad"
      ]
    },
    {
      "name": "Iran, Islamic Republic Of",
      "code": "IR",
      "dial_code": "+98",
      "currency_code": "IRR",
      "timezones": [
        "Asia/Tehran"
      ]
    },
    {
      "name": "Italy",
      "code": "IT",
      "dial_code": "+39",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Rome"
      ]
    },
    {
      "name": "Jersey ",
      "code": "JE",
      "dial_code": "+44",
      "currency_code": "GBP",
      "timezones": [
        "Europe/Jersey"
      ]
    },
    {
      "name": "Jamaica",
      "code": "JM",
      "dial_code": "+1876",
      "currency_code": "JMD",
      "timezones": [
        "America/Jamaica"
      ]
    },
    {
      "name": "Jordan",
      "code": "JO",
      "dial_code": "+962",
      "currency_code": "JOD",
      "timezones": [
        "Asia/Amman"
      ]
    },
    {
      "name": "Japan",
      "code": "JP",
      "dial_code": "+81",
      "currency_code": "JPY",
      "timezones": [
        "Asia/Tokyo"
      ]
    },
    {
      "name": "Kenya",
      "code": "KE",
      "dial_code": "+254",
      "currency_code": "KES",
      "timezones": [
        "Africa/Nairobi"
      ]
    },
    {
      "name": "Kyrgyzstan",
      "code": "KG",
      "dial_code": "+996",
      "currency_code": "KGS",
      "timezones": [
        "Asia/Bishkek"
      ]
    },
    {
      "name": "Cambodia",
      "code": "KH",
      "dial_code": "+855",
      "currency_code": "KHR",
      "timezones": [
        "Asia/Phnom_Penh"
      ]
    },
    {
      "name": "Kiribati",
      "code": "KI",
      "dial_code": "+686",
      "currency_code": "AUD",
      "timezones": [
        "Pacific/Tarawa",
        "Pacific/Enderbury",
        "Pacific/Kiritimati"
      ]
    },
    {
      "name": "Comoros",
      "code": "KM",
      "dial_code": "+269",
      "currency_code": "KMF",
      "timezones": [
        "Indian/Comoro"
      ]
    },
    {
      "name": "Saint Kitts and Nevis",
      "code": "KN",
      "dial_code": "+1869",
      "currency_code": "XCD",
      "timezones": [
        "America/St_Kitts"
      ]
    },
    {
      "name": "North Korea",
      "code": "KP",
      "dial_code": "+850",
      "currency_code": "KPW",
      "timezones": [
        "Asia/Pyongyang"
      ]
    },
    {
      "name": "South Korea",
      "code": "KR",
      "dial_code": "+82",
      "currency_code": "KRW",
      "timezones": [
        "Asia/Seoul"
      ]
    },
    {
      "name": "Kuwait",
      "code": "KW",
      "dial_code": "+965",
      "currency_code": "KWD",
      "timezones": [
        "Asia/Kuwait"
      ]
    },
    {
      "name": "Cayman Islands",
      "code": "KY",
      "dial_code": "+1345",
      "currency_code": "KYD",
      "timezones": [
        "America/Cayman"
      ]
    },
    {
      "name": "Kazakhstan",
      "code": "KZ",
      "dial_code": "+7",
      "currency_code": "KZT",
      "timezones": [
        "Asia/Almaty",
        "Asia/Qyzylorda",
        "Asia/Aqtobe",
        "Asia/Aqtau",
        "Asia/Oral"
      ]
    },
    {
      "name": "Lao People's Democratic Republic",
      "code": "LA",
      "dial_code": "+856",
      "currency_code": "LAK",
      "timezones": [
        "Asia/Vientiane"
      ]
    },
    {
      "name": "Lebanon",
      "code": "LB",
      "dial_code": "+961",
      "currency_code": "LBP",
      "timezones": [
        "Asia/Beirut"
      ]
    },
    {
      "name": "Saint Lucia",
      "code": "LC",
      "dial_code": "+1758",
      "currency_code": "XCD",
      "timezones": [
        "America/St_Lucia"
      ]
    },
    {
      "name": "Liechtenstein",
      "code": "LI",
      "dial_code": "+423",
      "currency_code": "CHF",
      "timezones": [
        "Europe/Vaduz"
      ]
    },
    {
      "name": "Sri Lanka",
      "code": "LK",
      "dial_code": "+94",
      "currency_code": "LKR",
      "timezones": [
        "Asia/Colombo"
      ]
    },
    {
      "name": "Liberia",
      "code": "LR",
      "dial_code": "+231",
      "currency_code": "LRD",
      "timezones": [
        "Africa/Monrovia"
      ]
    },
    {
      "name": "Lesotho",
      "code": "LS",
      "dial_code": "+266",
      "currency_code": "LSL",
      "timezones": [
        "Africa/Maseru"
      ]
    },
    {
      "name": "Lithuania",
      "code": "LT",
      "dial_code": "+370",
      "currency_code": "LTL",
      "timezones": [
        "Europe/Vilnius"
      ]
    },
    {
      "name": "Luxembourg",
      "code": "LU",
      "dial_code": "+352",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Luxembourg"
      ]
    },
    {
      "name": "Latvia",
      "code": "LV",
      "dial_code": "+371",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Riga"
      ]
    },
    {
      "name": "Libya",
      "code": "LY",
      "dial_code": "+218",
      "currency_code": "LYD",
      "timezones": [
        "Africa/Tripoli"
      ]
    },
    {
      "name": "Morocco",
      "code": "MA",
      "dial_code": "+212",
      "currency_code": "MAD",
      "timezones": [
        "Africa/Casablanca"
      ]
    },
    {
      "name": "Monaco",
      "code": "MC",
      "dial_code": "+377",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Monaco"
      ]
    },
    {
      "name": "Moldova, Republic Of",
      "code": "MD",
      "dial_code": "+373",
      "currency_code": "MDL",
      "timezones": [
        "Europe/Chisinau"
      ]
    },
    {
      "name": "Montenegro",
      "code": "ME",
      "dial_code": "+382",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Podgorica"
      ]
    },
    {
      "name": "Saint Martin",
      "code": "MF",
      "dial_code": "+590",
      "currency_code": "EUR",
      "timezones": [
        "America/Marigot"
      ]
    },
    {
      "name": "Madagascar",
      "code": "MG",
      "dial_code": "+261",
      "currency_code": "MGA",
      "timezones": [
        "Indian/Antananarivo"
      ]
    },
    {
      "name": "Marshall Islands",
      "code": "MH",
      "dial_code": "+692",
      "currency_code": "USD",
      "timezones": [
        "Pacific/Majuro",
        "Pacific/Kwajalein"
      ]
    },
    {
      "name": "Macedonia (FYROM)",
      "code": "MK",
      "dial_code": "+389",
      "currency_code": "MKD",
      "timezones": [
        "Europe/Skopje"
      ]
    },
    {
      "name": "Mali",
      "code": "ML",
      "dial_code": "+223",
      "currency_code": "XOF",
      "timezones": [
        "Africa/Bamako"
      ]
    },
    {
      "name": "Myanmar",
      "code": "MM",
      "dial_code": "+95",
      "currency_code": "MMK",
      "timezones": [
        "Asia/Rangoon"
      ]
    },
    {
      "name": "Mongolia",
      "code": "MN",
      "dial_code": "+976",
      "currency_code": "MNT",
      "timezones": [
        "Asia/Ulaanbaatar",
        "Asia/Hovd",
        "Asia/Choibalsan"
      ]
    },
    {
      "name": "Macau",
      "code": "MO",
      "dial_code": "+853",
      "currency_code": "MOP",
      "timezones": [
        "Asia/Macau"
      ]
    },
    {
      "name": "Northern Mariana Islands",
      "code": "MP",
      "dial_code": "+1670",
      "currency_code": "USD",
      "timezones": [
        "Pacific/Saipan"
      ]
    },
    {
      "name": "Martinique",
      "code": "MQ",
      "dial_code": "+596",
      "currency_code": "EUR",
      "timezones": [
        "America/Martinique"
      ]
    },
    {
      "name": "Mauritania",
      "code": "MR",
      "dial_code": "+222",
      "currency_code": "MRO",
      "timezones": [
        "Africa/Nouakchott"
      ]
    },
    {
      "name": "Montserrat",
      "code": "MS",
      "dial_code": "+1664",
      "currency_code": "XCD",
      "timezones": [
        "America/Montserrat"
      ]
    },
    {
      "name": "Malta",
      "code": "MT",
      "dial_code": "+356",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Malta"
      ]
    },
    {
      "name": "Mauritius",
      "code": "MU",
      "dial_code": "+230",
      "currency_code": "MUR",
      "timezones": [
        "Indian/Mauritius"
      ]
    },
    {
      "name": "Maldives",
      "code": "MV",
      "dial_code": "+960",
      "currency_code": "MVR",
      "timezones": [
        "Indian/Maldives"
      ]
    },
    {
      "name": "Malawi",
      "code": "MW",
      "dial_code": "+265",
      "currency_code": "MWK",
      "timezones": [
        "Africa/Blantyre"
      ]
    },
    {
      "name": "Mayotte",
      "code": "YT",
      "dial_code": "+262",
      "currency_code": "EUR",
      "timezones": [
        "Indian/Mayotte"
      ]
    },
    {
      "name": "Mexico",
      "code": "MX",
      "dial_code": "+52",
      "currency_code": "MXN",
      "timezones": [
        "America/Mexico_City",
        "America/Cancun",
        "America/Merida",
        "America/Monterrey",
        "America/Matamoros",
        "America/Mazatlan",
        "America/Chihuahua",
        "America/Ojinaga",
        "America/Hermosillo",
        "America/Tijuana",
        "America/Bahia_Banderas"
      ]
    },
    {
      "name": "Malaysia",
      "code": "MY",
      "dial_code": "+60",
      "currency_code": "MYR",
      "timezones": [
        "Asia/Kuala_Lumpur",
        "Asia/Kuching"
      ]
    },
    {
      "name": "Mozambique",
      "code": "MZ",
      "dial_code": "+258",
      "currency_code": "MZN",
      "timezones": [
        "Africa/Maputo"
      ]
    },
    {
      "name": "Namibia",
      "code": "NA",
      "dial_code": "+264",
      "currency_code": "NAD",
      "timezones": [
        "Africa/Windhoek"
      ]
    },
    {
      "name": "New Caledonia",
      "code": "NC",
      "dial_code": "+687",
      "currency_code": "XPF",
      "timezones": [
        "Pacific/Noumea"
      ]
    },
    {
      "name": "Niger",
      "code": "NE",
      "dial_code": "+227",
      "currency_code": "XOF",
      "timezones": [
        "Africa/Niamey"
      ]
    },
    {
      "name": "Norfolk Islands",
      "code": "NF",
      "dial_code": "+672",
      "currency_code": "AUD",
      "timezones": [
        "Pacific/Norfolk"
      ]
    },
    {
      "name": "Nigeria",
      "code": "NG",
      "dial_code": "+234",
      "currency_code": "NGN",
      "timezones": [
        "Africa/Lagos"
      ]
    },
    {
      "name": "Nicaragua",
      "code": "NI",
      "dial_code": "+505",
      "currency_code": "NIO",
      "timezones": [
        "America/Managua"
      ]
    },
    {
      "name": "Netherlands",
      "code": "NL",
      "dial_code": "+31",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Amsterdam"
      ]
    },
    {
      "name": "Norway",
      "code": "NO",
      "dial_code": "+47",
      "currency_code": "NOK",
      "timezones": [
        "Europe/Oslo"
      ]
    },
    {
      "name": "Nepal",
      "code": "NP",
      "dial_code": "+977",
      "currency_code": "NPR",
      "timezones": [
        "Asia/Kathmandu"
      ]
    },
    {
      "name": "Nauru",
      "code": "NR",
      "dial_code": "+674",
      "currency_code": "AUD",
      "timezones": [
        "Pacific/Nauru"
      ]
    },
    {
      "name": "Niue",
      "code": "NU",
      "dial_code": "+683",
      "currency_code": "NZD",
      "timezones": [
        "Pacific/Niue"
      ]
    },
    {
      "name": "New Zealand",
      "code": "NZ",
      "dial_code": "+64",
      "currency_code": "NZD",
      "timezones": [
        "Pacific/Auckland",
        "Pacific/Chatham"
      ]
    },
    {
      "name": "Oman",
      "code": "OM",
      "dial_code": "+968",
      "currency_code": "OMR",
      "timezones": [
        "Asia/Muscat"
      ]
    },
    {
      "name": "Panama",
      "code": "PA",
      "dial_code": "+507",
      "currency_code": "PAB",
      "timezones": [
        "America/Panama"
      ]
    },
    {
      "name": "Peru",
      "code": "PE",
      "dial_code": "+51",
      "currency_code": "PEN",
      "timezones": [
        "America/Lima"
      ]
    },
    {
      "name": "French Polynesia",
      "code": "PF",
      "dial_code": "+689",
      "currency_code": "XPF",
      "timezones": [
        "Pacific/Tahiti",
        "Pacific/Marquesas",
        "Pacific/Gambier"
      ]
    },
    {
      "name": "Papua New Guinea",
      "code": "PG",
      "dial_code": "+675",
      "currency_code": "PGK",
      "timezones": [
        "Pacific/Port_Moresby",
        "Pacific/Bougainville"
      ]
    },
    {
      "name": "Philippines",
      "code": "PH",
      "dial_code": "+63",
      "currency_code": "PHP",
      "timezones": [
        "Asia/Manila"
      ]
    },
    {
      "name": "Pakistan",
      "code": "PK",
      "dial_code": "+92",
      "currency_code": "PKR",
      "timezones": [
        "Asia/Karachi"
      ]
    },
    {
      "name": "Poland",
      "code": "PL",
      "dial_code": "+48",
      "currency_code": "PLN",
      "timezones": [
        "Europe/Warsaw"
      ]
    },
    {
      "name": "Saint Pierre And Miquelon",
      "code": "PM",
      "dial_code": "+508",
      "currency_code": "EUR",
      "timezones": [
        "America/Miquelon"
      ]
    },
    {
      "name": "Pitcairn Islands",
      "code": "PN",
      "dial_code": "+870",
      "currency_code": "NZD",
      "timezones": [
        "Pacific/Pitcairn"
      ]
    },
    {
      "name": "Puerto Rico",
      "code": "PR",
      "dial_code": "+1",
      "currency_code": "USD",
      "timezones": [
        "America/Puerto_Rico"
      ]
    },
    {
      "name": "Palestine",
      "code": "PS",
      "dial_code": "+970",
      "currency_code": "ILS",
      "timezones": [
        "Asia/Gaza",
        "Asia/Hebron"
      ]
    },
    {
      "name": "Portugal",
      "code": "PT",
      "dial_code": "+351",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Lisbon",
        "Atlantic/Madeira",
        "Atlantic/Azores"
      ]
    },
    {
      "name": "Palau",
      "code": "PW",
      "dial_code": "+680",
      "currency_code": "USD",
      "timezones": [
        "Pacific/Palau"
      ]
    },
    {
      "name": "Paraguay",
      "code": "PY",
      "dial_code": "+595",
      "currency_code": "PYG",
      "timezones": [
        "America/Asuncion"
      ]
    },
    {
      "name": "Qatar",
      "code": "QA",
      "dial_code": "+974",
      "currency_code": "QAR",
      "timezones": [
        "Asia/Qatar"
      ]
    },
    {
      "name": "Réunion",
      "code": "RE",
      "dial_code": "+262",
      "currency_code": "EUR",
      "timezones": [
        "Indian/Reunion"
      ]
    },
    {
      "name": "Romania",
      "code": "RO",
      "dial_code": "+40",
      "currency_code": "RON",
      "timezones": [
        "Europe/Bucharest"
      ]
    },
    {
      "name": "Serbia",
      "code": "RS",
      "dial_code": "+381",
      "currency_code": "RSD",
      "timezones": [
        "Europe/Belgrade"
      ]
    },
    {
      "name": "Russian Federation",
      "code": "RU",
      "dial_code": "+7",
      "currency_code": "RUB",
      "timezones": [
        "Europe/Kaliningrad",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Kirov",
        "Europe/Astrakhan",
        "Europe/Samara",
        "Europe/Ulyanovsk",
        "Asia/Yekaterinburg",
        "Asia/Omsk",
        "Asia/Novosibirsk",
        "Asia/Barnaul",
        "Asia/Tomsk",
        "Asia/Novokuznetsk",
        "Asia/Krasnoyarsk",
        "Asia/Irkutsk",
        "Asia/Chita",
        "Asia/Yakutsk",
        "Asia/Khandyga",
        "Asia/Vladivostok",
        "Asia/Ust-Nera",
        "Asia/Magadan",
        "Asia/Sakhalin",
        "Asia/Srednekolymsk",
        "Asia/Kamchatka",
        "Asia/Anadyr"
      ]
    },
    {
      "name": "Rwanda",
      "code": "RW",
      "dial_code": "+250",
      "currency_code": "RWF",
      "timezones": [
        "Africa/Kigali"
      ]
    },
    {
      "name": "Saudi Arabia",
      "code": "SA",
      "dial_code": "+966",
      "currency_code": "SAR",
      "timezones": [
        "Asia/Riyadh"
      ]
    },
    {
      "name": "Solomon Islands",
      "code": "SB",
      "dial_code": "+677",
      "currency_code": "SBD",
      "timezones": [
        "Pacific/Guadalcanal"
      ]
    },
    {
      "name": "Seychelles",
      "code": "SC",
      "dial_code": "+248",
      "currency_code": "SCR",
      "timezones": [
        "Indian/Mahe"
      ]
    },
    {
      "name": "Sudan",
      "code": "SD",
      "dial_code": "+249",
      "currency_code": "SDG",
      "timezones": [
        "Africa/Khartoum"
      ]
    },
    {
      "name": "Sweden",
      "code": "SE",
      "dial_code": "+46",
      "currency_code": "SEK",
      "timezones": [
        "Europe/Stockholm"
      ]
    },
    {
      "name": "Singapore",
      "code": "SG",
      "dial_code": "+65",
      "currency_code": "SGD",
      "timezones": [
        "Asia/Singapore"
      ]
    },
    {
      "name": "Saint Helena, Ascension And Tristan Da Cunha",
      "code": "SH",
      "dial_code": "+290",
      "currency_code": "SHP",
      "timezones": []
    },
    {
      "name": "Slovenia",
      "code": "SI",
      "dial_code": "+386",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Ljubljana"
      ]
    },
    {
      "name": "Slovakia",
      "code": "SK",
      "dial_code": "+421",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Bratislava"
      ]
    },
    {
      "name": "Sierra Leone",
      "code": "SL",
      "dial_code": "+232",
      "currency_code": "SLL",
      "timezones": [
        "Africa/Freetown"
      ]
    },
    {
      "name": "San Marino",
      "code": "SM",
      "dial_code": "+378",
      "currency_code": "EUR",
      "timezones": [
        "Europe/San_Marino"
      ]
    },
    {
      "name": "Senegal",
      "code": "SN",
      "dial_code": "+221",
      "currency_code": "XOF",
      "timezones": [
        "Africa/Dakar"
      ]
    },
    {
      "name": "Somalia",
      "code": "SO",
      "dial_code": "+252",
      "currency_code": "SOS",
      "timezones": [
        "Africa/Mogadishu"
      ]
    },
    {
      "name": "Svalbard and Jan Mayen",
      "code": "SJ",
      "dial_code": "+47",
      "currency_code": "NOK",
      "timezones": [
        "Arctic/Longyearbyen"
      ]
    },
    {
      "name": "Suri",
      "code": "SR",
      "dial_code": "+597",
      "currency_code": "SRD",
      "timezones": [
        "America/Paramaribo"
      ]
    },
    {
      "name": "South Sudan",
      "code": "SS",
      "dial_code": "+211",
      "currency_code": "SSP",
      "timezones": [
        "Africa/Juba"
      ]
    },
    {
      "name": "Sao Tome And Principe",
      "code": "ST",
      "dial_code": "+239",
      "currency_code": "STD",
      "timezones": [
        "Africa/Sao_Tome"
      ]
    },
    {
      "name": "El Salvador",
      "code": "SV",
      "dial_code": "+503",
      "currency_code": "USD",
      "timezones": [
        "America/El_Salvador"
      ]
    },
    {
      "name": "Sint Maarten",
      "code": "SX",
      "dial_code": "+1721",
      "currency_code": "ANG",
      "timezones": [
        "America/Lower_Princes"
      ]
    },
    {
      "name": "Syrian Arab Republic",
      "code": "SY",
      "dial_code": "+963",
      "currency_code": "SYP",
      "timezones": [
        "Asia/Damascus"
      ]
    },
    {
      "name": "Swaziland",
      "code": "SZ",
      "dial_code": "+268",
      "currency_code": "SZL",
      "timezones": [
        "Africa/Mbabane"
      ]
    },
    {
      "name": "Turks and Caicos Islands",
      "code": "TC",
      "dial_code": "+1649",
      "currency_code": "USD",
      "timezones": [
        "America/Grand_Turk"
      ]
    },
    {
      "name": "Chad",
      "code": "TD",
      "dial_code": "+235",
      "currency_code": "XAF",
      "timezones": [
        "Africa/Ndjamena"
      ]
    },
    {
      "name": "Togo",
      "code": "TG",
      "dial_code": "+228",
      "currency_code": "XOF",
      "timezones": [
        "Africa/Lome"
      ]
    },
    {
      "name": "Thailand",
      "code": "TH",
      "dial_code": "+66",
      "currency_code": "THB",
      "timezones": [
        "Asia/Bangkok"
      ]
    },
    {
      "name": "Tajikistan",
      "code": "TJ",
      "dial_code": "+992",
      "currency_code": "TJS",
      "timezones": [
        "Asia/Dushanbe"
      ]
    },
    {
      "name": "Tokelau",
      "code": "TK",
      "dial_code": "+690",
      "currency_code": "NZD",
      "timezones": [
        "Pacific/Fakaofo"
      ]
    },
    {
      "name": "Timor-leste",
      "code": "TL",
      "dial_code": "+670",
      "currency_code": "USD",
      "timezones": [
        "Asia/Dili"
      ]
    },
    {
      "name": "Turkmenistan",
      "code": "TM",
      "dial_code": "+993",
      "currency_code": "TMT",
      "timezones": [
        "Asia/Ashgabat"
      ]
    },
    {
      "name": "Tunisia",
      "code": "TN",
      "dial_code": "+216",
      "currency_code": "TND",
      "timezones": [
        "Africa/Tunis"
      ]
    },
    {
      "name": "Tonga",
      "code": "TO",
      "dial_code": "+676",
      "currency_code": "TOP",
      "timezones": [
        "Pacific/Tongatapu"
      ]
    },
    {
      "name": "Turkey",
      "code": "TR",
      "dial_code": "+90",
      "currency_code": "TRY",
      "timezones": [
        "Europe/Istanbul"
      ]
    },
    {
      "name": "Trinidad &amp; Tobago",
      "code": "TT",
      "dial_code": "+1868",
      "currency_code": "TTD",
      "timezones": [
        "America/Port_of_Spain"
      ]
    },
    {
      "name": "Tuvalu",
      "code": "TV",
      "dial_code": "+688",
      "currency_code": "AUD",
      "timezones": [
        "Pacific/Funafuti"
      ]
    },
    {
      "name": "Taiwan",
      "code": "TW",
      "dial_code": "+886",
      "currency_code": "TWD",
      "timezones": [
        "Asia/Taipei"
      ]
    },
    {
      "name": "Tanzania",
      "code": "TZ",
      "dial_code": "+255",
      "currency_code": "TZS",
      "timezones": [
        "Africa/Dar_es_Salaam"
      ]
    },
    {
      "name": "Ukraine",
      "code": "UA",
      "dial_code": "+380",
      "currency_code": "UAH",
      "timezones": [
        "Europe/Kiev",
        "Europe/Uzhgorod",
        "Europe/Zaporozhye"
      ]
    },
    {
      "name": "Uganda",
      "code": "UG",
      "dial_code": "+256",
      "currency_code": "UGX",
      "timezones": [
        "Africa/Kampala"
      ]
    },
    {
      "name": "United States",
      "code": "US",
      "dial_code": "+1",
      "currency_code": "USD",
      "timezones": [
        "America/New_York",
        "America/Detroit",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        "America/Indiana/Indianapolis",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Vevay",
        "America/Chicago",
        "America/Indiana/Tell_City",
        "America/Indiana/Knox",
        "America/Menominee",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/North_Dakota/Beulah",
        "America/Denver",
        "America/Boise",
        "America/Phoenix",
        "America/Los_Angeles",
        "America/Anchorage",
        "America/Juneau",
        "America/Sitka",
        "America/Metlakatla",
        "America/Yakutat",
        "America/Nome",
        "America/Adak",
        "Pacific/Honolulu"
      ]
    },
    {
      "name": "Uruguay",
      "code": "UY",
      "dial_code": "+598",
      "currency_code": "UYU",
      "timezones": [
        "America/Montevideo"
      ]
    },
    {
      "name": "Uzbekistan",
      "code": "UZ",
      "dial_code": "+998",
      "currency_code": "UZS",
      "timezones": [
        "Asia/Samarkand",
        "Asia/Tashkent"
      ]
    },
    {
      "name": "Holy See (vatican City State)",
      "code": "VA",
      "dial_code": "+379",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Vatican"
      ]
    },
    {
      "name": "Saint Vincent &amp; The Grenadines",
      "code": "VC",
      "dial_code": "+1784",
      "currency_code": "XCD",
      "timezones": [
        "America/St_Vincent"
      ]
    },
    {
      "name": "Venezuela, Bolivarian Republic Of",
      "code": "VE",
      "dial_code": "+58",
      "currency_code": "VEF",
      "timezones": [
        "America/Caracas"
      ]
    },
    {
      "name": "British Virgin Islands",
      "code": "VG",
      "dial_code": "+1284",
      "currency_code": "USD",
      "timezones": [
        "America/Tortola"
      ]
    },
    {
      "name": "US Virgin Islands",
      "code": "VI",
      "dial_code": "+1340",
      "currency_code": "USD",
      "timezones": [
        "America/St_Thomas"
      ]
    },
    {
      "name": "Vietnam",
      "code": "VN",
      "dial_code": "+84",
      "currency_code": "VND",
      "timezones": [
        "Asia/Ho_Chi_Minh"
      ]
    },
    {
      "name": "Vanuatu",
      "code": "VU",
      "dial_code": "+678",
      "currency_code": "VUV",
      "timezones": [
        "Pacific/Efate"
      ]
    },
    {
      "name": "Wallis And Futuna",
      "code": "WF",
      "dial_code": "+681",
      "currency_code": "XPF",
      "timezones": [
        "Pacific/Wallis"
      ]
    },
    {
      "name": "Western Sahara",
      "code": "EH",
      "dial_code": "+212",
      "currency_code": "MAD",
      "timezones": [
        "Africa/El_Aaiun"
      ]
    },
    {
      "name": "Samoa",
      "code": "WS",
      "dial_code": "+685",
      "currency_code": "WST",
      "timezones": [
        "Pacific/Apia"
      ]
    },
    {
      "name": "Kosovo",
      "code": "XK",
      "dial_code": "+383",
      "currency_code": "EUR",
      "timezones": [
        "Europe/Belgrade"
      ]
    },
    {
      "name": "Yemen",
      "code": "YE",
      "dial_code": "+967",
      "currency_code": "YER",
      "timezones": [
        "Asia/Aden"
      ]
    },
    {
      "name": "South Africa",
      "code": "ZA",
      "dial_code": "+27",
      "currency_code": "ZAR",
      "timezones": [
        "Africa/Johannesburg"
      ]
    },
    {
      "name": "Zambia",
      "code": "ZM",
      "dial_code": "+260",
      "currency_code": "ZMK",
      "timezones": [
        "Africa/Lusaka"
      ]
    },
    {
      "name": "Zimbabwe",
      "code": "ZW",
      "dial_code": "+263",
      "currency_code": "ZWL",
      "timezones": [
        "Africa/Harare"
      ]
    }
  ]