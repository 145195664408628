import { createSlice } from "@reduxjs/toolkit";
import {
  Getsubobligationdata,
  Getsubobligationform,
  getMyObligationdata,
  getMyObligationdataitemdetail,
  submitObligationAttachmentdata,
  submitObligationdata,
} from "./MyObligation.Action";

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  MyObligationTableData: [
    {
      prefix: "OBG",
      recordNo: 1,
      name: "taskdemo",
    },
  ],
  isVisible: false,
  isMyObligationVisible: false,
  workflownewcontentdata: {},
  editRecord: {},
  type: "",
  staffmDetails: {},
  totalCount: null,
  MyObligationPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  MySubObligationPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  taskDetailsdata: "",
  template_id: "",
  backendvariablesdata: {},
  backendvariablesforMyObligation: [],

  isContentPreviewVisible: false,
  contentPreviewModaType: "",
  isContetPreviewLoading: false,
  contentPreviewRecord: {},
  editRecordContent: "",
  contentFillData: [],
  previewType: "",
  isviewObligationDrawervisible: false,
  isformfillforobligationVisible: false,
  myObligationtableData: [],
  MyObligationdetails: [],
  formfillforobligationrecord: {},
  formfillforobligationchildindex: "",
  formfillforobligationparentindex: "",
  viewObligationDrawerrecord: {},
  subObligationsData: [],
  subObligationsformData: {},
  isDrawerdataLoading: false,
  issubobligationdataLoading: false,
  issubobligationformLoading: false,
  issubmitObligationdataLoading: false,
  AttachmentsubmittedData: "",
  isAttachmentsubmitting: false,
  isAttachedDocsVisible: false,

  AttachedDocsrecord: "",
  formfillforobligationtype: "",
};
const MyObligationSlice = createSlice({
  name: "MyObligation",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.taskDetailsdata = payload.taskDetailsdata;
      state.type = payload.type || "";
      state.editRecord = payload.record || {};
      state.editRecordContent = payload.editRecordContent
        ? Array.isArray(payload.editRecordContent) &&
          payload.editRecordContent.length
          ? payload.editRecordContent[0].content
          : payload.editRecordContent.content
        : payload.record
        ? payload.record.content
        : "";
      if (!payload.visible) {
        state.editRecord = {};
        state.taskDetailsdata = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.MyObligationPagination = {
        ...state.MyObligationPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onPageChangessubobligationFilter: (state, action) => {
      state.MySubObligationPagination = {
        ...state.MySubObligationPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    resetsubobligationPagination: (state, action) => {
      return {
        ...state,
        MySubObligationPagination: initialState.MySubObligationPagination,
      };
    },
    setTemplateIds: (state, action) => {
      state.template_id = action.payload.template_id;
    },
    onSearchList: (state, action) => {
      state.MyObligationPagination = {
        ...state.MyObligationPagination,
        search: action.payload.search || "",
      };
    },
    showContentmodal: (state, { payload }) => {
      state.isContentVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    showContentPreviewmodal: (state, { payload }) => {
      state.isContentPreviewVisible = payload.visible;
      state.contentPreviewRecord = payload.record || {};
      state.previewType = payload?.type || "";
      if (payload.record && payload.record.fields) {
        state.contentFillData = [payload.record];
      }
      state.contentPreviewModaType = payload.type || undefined;
      if (!payload.visible) {
        state.contentPreviewRecord = {};
        state.contentFillData = [];
      }
    },
   
    Contentdatareset: (state, action) => {
      return initialState;
    },

    setobligationassignModal: (state, { payload }) => {
      state.obligationassignModalvisible = payload.visible;
    },
    setViewmyObligationDrawerVisible: (state, { payload }) => {
      state.isviewObligationDrawervisible = payload.visible;
      state.viewObligationDrawerrecord = payload?.record || {};
      if(!payload.visible){
        state.MyObligationdetails=[]
      }
    },
    setformfillModalVisible: (state, { payload }) => {
     
      state.isformfillforobligationVisible = payload.visible;
      state.formfillforobligationrecord = payload?.record || {};
      state.formfillforobligationchildindex = payload?.childindex;
      state.formfillforobligationparentindex = payload?.parentindex;
      state.formfillforobligationtype = payload?.type;
    },
    ObligationvalueaddedonChange: (state, { payload }) => {
      const { fieldindex, value, contractType,} = payload;
      const updatedFields = [...state.subObligationsformData?.obligationFields];

      updatedFields[fieldindex] = {
        ...updatedFields[fieldindex],
        value,
        contractType,
      };
      // const updatedobligationfiltereddata = [...state.MyObligationdetails];

      const updatedobligationfiltereddata = {
        ...state.subObligationsformData,
        obligationFields: updatedFields,
      };
      return {
        ...state,

        subObligationsformData: updatedobligationfiltereddata || [],
      };
    },
    setViewAttachobgDocsdrawerVisible: (state, { payload }) => {
      state.isAttachedDocsVisible = payload.visible;
      state.AttachedDocsrecord = payload?.record || {};
    },
    DeleteUrlfromfile: (state, { payload }) => {
      state.subObligationsformData = payload;
    },
    myobligationdatareset: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyObligationdata.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMyObligationdata.fulfilled, (state, { payload }) => {
        state.MyObligationTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.MyObligationPagination = {
          ...state.MyObligationPagination,
          total: payload.total,
        };
      })
      .addCase(getMyObligationdata.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(getMyObligationdataitemdetail.pending, (state) => {
        state.isDrawerdataLoading = true;
      })
      .addCase(
        getMyObligationdataitemdetail.fulfilled,
        (state, { payload }) => {
          state.MyObligationdetails = payload.data;
          state.isDrawerdataLoading = false;
        }
      )
      .addCase(getMyObligationdataitemdetail.rejected, (state, { payload }) => {
        state.isDrawerdataLoading = false;
      })
      .addCase(submitObligationdata.pending, (state) => {
        state.issubmitObligationdataLoading = true;
      })
      .addCase(submitObligationdata.fulfilled, (state, { payload }) => {
        state.issubmitObligationdataLoading = false;
        state.isformfillforobligationVisible = false;
      })
      .addCase(submitObligationdata.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.issubmitObligationdataLoading = false;
      })
      .addCase(Getsubobligationdata.pending, (state) => {
        state.issubobligationdataLoading = true;
        state.subObligationsData = [];
      })
      .addCase(Getsubobligationdata.fulfilled, (state, { payload }) => {
        state.issubobligationdataLoading = false;
        state.subObligationsData = payload.data;
      })
      .addCase(Getsubobligationdata.rejected, (state, { payload }) => {
        state.issubobligationdataLoading = false;
      })
      .addCase(Getsubobligationform.pending, (state) => {
        state.issubobligationformLoading = true;
        state.subObligationsformData = {};
      })
      .addCase(Getsubobligationform.fulfilled, (state, { payload }) => {
        state.issubobligationformLoading = false;
        state.subObligationsformData = payload.data;
      })
      .addCase(Getsubobligationform.rejected, (state, { payload }) => {
        state.issubobligationformLoading = false;
      })
      .addCase(submitObligationAttachmentdata.pending, (state) => {
        state.isAttachmentsubmitting = true;
      })
      .addCase(
        submitObligationAttachmentdata.fulfilled,
        (state, { payload }) => {
          state.isAttachmentsubmitting = false;
          state.AttachmentsubmittedData = payload.data;
        }
      )
      .addCase(
        submitObligationAttachmentdata.rejected,
        (state, { payload }) => {
          state.isAttachmentsubmitting = false;
        }
      );
  },
});
export const {
  setModalVisible,
  onPageChangesFilter,
  onSearchList,
  setViewmyObligationDrawerVisible,
  showContentmodal,
  Contentdatareset,
  setTemplateIds,
  setworkflowContentdatareset,
  showContentPreviewmodal,
  templateValueonChange,
  setobligationassignModal,
  setformfillModalVisible,
  ObligationvalueaddedonChange,
  onPageChangessubobligationFilter,
  resetsubobligationPagination,
  setViewAttachobgDocsdrawerVisible,
  DeleteUrlfromfile,
  myobligationdatareset,
  
} = MyObligationSlice.actions;

export default MyObligationSlice.reducer;
