import { React } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./utils/styles/vars.less";
import "./App.less";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./globals/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// import { addLocaleData } from "react-intl";
// import enLocale from "react-intl/locale-data/en";
// import { IntlProvider } from "react-intl";
// addLocaleData([...enLocale]);

const root = ReactDOM.createRoot(document.getElementById("root"));
serviceWorkerRegistration.register();

root.render(
  <div>
    <Provider store={store}>
      {/* <IntlProvider locale="en" defaultLocale="en"> */}
      <App />
      {/* </IntlProvider> */}
    </Provider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
