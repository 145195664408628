import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/task/vendor";
const subUrllocalvendor = "/api/v1/vendor";
const taskurllocal = "/api/v1/taskcomment";
const subUrllocal1 = "/api/v1/task";
const adobeurllocal = "/api/v1/adobe";
const GuestsubUrllocal = "/api/v1/guest";

class taskVendorService {
  getTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/tasklist`, data, headers);
  createTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  createCommentAPI = (data) =>
    RestfulProvider.post(`${taskurllocal}/create`, data, headers);
  getCommentAPI = (data) =>
    RestfulProvider.post(`${taskurllocal}/comments`, data, headers);
  deletCommentAPI = (data) =>
    RestfulProvider.deleteget(`${taskurllocal}/delete`, data, headers);
  getDetailApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/taskdetail`, data, headers);
  taskActionApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/action`, data, headers);
  checklistApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/addChecklist`, data, headers);
  signApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/addVendorSignature`, data, headers);
  signApiDownload = (data) =>
    RestfulProvider.downloadMethod(`/api/v1/task/reportFile`, data, headers);
  uploadrequestforChangAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/task-action`, data, headers);
  tracktemplatehistoryAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/task-history`, data, headers);
  getTaskactivitylistAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/task-activity`, data, headers);
    verifycontracthashAPI= (data) =>
    RestfulProvider.formDatapost(`${subUrllocalvendor}/addPdf`, data, headers);
    getContentconfirmedAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/contentConfirmed`, data, headers);
    CreateagreementgetsignwithadobeAPI = (data) =>
    RestfulProvider.post(
      `${adobeurllocal}//checkIfAdobeContractIsSigned`,
      data,
      headers
    );
    getGuestTaskDetailsApi= (data) =>
    RestfulProvider.post(`${GuestsubUrllocal}/taskdetail`, data, headers);
    GuesttaskActionsApi = (data) =>
    RestfulProvider.post(`${GuestsubUrllocal}/taskAction`, data, headers);
}
// eslint-disable-next-line
export default new taskVendorService();
