import { createAsyncThunk } from "@reduxjs/toolkit";
import taskService from "./../../Services/taskServices";
import StaffServices from "./../../Services/staffServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { setModalVisible } from "../Content/content.slice";
import { createTemplateModal } from "../Template/Template.slice";
import VendorServices from "./../../Services/VendorServices";

import { setprofilemodal } from "../TaskmanagementVendor/TaskmanagementVendor.Slice";
import {
  SetNoncollabchangeRequestDrawer,
  changeRequestDrawer,
  onChangetaskStep,
  setDrawerVisible,
  setcustomconfirmModalVisible,
} from "./Taskmanagement.Slice";
import {
  getContractDetails,
  getContractsforcompany,
} from "../myContracts/myContract.Action";

import {
  setObligationdatareset,
  setobligationlistdata,
} from "../Workflow/Workflow.slice";
import { message } from "antd";
import { setReviewcommmentListEmpty } from "../MyTask/MyTask.Slice";
export const getTaskdetails = createAsyncThunk(
  "task/getTaskdetails",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getTaskAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      // thunkAPI.dispatch(getChatRoomApi());

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTask = createAsyncThunk(
  "task/createTask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.createTaskAPI(data);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "task/deleteTask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.deleteTaskApi(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTaskcomments = createAsyncThunk(
  "task/getTaskcomments",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getCommentAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTaskcomment = createAsyncThunk(
  "task/createTaskcomment",
  async ({ data, TaskcommentPagination }, thunkAPI) => {
    try {
      const res = await taskService.createCommentAPI(data);
      thunkAPI.dispatch(getTaskcomments(TaskcommentPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTaskComment = createAsyncThunk(
  "task/deleteTaskComment",
  async ({ data, TaskcommentPagination }, thunkAPI) => {
    try {
      const res = await taskService.deletCommentAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getTaskcomments(TaskcommentPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDetails = createAsyncThunk(
  "staff/getDetails",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getDetailApi(data);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTemplateTask = createAsyncThunk(
  "task/createTemplateTask",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.createTemplateTaskAPI(data);
      // thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const taskAssignee = createAsyncThunk(
  "template/taskAssignee",
  async ({ reqData, tasdReq, taskPagination, currenttaskStep }, thunkAPI) => {
    try {
      const res = await taskService.taskAssigneeApi(reqData);
      thunkAPI.dispatch(getDetails(tasdReq));
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      thunkAPI.dispatch(setcustomconfirmModalVisible({ visible: false }));
      thunkAPI.dispatch(onChangetaskStep(1));
      if (reqData?.reviewerIds?.length > 0 && reqData?.is_collobrative_mode) {
        // thunkAPI.dispatch(setDrawerVisible({ visible: false }));
        message.success(
          "Task has been created successfully and sent for a review"
        );
      } else {
        responseMessageHandler(res);
      }
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const taskConnect = createAsyncThunk(
  "template/taskConnect",
  async (recData, thunkAPI) => {
    try {
      const res = await taskService.taskContentApi(recData);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editTaskContentTemplate = createAsyncThunk(
  "template/editTaskContentTemplate",
  async ({ Taskdata, taskPagination, fortaskcontent }, thunkAPI) => {
    try {
      const res = await taskService.editTaskContentTemplateAPI(Taskdata);
    
      responseMessageHandler(res);
      if (fortaskcontent.type === "editTaskForAddendum") {
        thunkAPI.dispatch(
          createTemplateModal({
            type: "create",
            visible: false,
            record: { title: "", Attribute: [""] },
          })
        );
      
        thunkAPI.dispatch(
          changeRequestDrawer({
            type: "edittaskcontent",
            visible: true,
            record: res.data?.contentMainDetail || {},
            taskDetailsdata: res.data,
          })
        );
        thunkAPI.dispatch(getTaskdetails(taskPagination));
        thunkAPI.dispatch(getDetails({ taskId: res.data._id }));
        fortaskcontent &&
          thunkAPI.dispatch(
            setModalVisible({
              visible: true,
              type: "taskEditContent",
              record:
                Array.isArray(fortaskcontent.addendumtaskdetailsdata.content) &&
                fortaskcontent.addendumtaskdetailsdata.content.length > 0
                  ? fortaskcontent.addendumtaskdetailsdata.content[0]
                  : !fortaskcontent.addendumtaskdetailsdata.content.length
                  ? fortaskcontent.addendumtaskdetailsdata.content
                  : "",
              taskDetailsdata: fortaskcontent.addendumtaskdetailsdata,
              editRecordContent: "",
            })
          );
        fortaskcontent &&
          thunkAPI.dispatch(setModalVisible({ visible: false, record: {} }));
      } else {
        fortaskcontent &&
          thunkAPI.dispatch(setModalVisible({ visible: false, record: {} }));
      }

      thunkAPI.dispatch(
        createTemplateModal({
          type: "create",
          visible: false,
          record: { title: "", Attribute: [""] },
        })
      );
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      thunkAPI.dispatch(getDetails({ taskId: res.data._id }));

      if (!fortaskcontent?.type) {
        !fortaskcontent &&
          thunkAPI.dispatch(
            setModalVisible({
              type: "edittaskcontent",
              visible: true,
              record: res.data?.contentMainDetail || {},
              taskDetailsdata: res.data,
            })
          );
        fortaskcontent &&
          thunkAPI.dispatch(
            setDrawerVisible({ visible: true, record: res.data })
          );
        fortaskcontent &&
          thunkAPI.dispatch(getDetails({ taskId: res.data._id }));
        fortaskcontent &&
          thunkAPI.dispatch(
            getVendordetailsList({
              sortBy: "DESC",
              status: true,
              orderBy: "createdAt",
            })
          );
        fortaskcontent && thunkAPI.dispatch(getStaffList());
        fortaskcontent &&
          thunkAPI.dispatch(getTaskactivitylist({ taskId: res.data._id }));
      }

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getStaffList = createAsyncThunk(
  "staff/getStaffList",
  async (data, thunkAPI) => {
    try {
      let res = await StaffServices.getstaffAPI({
        ...data,
        isSignatory: true,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getVendordetailsList = createAsyncThunk(
  "staff/getVendordetailsList",
  async (data, thunkAPI) => {
    try {
      let res = await VendorServices.getvendorAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getfileforbulktask = createAsyncThunk(
  "task/getfileforbulktask",
  async (data1, thunkAPI) => {
    try {
      let res = await taskService.getfileforbulktask(data1);

   
      const url =URL.createObjectURL(new Blob([res]));
     
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'tasks.xlsx');
      
      link.click();
    
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const onuploadexcelforbulk = createAsyncThunk(
  "task/onuploadexcelforbulk",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.onuploadexcelforbulkAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createbulktask = createAsyncThunk(
  "task/createbulktask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.createbulktaskAPI(data);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);

      return res;
    } catch (error) {
      // const message =
      //   (error.response && error.response.data && error.response.data.error) ||
      //   error.message ||
      //   error.toString();

      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getWorkflowdetailsfortask = createAsyncThunk(
  "task/getWorkflowdetailsfortask",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getWorkflowdetailsfortaskAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const changeforrequestAction = createAsyncThunk(
  "task/changeforrequestAction",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      let res = await taskService.changeforrequestActionAPI(data);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTaskactivitylist = createAsyncThunk(
  "task/getTaskactivitylist",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getTaskactivitylistAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getVendordetiails = createAsyncThunk(
  "task/getVendordetiails",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getVendordetiailsAPI(data);

      res.data.length > 0 &&
        thunkAPI.dispatch(
          setprofilemodal({
            visible: true,
            record: res.data[0],
          })
        );

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getStaffdetiails = createAsyncThunk(
  "task/getStaffdetiails",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getStaffdetiailsAPI(data);

      res.data.length > 0 &&
        thunkAPI.dispatch(
          setprofilemodal({
            visible: true,
            record: res.data[0],
          })
        );

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setAbortTask = createAsyncThunk(
  "task/setAbortTask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.setAbortTaskAPI(data);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      thunkAPI.dispatch(getDetails(data));
      thunkAPI.dispatch(setDrawerVisible({ visible: false }));
      thunkAPI.dispatch(setReviewcommmentListEmpty());
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getreOpentask = createAsyncThunk(
  "task/getreOpentask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.getreOpentaskAPI(data);
      thunkAPI.dispatch(getDetails(data));
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      thunkAPI.dispatch(setDrawerVisible({ visible: false }));
      thunkAPI.dispatch(setReviewcommmentListEmpty());

      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTaskaddendum = createAsyncThunk(
  "task/createTaskaddendum",
  async ({ data, addendumgetData }, thunkAPI) => {
    try {
      const res = await taskService.createTaskaddendumAPI(data);
      thunkAPI.dispatch(getaddendumsteplistanddetails(addendumgetData));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getaddendumsteplistanddetails = createAsyncThunk(
  "task/getaddendumsteplistanddetails",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.getaddendumsteplistanddetailsAPI(data);

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getaddendumstepdetails = createAsyncThunk(
  "task/getaddendumstepdetails",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.getaddendumsteplistanddetailsAPI(data);
      thunkAPI.dispatch(setobligationlistdata(res?.data?.obligations));
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const gettaskaddendumstepdetails = createAsyncThunk(
  "task/gettaskaddendumstepdetails",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.gettaskaddendumstepdetailsAPI(data);

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Taskaddendumstep = createAsyncThunk(
  "task/Taskaddendumstep",
  async ({ data, detaildata, addendumlistdata }, thunkAPI) => {
    try {
      const res = await taskService.TaskaddendumstepAPI(data);
      thunkAPI.dispatch(getaddendumstepdetails(detaildata));
      thunkAPI.dispatch(gettaskaddendumstepdetails(detaildata));
      res.message.en === "Addendum prepared successfully." &&
        thunkAPI.dispatch(getaddendumsteplistanddetails(addendumlistdata));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Terminatecontract = createAsyncThunk(
  "task/Terminatecontract",
  async ({ data, taskPagination, contractpagination }, thunkAPI) => {
    try {
      const res = await taskService.TerminatecontractAPI(data);
      thunkAPI.dispatch(
        getContractDetails({ contractId: taskPagination.contractId })
      );
      thunkAPI.dispatch(getContractsforcompany(contractpagination));
      // thunkAPI.dispatch(getDetails(data));
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createTaskRenew = createAsyncThunk(
  "task/createTaskRenew",
  async ({ data, addendumgetData }, thunkAPI) => {
    try {
      const res = await taskService.createTaskRenewAPI(data);
      thunkAPI.dispatch(
        getRenewsteplistanddetails({
          ...addendumgetData,
          renewalId: res.data._id,
        })
      );
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getRenewsteplistanddetails = createAsyncThunk(
  "task/getRenewsteplistanddetails",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.getRenewsteplistanddetailsAPI(data);
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const TaskRenewstep = createAsyncThunk(
  "task/TaskRenewstep",
  async ({ data, detaildata, addendumlistdata }, thunkAPI) => {
    try {
      const res = await taskService.TaskRenewstepAPI(data);
      thunkAPI.dispatch(getRenewsteplistanddetails(detaildata));
      if (res && !res.data) {
        thunkAPI.dispatch(
          getTaskdetails({
            total: 0,
            pageSize: 20,
            defaultCurrent: 1,
            current: 1,
            sortBy: "DESC",
            showSizeChanger: true,
            search: "",
            orderBy: "createdAt",
          })
        );
      }
      if (data?.step_status === 7) {
        thunkAPI.dispatch(setObligationdatareset());
      }
      // getTaskdetails
      // res.message.en === "Renew prepared successfully." &&
      //   thunkAPI.dispatch(getRenewsteplistanddetails(addendumlistdata));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getReviewerStaffList = createAsyncThunk(
  "task/getReviewerStaffList",
  async ({ data }, thunkAPI) => {
    try {
      const res = await taskService.getReviewerListAPI();
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const ReviewerSubmit = createAsyncThunk(
  "task/ReviewerSubmit",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.ReviewerSubmitAPI(data);
      thunkAPI.dispatch(getDetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const customActions = createAsyncThunk(
  "task/customActions",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checklistDataGet = createAsyncThunk(
  "task/checklistDataGet",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.getChecklistData(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getVerionList = createAsyncThunk(
  "task/getVerionList",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.getVersionListApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const saveVersion = createAsyncThunk(
  "task/saveVersion",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.saveVersionListApi(data);

      thunkAPI.dispatch(getDetails({ taskId: data.taskId }));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCommentContent = createAsyncThunk(
  "task/getCommentContent",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.getCommentRequestApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReviewerEmailIds = createAsyncThunk(
  "task/getReviewerEmailIds",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.getReviewerEmailIdsApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const sendReviewerInvitaion = createAsyncThunk(
  "task/sendReviewerInvitaion",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.sendReviewerInvitaionApi(data);
      // thunkAPI.dispatch(getDetails({ taskId: data.taskId }));
      thunkAPI.dispatch(setDrawerVisible({ visible: false }));
      thunkAPI.dispatch(setReviewcommmentListEmpty());

      message.success("Mail sent successfully to  the reviewer(s).");
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const completeReviewerInvitaion = createAsyncThunk(
  "task/completeReviewerInvitaion",
  async (data, thunkAPI) => {
    try {
      const paramsObj = new FormData();
      paramsObj.append("taskId", data.taskId);
      paramsObj.append("file", data.filedata);
      paramsObj.append("companyId", localStorage.getItem("companyId"));
      const res = await taskService.completeReviewerInvitaionApi(paramsObj);
      // thunkAPI.dispatch(sendMailToParties({ taskId: data.taskId }));
      thunkAPI.dispatch(getDetails({ taskId: data.taskId }));
      message.success("Task has been reviewed and sent to signatories");
      // thunkAPI.dispatch(setDrawerVisible({ visible: false }));

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReviewDetails = createAsyncThunk(
  "task/getReviewDetails",
  async (data, thunkAPI) => {
    try {
  
      const res = await taskService.getReviewDetailsApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const sendMailToParties = createAsyncThunk(
  "task/sendMailToParties",
  async (data, thunkAPI) => {
    try {
    
      const res = await taskService.sendMailToPartiesApi(data);
      thunkAPI.dispatch(getDetails({ taskId: data.taskId }));

      // responseMessageHandler(res);
      thunkAPI.dispatch(setDrawerVisible({ visible: false }));
      thunkAPI.dispatch(setReviewcommmentListEmpty());

      message.success("Mail sent successfully to  the parties.");

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const completeReviewerParties = createAsyncThunk(
  "task/completeReviewerParties",
  async (data, thunkAPI) => {
    try {
      const paramsObj = new FormData();
      paramsObj.append("taskId", data.taskId);
      paramsObj.append("file", data.filedata);
      paramsObj.append("companyId", localStorage.getItem("companyId"));
      const res = await taskService.completeReviewPartiesApi(paramsObj);
      // thunkAPI.dispatch(sendMailToParties({ taskId: data.taskId }));
      thunkAPI.dispatch(getDetails({ taskId: data.taskId }));
      message.success("Task has been reviewed and sent for Signatures.");
      thunkAPI.dispatch(setDrawerVisible({ visible: false }));
      thunkAPI.dispatch(setReviewcommmentListEmpty());

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const editTaskContentForNoncollab = createAsyncThunk(
  "task/editTaskContentForNoncollab",
  async (
    { Taskdata, taskPagination, fortaskcontent, emailIds, taskDetailsdata },
    thunkAPI
  ) => {
    try {
      const res = await taskService.editTaskContentTemplateAPI(Taskdata);

      thunkAPI.dispatch(getDetails({ taskId: Taskdata.taskId }));
      if (taskDetailsdata.reviewMailSent !== 2) {
        thunkAPI.dispatch(
          sendReviewerInvitaion({ taskId: Taskdata.taskId, emailIds })
        );
      }
      if (taskDetailsdata?.reviewMailSent === 2) {
        thunkAPI.dispatch(sendMailToParties({ taskId: Taskdata.taskId }));
      }

      if (fortaskcontent?.type === "editTaskTemplateforNoncollab") {
        thunkAPI.dispatch(
          changeRequestDrawer({

            type: "edittaskcontent",
            visible: true,
            record: res.data.content[0],
            taskDetailsdata: res.data,
            min: "kio",
          })
        );
      }
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const editTaskTemplateForNoncollab = createAsyncThunk(
  "task/editTaskTemplateForNoncollab",
  async ({ Taskdata, taskPagination, fortaskcontent }, thunkAPI) => {
    try {
      const res = await taskService.editTaskContentTemplateAPI(Taskdata);
      responseMessageHandler(res);
      if (fortaskcontent?.type === "editTaskTemplateforNoncollab") {
        thunkAPI.dispatch(
          SetNoncollabchangeRequestDrawer({
            type: "edittaskcontent",
            visible: true,
            record: res.data.content[0],
            taskDetailsdata: res.data,
            min: "kio",
          })
        );
      }

      thunkAPI.dispatch(
        createTemplateModal({
          type: "create",
          visible: false,
          record: { title: "", Attribute: [""] },
        })
      );
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      thunkAPI.dispatch(getDetails({ taskId: res.data._id }));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const checkEmailregisteredorNot = createAsyncThunk(
  "task/checkEmailregisteredorNot",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.checkEmailregisteredorNotApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const SubmiteditTemplateValues = createAsyncThunk(
  "task/SubmiteditTemplateValues",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.SubmiteditTemplateValuesAPI(data);
      // thunkAPI.dispatch(getDetails({ taskId: data.taskId }));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
