import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/workflow";
const commentsapi = "/api/v1/comment";
const approvalapi = "/api/v1/approval";
const subUrllocal1 = "/obligation/addObligationToWorkflow";

class WorkflowServices {
  getworkflowAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/workflows`, data, headers);
  createworkflowAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  deleteworkflowAPI = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  getworkdetailsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/workflow/details`, data, headers);
  getworkflowstepAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/workflow/setup`, data, headers);
  getApprovadetailsAPI = (data) =>
    RestfulProvider.post(`${approvalapi}/approvalbyWorkflowId`, data, headers);
  createApprovalAPI = (data) =>
    RestfulProvider.post(`${approvalapi}/create`, data, headers);
  deleteApprovalAPI = (data) =>
    RestfulProvider.deleteget(`${approvalapi}/delete`, data, headers);
  getcommentsAPI = (data) =>
    RestfulProvider.post(`${commentsapi}/comments`, data, headers);
  createcommentsAPI = (data) =>
    RestfulProvider.post(`${commentsapi}/create`, data, headers);
  deletecommentsAPI = (data) =>
    RestfulProvider.deleteget(`${commentsapi}/delete`, data, headers);
  getContentByTemplateAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/getContentByTemplate`, data, headers);
  ApproveworkflowAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/approve`, data, headers);
  AssignworkflowtocompanyAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/addCompany`, data, headers);
  getAssignableCompanydetailsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/assignableCompany`, data, headers);
  rejectworkflowAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/reject`, data, headers);
    addObligationToWorkflowAPI   = (data) =>
    RestfulProvider.post(`${subUrllocal1}`, data, headers);
    ObligationToWorkflowlistAPI  = (data) =>
    RestfulProvider.post(`${subUrllocal1}/`, data, headers);
 deleteObligationToWorkflowAPI   = (data) =>
    RestfulProvider.post(`${subUrllocal1}/delete`, data, headers);
}
// eslint-disable-next-line
export default new WorkflowServices();
