import { createSlice } from "@reduxjs/toolkit";
const routeState = {
  location: {},
  match: {},
  history: {},
};
export const RouteSlice = createSlice({
  name: "route",
  initialState: routeState,
  reducers: {
    setRoute: (state, action) => {
      state.location = action.payload.location;
      state.match = action.payload.match;
      state.history = action.payload.history;
    },
  },
});

export const { setRoute } = RouteSlice.actions;
// export const selectUser = (state) => state.user.isAuthenticated;
export default RouteSlice.reducer;
