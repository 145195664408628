import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/task/company";
const subUrllocal1 = "/api/v1/task";
const taskurllocal = "/api/v1/taskcomment";
const verifyurllocal = "/api/v1/verifyGdrive";
const adobeurllocal = "/api/v1/adobe";
class taskVendorService {
  getTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/mytasklist`, data, headers);
  createTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  createCommentAPI = (data) =>
    RestfulProvider.post(`${taskurllocal}/create`, data, headers);
  getCommentAPI = (data) =>
    RestfulProvider.post(`${taskurllocal}/comments`, data, headers);
  deletCommentAPI = (data) =>
    RestfulProvider.deleteget(`${taskurllocal}/delete`, data, headers);
  getDetailApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/mytaskDetail`, data, headers);
  taskActionApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/staffAction`, data, headers);
  checklistApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/addStaffChecklist`, data, headers);
  signApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/addStaffSignature`, data, headers);
  signApiDownload = (data) =>
    RestfulProvider.downloadMethod(`/api/v1/task/reportFile`, data, headers);
  uploadrequestforChangAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/task-action`, data, headers);
  getTaskactivitylistAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/task-activity`, data, headers);

  createReviewCommentAPI = (data) =>
    RestfulProvider.post(`${taskurllocal}/reviewer/create`, data, headers);
  getReviewCommentAPI = (data) =>
    RestfulProvider.post(`${taskurllocal}/reviewer/commentList`, data, headers);
  deletReviewCommentAPI = (data) =>
    RestfulProvider.deleteget(`${taskurllocal}/delete`, data, headers);

  reviewAddComment = (data) =>
    RestfulProvider.post(`${subUrllocal1}/createContentComment`, data, headers);
  reviewerCommentListApi = (data) =>
    RestfulProvider.post(`${subUrllocal1}/taskContentComment`, data, headers);
  reviewerCommentActionAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/taskContentAction`, data, headers);
  VerifygdriveApi = (data) =>
    RestfulProvider.post(`${verifyurllocal}`, data, headers);
  trackTaskhistoryAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/contentHistorydata`, data, headers);
  deleteCommentHistoryAPI = (data) =>
    RestfulProvider.post(
      `${subUrllocal1}/contentCommentDeleted`,
      data,
      headers
    );
    CreateagreementwithadobeAPI  = (data) =>
    RestfulProvider.post(
      `${adobeurllocal}/createAgreement`,
      data,
      headers
    );
    CreateagreementgetsignwithadobeAPI = (data) =>
    RestfulProvider.post(
      `${adobeurllocal}//checkIfAdobeContractIsSigned`,
      data,
      headers
    );
}
// eslint-disable-next-line
export default new taskVendorService();
