import { Button, Descriptions, Tag } from "antd";
import React from "react";
import {
  ArrowRightOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import notFound from "../../../assets/images/contractNotFound.png";
import "./verifydetails.less";
import "../verify.less";
import { setissubmitted, verifydatareset } from "../Verify.Slice";
import { useDispatch } from "react-redux";
import { dateFormatter } from "../../../utils/helper";
const VerifiedDetails = (props) => {
  const { verify, fileData } = props;
  const {
    contractDetails = {},
    contractMgmtStatus,
    isContractVerified,
    isContractNotFound,
    isContractTempored,
  } = verify;
  const dispatch = useDispatch();

  return (
    <div>
      <div className="pdf_doc_container">
        <div className="pdf_doc_title">Verification Result</div>
        <div className="pdf_doc_title_extra">
          <div className="pdf_doc_title_extra_icon">
            <FilePdfOutlined style={{ color: "red", fontSize: "20px" }} />
          </div>
          <div className="pdf_doc_title_extra_name">{fileData?.name}</div>
          <div className="pdf_doc_title_extra_status">
            <Tag color={isContractVerified ? "success" : "error"}>
              {isContractVerified && "Verified"}
              {isContractNotFound && "Not a verifiable contract"}
              {isContractTempored && "Tampered"}
            </Tag>
          </div>
        </div>
      </div>
      {isContractVerified ? (
        <Descriptions>
        
          <Descriptions.Item span={24} label="Contract Name">
            {contractDetails?.contractName}
          </Descriptions.Item>
          <Descriptions.Item span={24} label="Contract Type">
            {contractDetails?.contractType1?.contentType || ""}
          </Descriptions.Item>
          <Descriptions.Item span={24} label="Contract StartDate">
            {dateFormatter(contractDetails?.effectiveDate, "DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item span={24} label="Contract EndDate">
            {dateFormatter(contractDetails?.contractExpiry, "DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item span={24} label="Contarct Status">
            {contractMgmtStatus[contractDetails?.contractStatus]?.name ||
              "Invalid"}
          </Descriptions.Item>
          <Descriptions.Item span={24} label="Counter Party Name">
            {contractDetails?.parties?.vendor?.firstName +
              " " +
              contractDetails?.parties?.vendor?.lastName}
          </Descriptions.Item>
          <Descriptions.Item span={24} label="No Of Addendums">
            {contractDetails?.addendums?.length || 0}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <div className="notfound_box">
          <img className="notfound_img" alt="" src={notFound}></img>
        </div>
      )}
      <div className="verify_btn_box">
        <Button
          onClick={() => {
            dispatch(setissubmitted(false));
            dispatch(verifydatareset());
          }}
          className="verify_btn"
        >
          <span className="verify_btn_title">
            Verify Another Document &nbsp;
            <ArrowRightOutlined />
          </span>
        </Button>
      </div>
    </div>
  );
};

export default VerifiedDetails;
