import { createSlice } from "@reduxjs/toolkit";

import { getcronjobetails } from "./Otherservices.Action";
const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  currentduration: 604800000,
  durationsetin: "week",
  isLicenceVisible:false,
  LicenceModalType:""

};
const OtherservicesSlice = createSlice({
  name: "OtherservicesSlice",
  initialState: initialState,
  reducers: {
    setLicenceModalVisible: (state, { payload }) => {
      state.isLicenceVisible = payload.visible;
      state.LicenceModalType = payload?.type || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getcronjobetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getcronjobetails.fulfilled, (state, { payload }) => {
        state.currentduration = payload?.data?.duration||604800000;
        
        if ((payload?.data?.duration||604800000 / 604800000) % 1 === 0) {
          state.durationsetin = "week";
        } else {
          state.durationsetin = "day";
        }
      })
      .addCase(getcronjobetails.rejected, (state, { payload }) => {
        state.isLoading = false;
      });
  },
});
 export const {setLicenceModalVisible} = OtherservicesSlice.actions;

export default OtherservicesSlice.reducer;
