import { createSlice } from "@reduxjs/toolkit";
import {
  getContenttype,
  createcontenttype,
  updatecontenttype,
  getSampleContent,
  createandupdateSampleContent,
  getContenttypefromadmin,
  importContenttypefromadmin,
  getsamplecontentfromadmin,
  importsamplecontentfromadmin,
  SetdisconnectwithAdobe,
  Setdisconnectwithdocusign,
  getClausesList,
  createAndUpdateClause,
  deleteClause,
  // deleteSampleContent
} from "./contentservices.Action";

const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  contenttypeTableData: [],
  isVisible: false,
  editRecord: {},
  type: "",
  totalCount: null,
  contenttypePagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  isSampleVisible: false,
  sampleeditRecord: {},
  isSampleContentVisible: false,
  samplecontentTableData: [],
  issampleSuccess: false,
  issampleLoading: false,
  samplecontentPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  issampleCreating: false,
  admincontenttypeData: [],
  isadmincontenttypeLoading: false,
  isselectforsampleVisible: false,
  isadminsamplecontentLoading: false,
  adminsamplecontentData: false,
  issamplecontentimportloading: false,
  isContenttypeimportloading: false,
  contentTypeforhistorical: {},
  isAdobesigndisconnecting: false,
  isDocusigndisconnecting: false,
  isSampleContentdatinlimit: {},
  clausePagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  clauseTableData: [],
  isClauseListLoading: false,
  clauseTotalCount: 0,
  isClauseModalVisible: false,
};
const contentserviceSlice = createSlice({
  name: "contentservice",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.type = payload?.type || "";
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    setselectforSampleModalVisible: (state, { payload }) => {
      state.isselectforsampleVisible = payload.visible;

      if (!payload.visible) {
      }
    },
    setSampleModalVisible: (state, { payload }) => {
      state.isSampleVisible = payload.visible;
      state.sampleeditRecord = payload.record || {};
      if (!payload.visible) {
        state.sampleeditRecord = {};
      }
    },
    showsampleContentmodal: (state, { payload }) => {
      state.isSampleContentVisible = payload.visible;
      state.sampleeditRecord = payload.record || {};
      if (!payload.visible) {
        state.sampleeditRecord = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.contenttypePagination = {
        ...state.contenttypePagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onsamplePageChangesFilter: (state, action) => {
      state.samplecontentPagination = {
        ...state.samplecontentPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onPageChangesClausesFilter: (state, action) => {
      state.clausePagination = {
        ...state.clausePagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.contenttypePagination = {
        ...state.contenttypePagination,
        search: action.payload.search || "",
      };
    },
    onsampleSearchList: (state, action) => {
      state.samplecontentPagination = {
        ...state.samplecontentPagination,
        search: action.payload.search || "",
      };
    },
    onSearchClausesList: (state, action) => {
      state.clausePagination = {
        ...state.clausePagination,
        search: action.payload.search || "",
      };
    },
    contentServicedatareset: (state, action) => {
      return initialState;
    },
    resetgetContenttypepagination: (state, action) => {
      return {
        ...state,
        contenttypePagination: initialState.contenttypePagination,
      };
    },
    setClauseModalVisible: (state, { payload }) => {
      state.isClauseModalVisible = payload.visible;
      state.type = payload?.type || "";
      state.clauseEditRecord = payload.record || {};
      if (!payload.visible) {
        state.clauseEditRecord = {};
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContenttype.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContenttype.fulfilled, (state, { payload }) => {
        state.contenttypeTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.total;
        state.contenttypePagination = {
          ...state.contenttypePagination,
          total: payload.total,
        };
      })
      .addCase(getContenttype.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createcontenttype.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createcontenttype.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        if (state.type === "historical") {
          state.contentTypeforhistorical = payload?.data || {};
        }
      })
      .addCase(createcontenttype.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updatecontenttype.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updatecontenttype.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        if (state.type === "historical") {
          state.contentTypeforhistorical = payload?.data || {};
        }
      })
      .addCase(updatecontenttype.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(getSampleContent.pending, (state) => {
        state.issampleLoading = true;
      })
      .addCase(getSampleContent.fulfilled, (state, { payload }) => {
        state.samplecontentTableData = payload.data;
        state.isSampleContentdatinlimit = payload?.licenseQuota?.contentLibrary;
        state.issampleLoading = false;
        state.totalCount = payload.total;
        state.samplecontentPagination = {
          ...state.samplecontentPagination,
          total: payload.total,
        };
      })
      .addCase(getSampleContent.rejected, (state, { payload }) => {
        state.issampleLoading = false;
      })
      .addCase(createandupdateSampleContent.pending, (state) => {
        state.issampleCreating = true;
        state.issampleSuccess = false;
      })
      .addCase(createandupdateSampleContent.fulfilled, (state, { payload }) => {
        state.issampleCreating = false;
        state.issampleSuccess = true;
        state.isSampleVisible = false;
      })
      .addCase(createandupdateSampleContent.rejected, (state, { payload }) => {
        state.issampleCreating = false;
      })
      .addCase(getContenttypefromadmin.pending, (state) => {
        state.isadmincontenttypeLoading = true;
      })
      .addCase(getContenttypefromadmin.fulfilled, (state, { payload }) => {
        state.isadmincontenttypeLoading = false;
        state.admincontenttypeData = payload.data;
      })
      .addCase(getContenttypefromadmin.rejected, (state, { payload }) => {
        state.isadmincontenttypeLoading = false;
      })
      .addCase(importContenttypefromadmin.pending, (state) => {
        state.isContenttypeimportloading = true;
      })
      .addCase(importContenttypefromadmin.fulfilled, (state, { payload }) => {
        state.isContenttypeimportloading = false;
        state.isVisible = false;
      })
      .addCase(importContenttypefromadmin.rejected, (state, { payload }) => {
        state.isContenttypeimportloading = false;
      })
      .addCase(getsamplecontentfromadmin.pending, (state) => {
        state.isadminsamplecontentLoading = true;
      })
      .addCase(getsamplecontentfromadmin.fulfilled, (state, { payload }) => {
        state.isadminsamplecontentLoading = false;
        state.adminsamplecontentData = payload.data;
      })
      .addCase(getsamplecontentfromadmin.rejected, (state, { payload }) => {
        state.isadminsamplecontentLoading = false;
      })
      .addCase(importsamplecontentfromadmin.pending, (state) => {
        state.issamplecontentimportloading = true;
      })
      .addCase(importsamplecontentfromadmin.fulfilled, (state, { payload }) => {
        state.isselectforsampleVisible = false;
        state.issamplecontentimportloading = false;
      })
      .addCase(importsamplecontentfromadmin.rejected, (state, { payload }) => {
        state.issamplecontentimportloading = false;
      })
      .addCase(SetdisconnectwithAdobe.pending, (state) => {
        state.isAdobesigndisconnecting = true;
      })
      .addCase(SetdisconnectwithAdobe.fulfilled, (state, { payload }) => {
        state.isAdobesigndisconnecting = false;
      })
      .addCase(SetdisconnectwithAdobe.rejected, (state, { payload }) => {
        state.isAdobesigndisconnecting = false;
      })
      .addCase(Setdisconnectwithdocusign.pending, (state) => {
        state.isDocusigndisconnecting = true;
      })
      .addCase(Setdisconnectwithdocusign.fulfilled, (state, { payload }) => {
        state.isDocusigndisconnecting = false;
      })
      .addCase(Setdisconnectwithdocusign.rejected, (state, { payload }) => {
        state.isDocusigndisconnecting = false;
      })
      .addCase(getClausesList.pending, (state) => {
        state.isClauseListLoading = true;
      })
      .addCase(getClausesList.fulfilled, (state, { payload }) => {
        state.clauseTableData = payload.data;
        state.isClauseListLoading = false;
        state.clauseTotalCount = payload.total;
        state.clausePagination = {
          ...state.clausePagination,
          total: payload.total,
        };
      })
      .addCase(getClausesList.rejected, (state, { payload }) => {
        state.isClauseListLoading = false;
      })
      .addCase(createAndUpdateClause.pending, (state) => {
        state.isClauseListLoading = true;
      })
      .addCase(createAndUpdateClause.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isClauseModalVisible = false;
      })
      .addCase(createAndUpdateClause.rejected, (state, { payload }) => {
        state.isClauseListLoading = false;
      })
      .addCase(deleteClause.pending, (state) => {
        state.isClauseListLoading = true;
      })
      .addCase(deleteClause.fulfilled, (state, { payload }) => {
        // state.isClauseListLoading = false;
      })
      .addCase(deleteClause.rejected, (state, { payload }) => {
        state.isClauseListLoading = false;
      });
  },
});
export const {
  showsampleContentmodal,
  setSampleModalVisible,
  setModalVisible,
  onPageChangesFilter,
  onSearchList,
  contentServicedatareset,
  setselectforSampleModalVisible,
  onsamplePageChangesFilter,
  onsampleSearchList,
  resetgetContenttypepagination,
  onPageChangesClausesFilter,
  onSearchClausesList,
  setClauseModalVisible
} = contentserviceSlice.actions;
export default contentserviceSlice.reducer;
