import React from "react";
import { Modal } from "antd";
import "./modal.less";

const CustomModal = (props) => {
  return (
    <Modal style={{ top: 25 }} className="custom-modal" {...props}>
      {props.children}
    </Modal>
  );
};

export default CustomModal;
