import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/auth";
const subUrllocal2 = "/api/v1/user";
const subUrllocal3 = "/api/v1/vendor";
const GuestsubUrllocal = "/api/v1/guest";
class UserService {
  login = (data) => RestfulProvider.post(`${subUrllocal}/login`, data, headers);
  chatRoomApiFunc = (data) =>
    RestfulProvider.post(`${subUrllocal}/getChatRoom`, data, headers);
  UpdateuserProfileAPI = (data) =>
    RestfulProvider.post(`${subUrllocal2}/users`, data, headers);
  ForgetpasswordAPI = (data) =>
    RestfulProvider.post(`${subUrllocal2}/resetPassword`, data, headers);
  resetpasswordAPI = (data) =>
    RestfulProvider.post(`${subUrllocal2}/changePassword`, data, headers);
  SetvendorsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal3}/setDefaultVendor`, data, headers);
  getRoleApi = () => RestfulProvider.get(`/api/v1/role`, {}, headers);
  getOtpApi = (data) =>
    RestfulProvider.post(`/api/v1/guest/generateGuestUserOtp`, data, headers);
  verifyOtpApi = (data) =>
    RestfulProvider.post(`/api/v1/guest/verifyGuestUserOtp`, data, headers);
  GuestgetChatRoomApiFunc = (data) =>
    RestfulProvider.post(`${GuestsubUrllocal}/getChatRoom`, data, headers);
  loginwithMicrosoft = (data) =>
    RestfulProvider.get(`${subUrllocal}/microsoft`, data, headers);
  getmicrosoftLogoutApi = (data) =>
    RestfulProvider.post(`/api/v1/logout`, data, headers);
  checkUrlTokenValidityAPI=(data) =>
    RestfulProvider.post(`/api/v1/user/verifylink`, data, headers);
  SetChangePasswordAPI=(data) =>
    RestfulProvider.post(`/api/v1/user/changePasswordDirect`, data, headers);
  contactSalesApi=(data) =>
    RestfulProvider.post(`/api/v1/licensePlan/contactsales`, data, headers);
}
// eslint-disable-next-line
export default new UserService();
