import { LockOutlined, UserOutlined} from "@ant-design/icons";
import { Button, Col, Form, Image, Input, Row, message } from "antd";
import "./user.less";
import CenterBox from "../../globals/components/centerBox/index";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import kmindzlogo from "../../assets/kmindzlogo.png";
import newLogo from "../../assets/images/newLogo.png";
import { handleKeyWithMenuId } from "../../globals/layout/layout.slice";
import { connect, useDispatch } from "react-redux";
import { getChatRoomApi, loginUser } from "./userActions";
import { injectIntl } from "react-intl";
import { SetforgetpasswordModal } from "./user.slice";
import ForgetPasswordPage from "./Forgetpassword";
import loginpageleft from "../../assets/images/loginpageleft.png";
import { appServiceName } from "../../utils/environment";
import { useHistory } from "react-router-dom";
import Microsoftimage from "../../assets/images/microsoft.png";
const LoginComponent = (props) => {
  const dispatch = useDispatch();
  const {
    user,
    intl: { formatMessage },
  } = props;
  const history = useHistory();

  const { isLoading, forgetpasswordmodal } = user;
  const handleSubmit = (e) => {
    dispatch(loginUser(e));
    dispatch(handleKeyWithMenuId());
  };
  const [token, setToken] = useState("");

  useEffect(() => {
    // Get the query parameters from the URL
    const params = new URLSearchParams(window.location.search);
    // Get the token value
    const tokenValue = params.get("token");
   
    setToken(tokenValue);

    if (tokenValue) {
    

      localStorage.setItem("customerToken", tokenValue);
      dispatch(getChatRoomApi());
      history.push("/dashboard");
    }
    const ifmessageValue = params.get("message");
    if (ifmessageValue) {
     
      message.error(ifmessageValue);
      history.push("/login");

    }

    // Set the token state
  }, []);
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={14} className="dc">
        <img className="Loginleft" src={loginpageleft} alt="" />
      </Col>
      <Col xs={24} sm={24} md={24} lg={10}>
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <CenterBox centerBoxClassName="centerbox-login">
            <div className="login-container">
              <div className="image-title-container">
                <div className="container">
                  <img
                    src={newLogo}
                    alt="logo"
                    className="logo"
                    width={"30%"}
                    height={"10%"}
                  />
                </div>
              </div>
              <Form
                name="normal_login"
                layout="vertical"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={(e) => handleSubmit(e)}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: "useremail_rule" }),
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={formatMessage({ id: "useremail" })}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: "password_rule" }),
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={formatMessage({ id: "password" })}
                  />
                </Form.Item>
                <Form.Item>
                  {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}
                  <span
                    onClick={() => {
                      dispatch(SetforgetpasswordModal({ visible: true }));
                    }}
                    // to="/forgetpassword"
                    className="forgetpassword"
                  >
                    {formatMessage({ id: "forget_your_password" })}
                  </span>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isLoading}
                  >
                    {formatMessage({ id: "login" })}
                  </Button>
                </Form.Item>
                Or <span>login with</span>&nbsp;
                <Button
                  // type="primary"
                  disabled={isLoading}
                  type="text"
                  // icon={}
                  onClick={() => {
                    window.open(`${appServiceName}/api/v1/auth/microsoft`);
                  }}
                  // htmlType="submit"
                  className="login-form-button-microsoft"
                  // loading={isLoading}
                >
                  <Image
                    style={{ height: "24px", width: "24px" }}
                    preview={false}
                    src={Microsoftimage}
                  />
                </Button>
              </Form>
            </div>
          </CenterBox>

          {forgetpasswordmodal && <ForgetPasswordPage {...props} />}
        </div>
      </Col>
    </Row>
  );
};

export default connect((state) => ({
  user: state.user,
  global: state.global,
}))(injectIntl(LoginComponent));
