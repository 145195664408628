import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/myobligations";

class MyobligationService {
  

    getMyObligationdataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/tasks`, data, headers);
    getMyObligationdataitemdetailAPI= (data) =>
    RestfulProvider.post(`${subUrllocal}/obligations`, data, headers);
    submitObligationdataAPI= (data) =>
    RestfulProvider.post(`${subUrllocal}/submitobligaiton`, data, headers);
    GetsubobligationdataAPI= (data) =>
    RestfulProvider.post(`${subUrllocal}/subobligations`, data, headers);
    GetsubobligationformAPI= (data) =>
    RestfulProvider.post(`${subUrllocal}/detail`, data, headers);
    submitObligationsubmitObligationAttachmentdatadataAPI= (data) =>
    RestfulProvider.formDatapost(`${subUrllocal}/uploadDocument`, data, headers);
}
// eslint-disable-next-line
export default new MyobligationService();
