import React, { useState } from "react";
import { Checkbox, Divider, Form, Input, message, Select } from "antd";

import CustomButton from "../../../globals/components/button";
import CustomModal from "../../../globals/components/Modal";

import { setModalVisible } from "../Vendor.Slice";
import { useDispatch } from "react-redux";
import { Createvendor, FillandUpdateGuestvendordata } from "../Vendor.Action";
import "./vendor.less";
import { countriesList } from "./../../../utils/country";
import { countriescodeList } from "./../../../utils/countrycode";

import { useEffect } from "react";
import { validateName } from "../../../utils/Validationcheck";
const formItemLayout = {
  labelCol: { xs: 24, sm: 24, md: 24, lg: 8 },
  wrapperCol: { xs: 24, sm: 24, md: 24, lg: 16 },
};
const { Option } = Select;
const Createvendormodel = (props) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const {
    vendor,
    intl: { formatMessage },
    user: {  userdetails = {} },
  } = props;
  const {
    editRecord = {},
    isSuccess,
    isVisible,
    isCreateLoading,
    vendorPagination,
    vendor_model_type,
    drawer_record_for_task,
    type,
  } = vendor;
  const [countryId, setCountryId] = useState(editRecord?.countryName);
  const [stateId, setStateId] = useState(editRecord?.stateName);
  const getDialCodeList = (countryName) => {
   
    const country = countriescodeList.filter(
      (c) => c.name.toLowerCase() === countryName?.toLowerCase()
    );
    return countryName ? country : countriescodeList;
  };
  const getDialCodeByName = (countryName) => {
    const country = countriescodeList.find(
      (c) => c.name.toLowerCase() === countryName?.toLowerCase()
    );
    return country ? country.dial_code : null;
  };
  const prefixphoneNo = (editrecord) => (
    <Form.Item name="stdCode" noStyle initialValue={editrecord?.stdCode}>
      <Select
        style={{
          width: 70,
        }}
        optionFilterProp="children"
        showArrow={false}
        showSearch
      >
        {getDialCodeList(countryId).map((rt, index) => (
          <Option key={index} value={rt.dial_code}>
            {rt.dial_code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
  const modelClose = async (form) => {
    form.resetFields();
    dispatch(setModalVisible({ visible: false, record: {} }));
  };
  const createvendorSubmit = async (form) => {
    var values = await form.validateFields();
    var validRegex = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    let data = {
      id: editRecord?._id,
      companyId: editRecord?.companyId,
      ...values,
    };
    if (values.email && !validRegex.test(values.email)) {
      return message.warning("Please enter the correct email address.");
    }

    if (Object.keys(editRecord).length) {
      if (type === "createfromemail") {
        dispatch(
          FillandUpdateGuestvendordata({
            data: { ...data, isGuestUser: true },

            vendorPagination,
            vendor_model_type,
            drawer_record_for_task,
          })
        );
      } else {
        dispatch(
          Createvendor({
            data,
            vendorPagination,
            vendor_model_type,
            drawer_record_for_task,
          })
        );
      }
    } else {
      dispatch(Createvendor({ data, vendorPagination, vendor_model_type }));
    }
  };
  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess, form]);
  const getCountrybyCityList = (country_Id) => {
    if (country_Id) {
      let stateList = [];
      stateList = countriesList.find(
        (eve) => eve.CountryName === country_Id
      ).States;
      return stateList;
    }
    return [];
  };

  const getCityList = (country_Id, state_id) => {
    if (country_Id && state_id) {
      let stateObj = countriesList.find(
        (eve) => eve.CountryName === country_Id
      ).States;
      let rt =
        stateObj && stateObj.length
          ? stateObj.find((eve) => eve.StateName === state_id).Cities
          : "";
      return rt;
    }
    return [];
  };
  const validateEmail = (rule, value) => {
    if (value && value.endsWith(".com")) {
      return Promise.resolve();
    } else if (value && value.endsWith(".in")) {
      return Promise.resolve();
    } else if (value && value.endsWith(".co")) {
      return Promise.resolve();
    }
    return Promise.reject('Email must end with ".com","co and ".in');
  };
  return (
    <CustomModal
      width={460}
      maskClosable={false}
      title={
        Object.keys(editRecord).length > 2 && editRecord?.firstName
          ? type === "createfromemail"
            ? "Update Details"
            : formatMessage({ id: "update_vendor" })
          : type === "createfromemail"
          ? "Fill Details"
          : formatMessage({ id: "create_vendor" })
      }
      placement="right"
      onCancel={() => {
        modelClose(form);
      }}
      open={isVisible}
      footer={
        <div>
          <CustomButton
            // type="primary"
            onClick={() => {
              modelClose(form);
            }}
          >
            {formatMessage({ id: "cancel" })}
          </CustomButton>
          <CustomButton
            type="primary"
            onClick={() => createvendorSubmit(form)}
            key={"submit"}
            loading={isCreateLoading}
          >
            {Object.keys(editRecord).length > 2
              ? formatMessage({ id: "update" })
              : formatMessage({ id: "create" })}
          </CustomButton>
        </div>
      }
    >
      {" "}
      <Form
        labelWrap
        className="vendorform"
        form={form}
        colon={false}
        name="register"
        layout="horizontal"
        scrollToFirstError
        labelAlign="left"
        // labelWrap
      >
        <Form.Item
          {...formItemLayout}
          name="firstName"
          initialValue={editRecord.firstName ? editRecord.firstName : ""}
          label={formatMessage({ id: "firstname" })}
          rules={[
            { required: true, message: formatMessage({ id: "fname_rule" }) },
            {
              whitespace: true,
              message: formatMessage({ id: "firstname_emptyrule" }),
            },
            {
              validator: validateName,
            },
          ]}
        >
          <Input placeholder={formatMessage({ id: "plce_firstname" })} />
        </Form.Item>
        <Form.Item
          // className="staffform"
          {...formItemLayout}
          initialValue={editRecord.lastName ? editRecord.lastName : ""}
          name="lastName"
          label={formatMessage({ id: "lastname" })}
          rules={[
            { required: true, message: formatMessage({ id: "lname_rule" }) },
            {
              whitespace: true,
              message: formatMessage({ id: "lastname_emptyrule" }),
            },
            {
              validator: validateName,
            },
          ]}
        >
          <Input placeholder={formatMessage({ id: "plce_lastname" })} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="designation"
          initialValue={editRecord.designation ? editRecord.designation : ""}
          label={formatMessage({ id: "designation" })}
          rules={[
            // { required: true, message: formatMessage({ id: "cname_rule" }) },

            { required: true, message: "Please enter designation!" },

            {
              whitespace: true,
              message: formatMessage({ id: "designation_emptyrule" }),
            },
            {
              validator: validateName,
            },
          ]}
        >
          <Input
            placeholder={formatMessage({ id: "designation_placeholder" })}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="email"
          initialValue={editRecord.email ? editRecord.email : ""}
          label={formatMessage({ id: "email" })}
          rules={[
            { required: true, message: formatMessage({ id: "email_rule" }) },
            {
              whitespace: true,
              message: formatMessage({ id: "email_emptyrule" }),
            },
            {
              type: "email",
              message: formatMessage({ id: "email_must_rule" }),
            },
            { validator: validateEmail },
          ]}
        >
          <Input
            disabled={!userdetails?.is_owner_user ? true : false}
            placeholder={formatMessage({ id: "plce_email" })}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="company"
          initialValue={editRecord.company ? editRecord.company : ""}
          label={formatMessage({ id: "company" })}
          rules={[
            { required: true, message: formatMessage({ id: "cname_rule" }) },
            {
              whitespace: true,
              message: formatMessage({ id: "cname_emptyrule" }),
            },
          ]}
        >
          <Input placeholder={formatMessage({ id: "cname_placeholder" })} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="vendor_registered_address"
          initialValue={
            editRecord.vendor_registered_address
              ? editRecord.vendor_registered_address
              : ""
          }
          label={formatMessage({ id: "registeredAddress" })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: "registeredAddress_rule" }),
            },
            {
              whitespace: true,
              message: formatMessage({ id: "registeredAddress_emptyrule" }),
            },
          ]}
        >
          <Input
            placeholder={formatMessage({ id: "plce_registeredAddress" })}
          />
        </Form.Item>
        <Form.Item
          // className="staffform"
          {...formItemLayout}
          initialValue={editRecord?.countryName}
          name="countryName"
          label={formatMessage({ id: "country" })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: "plce_country" }),
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            allowClear
            // getPopupContainer={(trigger) => trigger.parentNode}
            // disabled={Object.keys(editRecord).length > 0 ? true : false}
            placeholder={formatMessage({ id: "country_rule" })}
            onChange={(e) => {
              const getdialcode = getDialCodeByName(e);
              setCountryId(e);
              form.setFieldsValue({
                [`stateName`]: "",
                [`cityName`]: "",
                [`stdCode`]: getdialcode,
              });
              setStateId("");
            }}
            showArrow
          >
            {countriesList.map((rt, index) => (
              <Option key={index} value={rt.CountryName}>
                {rt.CountryName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          // className="staffform"
          {...formItemLayout}
          initialValue={editRecord?.stateName}
          name="stateName"
          label={formatMessage({ id: "state" })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: "plce_state" }),
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            showArrow
            // getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(e) => {
              setStateId(e);
              form.setFieldsValue({
                [`cityName`]: "",
              });
            }}
            // disabled={Object.keys(editRecord).length > 0 ? true : false}
            placeholder={formatMessage({ id: "select_state" })}
          >
            {getCountrybyCityList(countryId).map((rt, index) => (
              <Option key={index} value={rt.StateName}>
                {rt.StateName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          // className="staffform"
          {...formItemLayout}
          initialValue={editRecord?.cityName}
          name="cityName"
          label={formatMessage({ id: "city" })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: "plce_city" }),
            },
          ]}
        >
          <Select
            optionFilterProp="children"
            showSearch
            showArrow
            // getPopupContainer={(trigger) => trigger.parentNode}
            // disabled={Object.keys(editRecord).length > 0 ? true : false}
            placeholder={formatMessage({ id: "select_city" })}
          >
            {getCityList(countryId, stateId).map((rt, index) => (
              <Option key={index} value={rt}>
                {rt}
              </Option>
            ))}
          </Select>
        </Form.Item>{" "}
        <Form.Item
          {...formItemLayout}
          name="pinCode"
          initialValue={editRecord.pinCode ? editRecord.pinCode : ""}
          label={formatMessage({ id: "pincode" })}
          rules={[
            {
              required: true,

              message: formatMessage({ id: "pincode_rule" }),
            },
            {
              message: "Entered zip code is not valid",
              pattern: new RegExp(/^[0-9]+$/),
            },
            {
              max: 10,
              min: 1,
              message: (
                <span style={{ textTransform: "none" }}>
                  {`Zip code must be 1-10 digit long`}
                </span>
              ),
            },
            {
              whitespace: true,
            },

            // {
            //   whitespace: true,
            // },
          ]}
        >
          <Input
            className="input-scroll-numpad-zip"
            type="number"
            onWheel={(e) => {
              e.preventDefault();
              e.target.blur();
            }}
            maxLength={10}
            placeholder={formatMessage({ id: "plce_pincode" })}
            minLength={10}
            min={10000}
            max={9999999999}
            controls={false}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="phoneNo"
          initialValue={editRecord.phoneNo ? editRecord.phoneNo : ""}
          label={formatMessage({ id: "phone_no" })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: "phone_rule" }),
              pattern: new RegExp(/^[0-9]+$/),
            },
            {
              max: 14,
              min: 8,
              message: (
                <span style={{ textTransform: "none" }}>
                  {`Phone no must be 8 - 14 digit long`}
                </span>
              ),
            },
            {
              whitespace: true,
            },
          ]}
        >
          <Input
            className="input-scroll-num"
            type="number"
            onWheel={(e) => {
              e.preventDefault();
              e.target.blur();
            }}
            addonBefore={prefixphoneNo(editRecord)}
            controls={false}
            style={{ width: "100%" }}
            placeholder={formatMessage({ id: "plce_phoneno" })}
            maxLength={10}
            minLength={10}
          />
        </Form.Item>{" "}
        <Form.Item
          className="cmp_address_same"
          name="isnicknamesame"
          initialValue={editRecord?.isnicknamesame}
          label={formatMessage({ id: "nickname_same_as_above_name" })}
          // rules={[
          //   {
          //     required: true,
          //     message: formatMessage({ id: "email_rule" }),
          //   },
          // ]}
          valuePropName="checked"
        >
          <Checkbox
            checked
            onChange={(e) => {
              form.setFieldsValue({
                [`vendor_nickName`]: e.target.checked
                  ? form.getFieldValue("firstName") +
                    " " +
                    form.getFieldValue("lastName")
                  : "",
              });
            }}
          ></Checkbox>
        </Form.Item>
        <Divider className="cmp_ques_check_divider" />
        <Form.Item
          {...formItemLayout}
          name="vendor_nickName"
          initialValue={
            editRecord.vendor_nickName ? editRecord.vendor_nickName : ""
          }
          label={formatMessage({ id: "nickName" })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: "nickName_rule" }),
            },
            {
              whitespace: true,
              message: formatMessage({ id: "nickName_emptyrule" }),
            },
          ]}
        >
          <Input placeholder={formatMessage({ id: "plce_nickName" })} />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default Createvendormodel;
