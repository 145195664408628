import { createAsyncThunk } from "@reduxjs/toolkit";
import ObligationService from "../../Services/ObligationService";
import { responseMessageHandler } from "../../globals/components/notification";

export const getObligationFormlist = createAsyncThunk(
  "ObligationForm/getObligationFormlist",
  async (data, thunkAPI) => {
    try {
     
      let res = await ObligationService.getObligationFormlistAPI({
        ...data,
        limit: data?.pageSize,
        page: data?.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createObligationForm = createAsyncThunk(
  "ObligationForm/createObligationForm",
  async ({ data, ObligationformPagination}, thunkAPI) => {
    try {
      const res = await ObligationService.createObligationFormAPI(data);
      thunkAPI.dispatch(getObligationFormlist(ObligationformPagination));
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteObligationForm = createAsyncThunk(
  "ObligationForm/deleteObligationForm",
  async ({ data, ObligationformPagination }, thunkAPI) => {
    try {
      const res = await ObligationService.deleteObligationFormApi(data);
      thunkAPI.dispatch(getObligationFormlist(ObligationformPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);