import React, { useEffect } from "react";

import { Form, Input, Radio, Upload, message } from "antd";
import CustomButton from "../../../globals/components/button";
import CustomModal from "../../../globals/components/Modal";
import { InboxOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setSignatureModal } from "../TaskmanagementVendor.Slice";
import { signatureApicall } from "../TaskmanagementVendor.Action";
import SignaturePad from "../../../globals/components/signaturePad";
import CustomCard from "../../../globals/components/Card";
import { useState } from "react";
import "./Signature.less";
import html2canvas from "html2canvas";
import WebFont from "webfontloader";

const { Dragger } = Upload;

const SignatureModal = (props) => {
  const [Signaturetype, setsignaturetype] = useState(1);
  const [uploadFiles, setuploadFiles] = useState([]);
  // const [SignaturetypeLoading, setsignaturetypeLoading] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [typedText, setTypedText] = useState("");
  const [base64fFile, setbase64fFile] = useState("");
  const handleTextChange = (e) => {
    setTypedText(e.target.value);
  };
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Qwitcher Grypen"], // Replace with the fonts you selected
      },
    });
  }, []);

  const {
    intl: { formatMessage },
    taskmanagementVendor,
    user: { vendor_details = {}, company_ids },
  } = props;
  const {
    isSignatureVisibleLoading,
    isSignatureVisible,
    signatureDetails,
    taskTicketDetails,
    isvendorlastsignature,
    signModalopenfrom,
  } = taskmanagementVendor;
  const convertToPng = async () => {
    try {
      const typedSignature = document.querySelector(".typed-signature");

      if (typedSignature) {
        const croppedCanvas = document.createElement("canvas");
        var ctx = croppedCanvas.getContext("2d");

       
        croppedCanvas.width = 360;
        croppedCanvas.height = 80;
       
       
       
        ctx.font = "600 60px Qwitcher Grypen, cursive";
       
        ctx.fillText(typedText,10,55,340);

        
        const image = croppedCanvas.toDataURL("image/png");
        return image;
      } else {
        throw new Error("Typed signature element not found");
      }
    } catch (error) {
      console.error("Error converting to PNG:", error);
      throw error;
    }
  };
  const modelClose = async (form) => {
    form.resetFields();
    dispatch(setSignatureModal({ visible: false, record: {} }));
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const createStaffSubmit = async (form) => {
    const { approvals = [] } = taskTicketDetails;
    const newtaskPagination = {
      // ...taskPagination,
      companyIds: company_ids,
      vendorId: vendor_details._id,
    };
    var values = await form.validateFields();
    // setsignaturetypeLoading(true);

    let data = {
      signature: values.files,
      vendorId: vendor_details._id,
      taskId: taskTicketDetails?._id,
      approvalId: signatureDetails._id || approvals[0]._id, //LevelId
      signatureType: Signaturetype,
    };

    // approvalId:checkApprovalId(taskTicketDetails.vendorApprovalData)
    let taskReq = {
      taskId: taskTicketDetails._id,
      companyIds: company_ids,
      companyId: taskTicketDetails.companyId,
      vendorId: vendor_details._id,
    };
    if (Signaturetype === 2) {
      await dispatch(
        signatureApicall({
          data,
          taskReq,
          isvendorlastsignature,
          newtaskPagination,
          signModalopenfrom
        })
      );
      // dispatch(setSignatureModal({ visible: false, record: {} }));
    }
    if (Signaturetype === 1) {
      const signaturetypedata = await convertToPng();
      let data = {
        signature: signaturetypedata,
        vendorId: vendor_details._id,
        taskId: taskTicketDetails?._id,
        approvalId: signatureDetails._id || approvals[0]._id, //LevelId
        signatureType: Signaturetype,
      companyId: taskTicketDetails?.companyId,
      };
      await dispatch(
        signatureApicall({
          data,
          taskReq,
          isvendorlastsignature,
          newtaskPagination,
          signModalopenfrom
        })
      );
      dispatch(setSignatureModal({ visible: false, record: {} }));
    }
    if (Signaturetype === 3) {
      let data = {
        signature: base64fFile,
        vendorId: vendor_details._id,
        taskId: taskTicketDetails?._id,
        approvalId: signatureDetails._id || approvals[0]._id, //LevelId
        signatureType: Signaturetype,
      };
      await dispatch(
        signatureApicall({
          data,
          taskReq,
          isvendorlastsignature,
          newtaskPagination,
          signModalopenfrom
        })
      );
      // dispatch(setSignatureModal({ visible: false, record: {} }));
    }
    // setsignaturetypeLoading(false);
  };
  var sigCanvas = "";
  const SignatureselectionData = [
    { name: "Type", value: 1 },
    { name: "Draw", value: 2 },
    { name: "Upload", value: 3 },
  ];
  const checkFileTypeandSize = file => {
    const fileNameParts = file.name.split('.');
    const fileType = fileNameParts[fileNameParts.length - 1];
    const maxSize = 1 * 1024 * 1024; // 1 MB in bytes

    if (!["jpg","jpeg","png","PNG","JPEG","JPG"].includes(fileType.toLowerCase())) {
      message.error(`Only jpg,png,jpeg  are allowed!`);
      return false; // Prevent the file from being uploaded
    }else if (file.size > maxSize) {
      message.error("File must be smaller than 1MB!");
      return false;
    }

    return true; // Continue with the upload
  };
  const draggerprops = {
    name: "file",
    multiple: true,
    accept: ".jpg, .jpeg, .png ,.PNG, .JPEG, .JPG",
    maxCount: 1,
    fileList: uploadFiles,
    beforeUpload:checkFileTypeandSize,

    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setuploadFiles([info.file]);
        var reader = new FileReader();
        reader.onloadend = function (e) {
          var getBase64Data = e.target.result;

          setbase64fFile(getBase64Data);
        };
        reader.readAsDataURL(info.file.originFileObj);
      }
      if (status === "done") {
        setuploadFiles([info.file]);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  return (
    <CustomModal
      //   width={300}
      maskClosable={false}
      bodyStyle={{ height: "350px" }}
      title={formatMessage({ id: "signature" })}
      placement="right"
      onCancel={() => {
        modelClose(form);
      }}
      open={isSignatureVisible}
      footer={
        <div>
          <CustomButton
            // type="primary"
          disabled={isSignatureVisibleLoading}

            onClick={() => {
              modelClose(form);
            }}
          >
            {formatMessage({ id: "cancel" })}
          </CustomButton>
          <CustomButton
          disabled={isSignatureVisibleLoading}

            type="primary"
            onClick={() => createStaffSubmit(form)}
            key={"submit"}
            loading={isSignatureVisibleLoading}
          >
            {formatMessage({ id: "submit" })}
          </CustomButton>
        </div>
      }
    >
      <Form
        className="signatureForm"
        style={{ width: "100%" }}
        form={form}
        name="editcomment"
        scrollToFirstError
      >
        <Form.Item
          className="signatureradio"
          labelAlign="left"
          labelCol={{ xs: { span: 24 } }}
          wrapperCol={{ xs: { span: 24 } }}
          label={<h4>{formatMessage({ id: "type_of_input" })}</h4>}
          name="signaturetype"
          rules={
            [
              // {
              //   required: true,
              //   message: formatMessage({ id: "rule_description" }),
              // },
            ]
          }
        >
          <Radio.Group
            //  onChange={(e)=>{
            //   form.setFieldsValue({[`signaturetype`]:e.target.value})}}
            //             onChange={(e) => {
            // form.setFieldValue({ [`signaturetype`]: e.target.value }); // Update the form field value
            //}}
            // onChange={(e) => {
            //   form.setFieldValue("signaturetype", e.target.value);
            // }}
            defaultValue={Signaturetype}
            onChange={(e) => setsignaturetype(e.target.value)}
          >
            {SignatureselectionData.map((item, index) => (
              <Radio key={index} value={item.value}>
                {item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {Signaturetype === 1 && (
          <>
            <CustomCard
              bordered
              title={formatMessage({ id: "type_your_signature" })}
              headStyle={{ background: "#EBEDEF" }}
              bodyStyle={{
                background: "#F5F5F5",
                padding: "15px",
              height: "200px",

                // height: "250px",
              }}
              extra={[
                <CustomButton
                  size="small"
                  key="clear"
                  onClick={() => {
                    setTypedText("");
                    form.setFieldsValue({ [`files12`]: "" });
                  }}
                >
                  {formatMessage({ id: "clear" })}
                </CustomButton>,
              ]}
            >
              <Form.Item
                className="signature-canvas"
                name="files12"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: "signature_required" }),
                  },
                ]}
              >
                <Input value={typedText} onChange={handleTextChange} />
              </Form.Item>
              <div
              style={{
                border: "1px solid black",
                margin: "10px",
                height: "140px",
                display:"flex",
                justifyContent:"center",
                // overflow:"hidden",
                whiteSpace:"nowrap",
                padding:"3px",
              }}
            >
              <div
                className="typed-signature"
                style={{
                  fontSize:window.innerWidth>982?"60px":window.innerWidth<=982&&window.innerWidth>=480?"44px":"26px",
                  // paddingTop: "35px",
                  padding:"10px",
                  fontFamily: "Qwitcher Grypen, cursive",
                  fontWeight: "600",
                 
               width:"400px",
                  maxWidth:"400px",
                  overflowX:"scroll",
                  // height: "150px",
                  textAlign: "center",
                }}
              >
                {typedText}
              </div>
              </div>
            </CustomCard>
          </>
        )}
        {Signaturetype === 2 && (
          <CustomCard
            bordered
            title={formatMessage({ id: "signature" })}
            headStyle={{ background: "#EBEDEF" }}
            bodyStyle={{
              background: "#F5F5F5",
              height: "152px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
            }}
            extra={[
              <CustomButton
                size="small"
                key="clear"
                onClick={() => {
                  sigCanvas && sigCanvas.clear();
                }}
              >
                {formatMessage({ id: "clear" })}
              </CustomButton>,
            ]}
          >
            <Form.Item
              className="signature-canvas"
              name="files"
              rules={[
                {
                  required: true,
                  message: formatMessage({ id: "signature_required" }),
                },
              ]}
            >
              <SignaturePad
                canvasProps={{ width: 450, height: 120 }}
                signatureRef={(ref) => {
                  sigCanvas = ref;
                }}
              />
            </Form.Item>
          </CustomCard>
        )}
        {Signaturetype === 3 && (
          <>
            {base64fFile ? (
              <CustomCard
                bordered
                title={formatMessage({ id: "signature" })}
                headStyle={{ background: "#EBEDEF" }}
                bodyStyle={{
                  background: "#F5F5F5",
                  height: "160px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "15px",
                }}
                extra={[
                  <CustomButton
                    size="small"
                    key="clear"
                    onClick={() => {
                      sigCanvas && sigCanvas.clear();
                      setbase64fFile("");
                      setuploadFiles([]);
                    }}
                  >
                    {formatMessage({ id: "clear" })}
                  </CustomButton>,
                ]}
              >
                {/* <div className=""> */}
                <img className="signature-view" src={base64fFile} alt="" />
                {/* </div> */}
              </CustomCard>
            ) : (
              <Dragger height={200} {...draggerprops}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            )}
          </>
        )}
      </Form>
    </CustomModal>
  );
};

export default SignatureModal;
