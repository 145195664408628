import { createAsyncThunk } from "@reduxjs/toolkit";
import StaffServices from "./../../Services/staffServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { getChatRoomApi } from "../users/userActions";
export const getStaffdetails = createAsyncThunk(
  "staff/getStaffdetails",
  async (data, thunkAPI) => {
    try {
      let res = await StaffServices.getstaffAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const staffCreate = createAsyncThunk(
  "staff/createStaff",
  async ({ data, staffPagination }, thunkAPI) => {
    try {
      const res = await StaffServices.createstaffAPI(data);
      thunkAPI.dispatch(getStaffdetails(staffPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatestaff = createAsyncThunk(
  "staff/updatestaff",
  async ({ data, staffPagination }, thunkAPI) => {
    try {
      const res = await StaffServices.createstaffAPI(data);
      thunkAPI.dispatch(getStaffdetails(staffPagination));
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletestaff = createAsyncThunk(
  "staff/deletestaff",
  async ({ data, staffPagination }, thunkAPI) => {
    try {
      const res = await StaffServices.deletestaffApi(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getStaffdetails(staffPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Resetpassword = createAsyncThunk(
  "staff/Resetpassword",
  async (data, thunkAPI) => {
    try {
      const res = await StaffServices.ResetpasswordApi(data);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getdepartmentList = createAsyncThunk(
  "staff/getdepartmentList",
  async (data, thunkAPI) => {
    try {
      let res = await StaffServices.getDeptApi({
        // ...data,
        // limit: data.pageSize,
        // page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
