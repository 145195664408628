import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/user";
class stffService {
  getstaffAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/users`, data, headers);
  createstaffAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/sign-up`, data, headers);
  deletestaffApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  ResetpasswordApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/updateUserProfile`, data, headers);
  getDeptApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/dept-list`, data, headers);
}
// eslint-disable-next-line
export default new stffService();
