import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/historicalContractSetting";
class HistoricalServices {
  gethistoricalContractSettingAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}`, data, headers);
  createhistoricalContractAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  deletehistoricalContractApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/delete/${data}`, data, headers);
  importworkflowTypefromadminAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/import`, data, headers);
  deleteadminworkflowTypeApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/remove`, data, headers);
}
// eslint-disable-next-line
export default new HistoricalServices();
