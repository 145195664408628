import { message } from "antd";
const checkStatusToMsgType = (statusCode) => {
  if (statusCode === 401) {
    // this.clearAllCookies();
    // this.allStore.routes.history.push("/user/login");
    return "error";
  }
  switch (statusCode) {
    case 201: // CREATED: 201
    case 200: // OK: 200
      return "success";
    case 401:
    case 400: // BAD_REQUEST: 400
    case 404:
    case 420: // METHOD_FAILURE: 420
    case 406:
    case 403:
      return "error";
    case 206: // PARTIAL_CONTENT: 206
    case 409: // CONFLICT: 409
    case 422:
      return "warning";
    case 500:
      return "error";
    default:
      return "success";
  }
};
export const responseMessageHandler = (res = {}) => {
  if (res && res.STATUS_CODE) {
    if (res.message) {
      if (typeof res.message === "string") {
        message[checkStatusToMsgType(res.STATUS_CODE)](res.message);
      } else if (typeof res.message === "object") {
        message[checkStatusToMsgType(res.STATUS_CODE)](res.message.en);
      } else if (Array.isArray(res.errors)) {
      }
    } else {
      !res.data && console.error(res);
    }
  } else {
    !res.data && console.error(res);
  }
};
