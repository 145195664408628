import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/task";
const subUrllocal1 = "/api/v1/cloudStorage";
const subUrllocalobg = "/api/v1/myobligations";
const addendumurl="/api/v1/task"
class myContractServices {
  getContractsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/mycontracts`, data, headers);
  getvendordashboarddetailsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/vendor`, data, headers);
  getAdmindashboarddetailsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/admin`, data, headers);
  getContractsforcompanyAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/mycontractscompany`, data, headers);
  getbucketsAPI = (data) =>
    RestfulProvider.postWithOutCompanyId(
      `${subUrllocal1}/getBucket`,
      data,
      headers
    );
  getVendorListsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/vendorsContract`, data, headers);
  getContractByVendorAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/contractsByVendor`, data, headers);
  getAddendumTaskAPi = (data) =>
    RestfulProvider.post(`${subUrllocal}/addendumByTask`, data, headers);
  getContractDetailAPi = (data) =>
    RestfulProvider.post(`${subUrllocal}/contractDetails`, data, headers);
    getgetcontractVerifyAPI= (data) =>
    RestfulProvider.formDatapost(`${subUrllocal}/verifyContract`, data, headers);
    getAcceptorrejectobligationAPI= (data) =>
    RestfulProvider.post(`${subUrllocalobg}/obligationaction`, data, headers);
    createAddendumforTasksAPI= (data) =>
    RestfulProvider.post(`${addendumurl}/createV2`, data, headers);
    createRenewofTasksAPI= (data) =>
    RestfulProvider.post(`${addendumurl}/createV2`, data, headers);
    getRenewalsInfoOfContractAPI= (data) =>
      RestfulProvider.post(`/api/v1/renewal/renewalsInfo`, data, headers);
}
// eslint-disable-next-line
export default new myContractServices();
