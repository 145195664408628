import { createAsyncThunk } from "@reduxjs/toolkit";
import VendorServices from "./../../Services/VendorServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { getVendordetailsList } from "../Taskmanagement/Taskmanagement.Action";
import { getDetails } from "../TaskmanagementVendor/TaskmanagementVendor.Action";
import { getChatRoomApi } from "../users/userActions";
import { updateVendorData } from "../users/user.slice";
export const getVendordetails = createAsyncThunk(
  "vendor/getVendordetails",
  async (data, thunkAPI) => {
    try {
      let res = await VendorServices.getvendorAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      // thunkAPI.dispatch(getChatRoomApi());

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Createvendor = createAsyncThunk(
  "vendor/Createvendor",
  async (
    { data, vendorPagination, vendor_model_type, drawer_record_for_task },
    thunkAPI
  ) => {
    try {
      const res = await VendorServices.createvendorAPI(data);
      if (vendor_model_type === "vendor_new_one") {
        const { pathname } = window.location;
        if (!pathname.includes("basic_Details")) {
          thunkAPI.dispatch(getChatRoomApi());
          if (vendor_model_type === "vendor_selection") {
            thunkAPI.dispatch(getVendordetailsList(vendorPagination));
          } else {
            thunkAPI.dispatch(getVendordetails(vendorPagination));
          }
        }
        if (pathname.includes("basic_Details")) {
          thunkAPI.dispatch(updateVendorData());
        }
        thunkAPI.dispatch(getDetails(drawer_record_for_task));
      }
      thunkAPI.dispatch(getVendordetails(vendorPagination));

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GuestCreatevendor = createAsyncThunk(
  "vendor/GuestCreatevendor",
  async (
    { data, vendorPagination, vendor_model_type, drawer_record_for_task },
    thunkAPI
  ) => {
    try {
      const res = await VendorServices.createvendorAPI(data);
      thunkAPI.dispatch(updateVendorData());
      thunkAPI.dispatch(getDetails(drawer_record_for_task));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletevendor = createAsyncThunk(
  "vendor/deletevendor",
  async ({ data, vendorPagination }, thunkAPI) => {
    try {
      const res = await VendorServices.deletevendorAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getVendordetails(vendorPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Resetpassword = createAsyncThunk(
  "staff/Resetpassword",
  async (data, thunkAPI) => {
    try {
      const res = await VendorServices.ResetpasswordApi(data);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const FillandUpdateGuestvendordata = createAsyncThunk(
  "vendor/FillandUpdateGuestvendordata",
  async (
    { data, vendorPagination, vendor_model_type, drawer_record_for_task },
    thunkAPI
  ) => {
    try {
      const res = await VendorServices.FillandUpdateGuestvendordataAPI(data);
      
      // thunkAPI.dispatch(getVendordetails(vendorPagination));

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
