import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/workflowtype";
class TemplateServices {
  getworkflowTypeAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}`, data, headers);
  createworkflowTypeAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  deleteworkflowTypeApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  importworkflowTypefromadminAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/import`, data, headers);
  deleteadminworkflowTypeApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/remove`, data, headers);
}
// eslint-disable-next-line
export default new TemplateServices();
