import { createAsyncThunk } from "@reduxjs/toolkit";
import taskService from "./../../Services/taskServicesVendor";
import { responseMessageHandler } from "../../globals/components/notification";
import { setcontractsuccessModal } from "../myContracts/myContract.Slice";
import { reviewCommentslist } from "../MyTask/MyTask.Action";
import {
  setDrawerVisible,
  setchecklistModal,
} from "./TaskmanagementVendor.Slice";
import { message } from "antd";
import { SetAdobesignModalvisible } from "../MyTask/MyTask.Slice";
import { CustomNotification } from "../../globals/components/Notificationpop";
export const getTaskdetails = createAsyncThunk(
  "task/getTaskdetails",
  async (data, thunkAPI) => {
    try {
    
      let res = await taskService.getTaskAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
        orderBy: "updatedAt",
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTask = createAsyncThunk(
  "task/createTask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.createTaskAPI(data);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "task/deleteTask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.deleteTaskApi(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTaskcomments = createAsyncThunk(
  "task/getTaskcomments",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getCommentAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTaskcomment = createAsyncThunk(
  "task/createTaskcomment",
  async ({ data, TaskcommentPagination }, thunkAPI) => {
    try {
      const res = await taskService.createCommentAPI(data);
      thunkAPI.dispatch(getTaskcomments(TaskcommentPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const tracktemplatehistory = createAsyncThunk(
  "task/tracktemplatehistory",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.tracktemplatehistoryAPI(data);
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteTaskComment = createAsyncThunk(
  "task/deleteTaskComment",
  async ({ data, TaskcommentPagination }, thunkAPI) => {
    try {
      const res = await taskService.deletCommentAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getTaskcomments(TaskcommentPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDetails = createAsyncThunk(
  "staff/getDetails",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getDetailApi(data);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTemplateTask = createAsyncThunk(
  "task/createTemplateTask",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.createTemplateTaskAPI(data);
      // thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const taskAssignee = createAsyncThunk(
  "template/taskAssignee",
  async (recData, thunkAPI) => {
    try {
      const res = await taskService.taskAssigneeApi(recData);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const taskConnect = createAsyncThunk(
  "template/taskConnect",
  async (recData, thunkAPI) => {
    try {
      const res = await taskService.taskContentApi(recData);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const taskActions = createAsyncThunk(
  "vendortask/taskActions",
  async ({ rscData, taskReq, newtaskPagination }, thunkAPI) => {
    try {
      const res = await taskService.taskActionApi(rscData);
      thunkAPI.dispatch(getDetails(taskReq));
      thunkAPI.dispatch(getTaskdetails(newtaskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      // const message =
      //   (error.response && error.response.data && error.response.data.error) ||
      //   error.message ||
      //   error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const reportDownload = createAsyncThunk(
  "task/reportDownload",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.signApiDownload(data);
      var file = new Blob([res], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return fileURL;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const uploadrequestforChange = createAsyncThunk(
  "task/uploadrequestforChange",
  async ({ data, newtaskPagination }, thunkAPI) => {
    try {
      let res = await taskService.uploadrequestforChangAPI({
        ...data,
      });
      thunkAPI.dispatch(getTaskdetails(newtaskPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTaskactivitylist = createAsyncThunk(
  "task/getTaskactivitylist",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getTaskactivitylistAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifycontracthash = createAsyncThunk(
  "task/verifycontracthash",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.verifycontracthashAPI(data);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContentconfirmed = createAsyncThunk(
  "task/getContentconfirmed",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.getContentconfirmedAPI(data);
      thunkAPI.dispatch(
        reviewCommentslist({
          companyId: data?.companyId,
          taskId: data?.taskId,
          vendorId: data?.vendorId,
        })
      );
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const CreateagreementgetVendorsignwithadobe = createAsyncThunk(
  "task/Createagreementgetsignwithadobeforvendor",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.CreateagreementgetsignwithadobeAPI(data);

      if (res.data.signed_by_vendor) {
        thunkAPI.dispatch(setDrawerVisible({ visible: false }));
        thunkAPI.dispatch(getTaskdetails(taskPagination));
        message.success("Signature submit successfully");
        thunkAPI.dispatch(
          SetAdobesignModalvisible({ visible: false, record: {} })
        );
      }
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getGuestTaskDetails = createAsyncThunk(
  "Taskvendor/getGuestTaskDetails",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getGuestTaskDetailsApi(data);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GuesttaskActions = createAsyncThunk(
  "vendortask/GuesttaskActions",
  async ({ rscData, taskReq, newtaskPagination }, thunkAPI) => {
    try {
      const res = await taskService.GuesttaskActionsApi(rscData);
      thunkAPI.dispatch(getGuestTaskDetails(taskReq));
      CustomNotification(
        "success",
        "Approved successfully",
        "An imminent request for your signature is on its way. Stay tuned for the email"
      );
      // thunkAPI.dispatch(getTaskdetails(newtaskPagination));
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      // const message =
      //   (error.response && error.response.data && error.response.data.error) ||
      //   error.message ||
      //   error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const signatureApicall = createAsyncThunk(
  "task/signatureApicall",
  async (
    {
      data,
      taskReq,
      isvendorlastsignature,
      newtaskPagination,
      signModalopenfrom,
    },
    thunkAPI
  ) => {
    try {
      const res = await taskService.signApi({
        ...data,
        isGuestUser: signModalopenfrom === "guest" ? true : false,
      });
      if (signModalopenfrom === "guest") {
        CustomNotification(
          "success",
          "Signature submitted successfully",
          "Please wait for other parties to sign"
        );

        thunkAPI.dispatch(
          getGuestTaskDetails({
            ...taskReq,
            isGuestUser: signModalopenfrom === "guest" ? true : false,
          })
        );
      } else {
        thunkAPI.dispatch(getDetails(taskReq));
        thunkAPI.dispatch(getTaskdetails(newtaskPagination));
        responseMessageHandler(res);
      }

      isvendorlastsignature && thunkAPI.dispatch(setcontractsuccessModal(true));

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const checklistSubmit = createAsyncThunk(
  "task/checklistSubmit",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.checklistApi(data?.reqData);
     
      thunkAPI.dispatch(setchecklistModal({ visible: false, record: {} }));

      if (data?.type === "checklistactionforemail") {
        thunkAPI.dispatch(
          getGuestTaskDetails({
            taskId: data?.reqData?.taskId,
            isGuestUser: true,
          })
        );
      }

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
