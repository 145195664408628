import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/task/company";
const cronurllocal = "/api/v1/cronjob";
class otherService {
  cronjobDurationAPI = (data) =>
    RestfulProvider.post(`${cronurllocal}/setDuration`, data, headers);
  createTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  getcronjobetailsAPI = (data) =>
    RestfulProvider.post(`${cronurllocal}/getDuration`, data, headers);
}
// eslint-disable-next-line
export default new otherService();
