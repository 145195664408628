import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/historicalContractSetting";
const localurl="/api/v1"
class stffService {
  getHistoricalListAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/historical_list`, data, headers);
  createHistoricalContract = (data) =>
    RestfulProvider.formDatapost(
      `${subUrllocal}/historical_create`,
      data,
      headers
    );
  deleteTaskApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/historical_delete`, data, headers);
  ResetpasswordApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/updateUserProfile`, data, headers);
  getHistoricaljsondataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/getforms`, data, headers);
  updateHistoricaljsondataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/updateDefaultFields`, data, headers);
  CreatehistoricalContractsAPI = (data) =>
    RestfulProvider.formDatapost(`${subUrllocal}/submit`, data, headers);
    GetbufferdatafromUrlAPI= (data) =>
    RestfulProvider.downloadMethod(`${localurl}/content/getpdf`, data, headers);
}
// eslint-disable-next-line
export default new stffService();
