import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/task";

class VerifyServices {
    getgetcontractVerifyAPI= (data) =>
        RestfulProvider.formDatapost(`${subUrllocal}/verifyContract`, data, headers);
}
// eslint-disable-next-line
export default new VerifyServices();
