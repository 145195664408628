import { createAsyncThunk } from "@reduxjs/toolkit";
import ProfileServices from "./../../Services/ProfileServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { getChatRoomApi } from "../users/userActions";
import { logout } from "../users/user.slice";
import { message } from "antd";
export const profileupdate = createAsyncThunk(
  "profile/profileupdate",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.profileupdateAPI(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const companyprofileupdate = createAsyncThunk(
  "profile/companyprofileupdate",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.companyprofileupdateAPI(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const profilepasswordupdate = createAsyncThunk(
  "profile/profilepasswordupdate",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.profileupdateAPI(data);
      thunkAPI.dispatch(logout());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getprofileDetails = createAsyncThunk(
  "profile/getprofileDetails",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.getprofileDetailsAPI(data);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getotpforupdate = createAsyncThunk(
  "profile/getotpforupdate",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.getotpforupdateAPI();
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getcompvendordetails = createAsyncThunk(
  "profile/getcompvendordetails",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.getcompvendordetailsAPI();
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Vendorprofileupdate = createAsyncThunk(
  "profile/Vendorprofileupdate",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.VendorprofileupdateAPI(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Vendorpasswordprofileupdate = createAsyncThunk(
  "profile/Vendorpasswordprofileupdate",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.VendorprofileupdateAPI(data);
      thunkAPI.dispatch(logout());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setuserSignature = createAsyncThunk(
  "profile/setuserSignature",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.setuserSignatureAPI(data);
      await thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setVendorSignature = createAsyncThunk(
  "profile/setVendorSignature",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.setVendorSignatureAPI(data);
      await thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteUserSignature = createAsyncThunk(
  "profile/deleteUserSignature",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.setuserSignatureAPI(data);
      await thunkAPI.dispatch(getChatRoomApi());
      message.success("Signature deleted successfully");
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletevendorSignature = createAsyncThunk(
  "profile/deletevendorSignature",
  async (data, thunkAPI) => {
    try {
      let res = await ProfileServices.setVendorSignatureAPI(data);
     await thunkAPI.dispatch(getChatRoomApi());
      message.success("Signature deleted successfully");
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);