import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/vendor";
const GuestsubUrllocal = "/api/v1/guest";
class VendorServices {
    getvendorAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/vendors`, data, headers);
    createvendorAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/sign-up`, data, headers);
   
    deletevendorAPI = (data) =>
    
   RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
   FillandUpdateGuestvendordataAPI = (data) =>
   RestfulProvider.post(`${GuestsubUrllocal}/fillVendor`, data, headers);
}
// eslint-disable-next-line
export default new VendorServices();
