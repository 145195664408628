import { createAsyncThunk } from "@reduxjs/toolkit";
import WorkflowServices from "./../../Services/workflowServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { setDrawerVisible } from "./Workflow.slice";
import { getChatRoomApi } from "../users/userActions";
export const getWorkflowdetails = createAsyncThunk(
  "workflow/getWorkflowdetails",
  async (data, thunkAPI) => {
    try {
      let res = await WorkflowServices.getworkflowAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      // thunkAPI.dispatch(getChatRoomApi())

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createworkflow = createAsyncThunk(
  "workflow/createworkflow",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.createworkflowAPI(data);
      thunkAPI.dispatch(setDrawerVisible({ visible: true, record: res.data}));
      thunkAPI.dispatch(getworkdetails({ workflow_id: res.data._id }));
thunkAPI.dispatch(getApprovadetails({}))
      thunkAPI.dispatch(getWorkflowdetails(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateworkflow = createAsyncThunk(
  "workflow/updateworkflow",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.createworkflowAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getWorkflowdetails(workflowPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getworkdetails = createAsyncThunk(
  "workflow/getworkdetails",
  async (data, thunkAPI) => {
    try {
      const res = await WorkflowServices.getworkdetailsAPI(data);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteworkflow = createAsyncThunk(
  "workflow/deleteworkflow",
  async ({ data, deleteworkflowpagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.deleteworkflowAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getWorkflowdetails(deleteworkflowpagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Nextstep = createAsyncThunk(
  "workflow/Nextstep",
  async ({ data, workdata, workflowPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.getworkflowstepAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getworkdetails(workdata));
      thunkAPI.dispatch(getWorkflowdetails(workflowPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getApprovadetails = createAsyncThunk(
  "workflow/getApprovadetails",
  async (data, thunkAPI) => {
    try {
      const res = await WorkflowServices.getApprovadetailsAPI(data);
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createApproval = createAsyncThunk(
  "workflow/createApproval",
  async ({ data, approvalPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.createApprovalAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(
        getApprovadetails({
          // ...approvalPagination,
          workflow_id: data.workflowId,
        })
      );
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteApproval = createAsyncThunk(
  "workflow/deleteApproval",
  async ({ data, approvalPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.deleteApprovalAPI(data);

      responseMessageHandler(res);
      thunkAPI.dispatch(getApprovadetails(approvalPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getcomments = createAsyncThunk(
  "workflow/getcomments",
  async (data, thunkAPI) => {
    try {
      const res = await WorkflowServices.getcommentsAPI(data);

      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createcomments = createAsyncThunk(
  "workflow/createcomments",
  async ({ data, commentdataPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.createcommentsAPI(data);

      responseMessageHandler(res);
      thunkAPI.dispatch(getcomments(commentdataPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletecomments = createAsyncThunk(
  "workflow/deletecomments",
  async ({ item, commentdataPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.deletecommentsAPI(item);

      responseMessageHandler(res);
      thunkAPI.dispatch(getcomments(commentdataPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContentByTemplate = createAsyncThunk(
  "workflow/getContentByTemplate",
  async (data, thunkAPI) => {
    try {
      const res = await WorkflowServices.getContentByTemplateAPI(data);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Approveworkflow = createAsyncThunk(
  "workflow/Approveworkflow",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.ApproveworkflowAPI(data);
      thunkAPI.dispatch(getWorkflowdetails(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const rejectworkflow = createAsyncThunk(
  "workflow/rejectworkflow",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.rejectworkflowAPI(data);
      thunkAPI.dispatch(getWorkflowdetails(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Assignworkflowtocompany = createAsyncThunk(
  "workflow/Assignworkflowtocompany",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await WorkflowServices.AssignworkflowtocompanyAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getWorkflowdetails(workflowPagination));
      thunkAPI.dispatch(setDrawerVisible({ visible: false, detail: {} }));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAssignableCompanydetails = createAsyncThunk(
  "workflow/getAssignableCompanydetails",
  async (data, thunkAPI) => {
    try {
      const res = await WorkflowServices.getAssignableCompanydetailsAPI(data);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addObligationToWorkflow = createAsyncThunk(
  "workflow/addObligationToWorkflow",
  async (data, thunkAPI) => {
    try {
      const res = await WorkflowServices.addObligationToWorkflowAPI(data);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
