import { createAsyncThunk } from "@reduxjs/toolkit";
import categoryServices from "./../../Services/categoryServices";
import { responseMessageHandler } from "../../globals/components/notification";
export const getCategory = createAsyncThunk(
  "category/getCategory",
  async (data, thunkAPI) => {
    try {
      let res = await categoryServices.getCategoryAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createCategory = createAsyncThunk(
  "category/createcategory",
  async ({ data, categoryPagination }, thunkAPI) => {
    try {
      const res = await categoryServices.createCategoryAPI(data);
      thunkAPI.dispatch(getCategory(categoryPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async ({ data, categoryPagination }, thunkAPI) => {
    try {
      const res = await categoryServices.createCategoryAPI(data);
      thunkAPI.dispatch(getCategory(categoryPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletecategory = createAsyncThunk(
  "category/deletecategory",
  async ({ data, categoryPagination }, thunkAPI) => {
    try {
      const res = await categoryServices.deletecategoryApi(data);
      thunkAPI.dispatch(getCategory(categoryPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getCategoryfromadmin = createAsyncThunk(
  "category/getCategoryfromadmin",
  async (data, thunkAPI) => {
    try {
      let res = await categoryServices.getCategoryAPI({
        getplatformadminData: true,
        toImport: true,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const importCategoryfromadmin = createAsyncThunk(
  "category/importCategoryfromadmin",
  async ({ data, categoryPagination }, thunkAPI) => {
    try {
      let res = await categoryServices.importCategoryfromadminAPI(data);
      thunkAPI.dispatch(getCategory(categoryPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteadmincategory = createAsyncThunk(
  "category/deleteadmincategory",
  async ({ data, categoryPagination }, thunkAPI) => {
    try {
      const res = await categoryServices.deleteadmincategoryApi(data);
      thunkAPI.dispatch(getCategory(categoryPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
