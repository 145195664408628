import { createAsyncThunk } from "@reduxjs/toolkit";

import Historicalservices from "./../../Services/Historicalservices";
import { responseMessageHandler } from "../../globals/components/notification";
export const gethistoricalContractSetting = createAsyncThunk(
  "historicalContract/gethistoricalContractSetting",
  async (data, thunkAPI) => {
    try {
      let res = await Historicalservices.gethistoricalContractSettingAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createhistoricalContract = createAsyncThunk(
  "historicalContract/createhistoricalContract",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await Historicalservices.createhistoricalContractAPI(data);
      thunkAPI.dispatch(gethistoricalContractSetting(workflowPagination));
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatehistoricalContract = createAsyncThunk(
  "historicalContract/updatehistoricalContract",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await Historicalservices.createhistoricalContractAPI(data);
      thunkAPI.dispatch(gethistoricalContractSetting(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletehistoricalContract = createAsyncThunk(
  "historicalContract/deletehistoricalContract",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await Historicalservices.deletehistoricalContractApi(data);
      thunkAPI.dispatch(gethistoricalContractSetting(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getworkflowType = createAsyncThunk(
  "workflowType/getworkflowType",
  async (data, thunkAPI) => {
    try {
      let res = await Historicalservices.getworkflowTypeAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getchecklistfromadmin = createAsyncThunk(
  "checklist/getchecklistfromadmin",
  async (data, thunkAPI) => {
    try {
      let res = await Historicalservices.getchecklistAPI({
        getplatformadminData: true,
        toImport: true,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const importchecklistfromadmin = createAsyncThunk(
  "checklist/importchecklistfromadmin",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      let res = await Historicalservices.importchecklistfromadminAPI(data);
      thunkAPI.dispatch(gethistoricalContractSetting(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteadminchecklist = createAsyncThunk(
  "checklist/deleteadminchecklist",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await Historicalservices.deleteadminchecklistApi(data);
      thunkAPI.dispatch(gethistoricalContractSetting(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
