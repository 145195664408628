import React from "react";
import SignatureCanvas from "react-signature-canvas";

class SignaturePad extends React.Component {
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ("value" in nextProps) {
      return {
        ...nextProps.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const value = props.value;
    this.state = {
      dataURL: value,
    };
  }
  componentDidMount() {
    this.props.signatureRef && this.props.signatureRef(this);
  }

  handleCurrencyChange = async (currency) => {
    // let dataURL = await urltoFile(
    //   this.sigCanvas.getCanvas().toDataURL("image/png"),
    //   "signature"
    // );
    const dataURL = this.sigCanvas.toDataURL();
    if (!("value" in this.props)) {
      this.setState({ dataURL });
    }
    this.triggerChange({ dataURL });
  };

  triggerChange = ({ dataURL }) => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange(dataURL);
    }
  };
  clear = () => {
    this.sigCanvas.clear();
    this.setState({ dataURL: undefined });
    this.triggerChange({ dataURL: undefined });
  };
  render() {
    const {
      penColor = "black", // "#747272",//
      canvasProps = { width: 300, height: 120, className: "sigCanvas" },
    } = this.props;
    return (
      <span>
        <SignatureCanvas
          backgroundColor="#fff"
          penColor={penColor}
          ref={(ref) => {
            this.sigCanvas = ref;
          }}
          onEnd={this.handleCurrencyChange}
          canvasProps={canvasProps}
          // minWidth={0.9}
          // maxWidth={1.5}
        />
      </span>
    );
  }
}
export default SignaturePad;
