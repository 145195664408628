import { createSlice } from "@reduxjs/toolkit";

import {
  getLicencingData,
  createLicencingData,
  deleteLicencingData,
} from "./Licences.Action";
const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  currentduration: 604800000,
  durationsetin: "week",
  isLicenceVisible: false,
  LicenceModalType: "",
  LicencePagination: {
    total: 0,
    pageSize: 10,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  Licencedatalist: [],
  editLicencingrecord: {},
  isLicencingcreating: false,
};
const LicencesSlice = createSlice({
  name: "LicencesSlice",
  initialState: initialState,
  reducers: {
    setLicenceModalVisible: (state, { payload }) => {
      state.isLicenceVisible = payload.visible;
      state.LicenceModalType = payload?.type || "";
      state.editLicencingrecord = payload?.record || {};
    },
    onPageChangesFilter: (state, { payload }) => {
      state.LicencePagination = {
        ...state.LicencePagination,
        current: payload.current,
        pageSize: payload.size,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLicencingData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLicencingData.fulfilled, (state, { payload }) => {
        state.Licencedatalist = payload?.data;
        state.isLoading = false;
        state.LicencePagination = {
          ...state.LicencePagination,
          total: payload.total,
        };
      })
      .addCase(getLicencingData.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createLicencingData.pending, (state) => {
        state.isLicencingcreating = true;
      })
      .addCase(createLicencingData.fulfilled, (state, { payload }) => {
        state.isLicenceVisible = false;
        state.isLicencingcreating = false;

        if (state.LicenceModalType === "edit") {
          state.editLicencingrecord = {};
        }
      })
      .addCase(createLicencingData.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isLicencingcreating = false;
      })
      .addCase(deleteLicencingData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteLicencingData.fulfilled, (state, { payload }) => {
        state.currentduration =
          payload?.data?.duration || state.currentduration;

        if ((payload?.data?.duration / 604800000) % 1 === 0) {
          state.durationsetin = "week";
        } else {
          state.durationsetin = "day";
        }
      })
      .addCase(deleteLicencingData.rejected, (state, { payload }) => {
        state.isLoading = false;
      });
  },
});
export const { setLicenceModalVisible, onPageChangesFilter } =
  LicencesSlice.actions;

export default LicencesSlice.reducer;
