import { createAsyncThunk } from "@reduxjs/toolkit";
import contentServices from "../../Services/contenttypeservices";
import { responseMessageHandler } from "../../globals/components/notification";
import { getChatRoomApi } from "../users/userActions";
export const getContenttype = createAsyncThunk(
  "content/getContenttype",
  async (data, thunkAPI) => {
    try {
     
      let res = await contentServices.getContenttypeAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createcontenttype = createAsyncThunk(
  "content/createcontenttype",
  async ({ data, contenttypePagination }, thunkAPI) => {
    try {
      const res = await contentServices.createContenttypeAPI(data);
      thunkAPI.dispatch(getContenttype(contenttypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatecontenttype = createAsyncThunk(
  "content/updatecontenttype",
  async ({ data, contenttypePagination }, thunkAPI) => {
    try {
      const res = await contentServices.createContenttypeAPI(data);
      thunkAPI.dispatch(getContenttype(contenttypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletecontenttype = createAsyncThunk(
  "content/deletecontenttype",
  async ({ data, contenttypePagination }, thunkAPI) => {
    try {
      const res = await contentServices.deletecontenttypeApi(data);
      thunkAPI.dispatch(getContenttype(contenttypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSampleContent = createAsyncThunk(
  "content/getSampleContent",
  async (data, thunkAPI) => {
    try {
      let res = await contentServices.getSampleContentAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      // thunkAPI.dispatch(getChatRoomApi())
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createandupdateSampleContent = createAsyncThunk(
  "content/createandupdateSampleContent",
  async ({ data, samplecontentPagination }, thunkAPI) => {
    try {
      const res = await contentServices.createandupdateSampleContentAPI(data);
      thunkAPI.dispatch(getSampleContent(samplecontentPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteSampleContent = createAsyncThunk(
  "content/deleteSampleContent",
  async ({ data, samplecontentPagination }, thunkAPI) => {
    try {
      const res = await contentServices.deleteSampleContentApi(data);
      thunkAPI.dispatch(getSampleContent(samplecontentPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContenttypefromadmin = createAsyncThunk(
  "content/getContenttypefromadmin",
  async (data, thunkAPI) => {
    try {
      let res = await contentServices.getContenttypeAPI({
        getplatformadminData: true,
        toImport: true,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const importContenttypefromadmin = createAsyncThunk(
  "content/importContenttypefromadmin",
  async ({ data, contenttypePagination }, thunkAPI) => {
    try {
      let res = await contentServices.importCategoryfromadminAPI(data);
      thunkAPI.dispatch(getContenttype(contenttypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteadmincontenttype = createAsyncThunk(
  "content/deleteadmincontenttype",
  async ({ data, contenttypePagination }, thunkAPI) => {
    try {
      const res = await contentServices.deleteadmincontenttypeApi(data);
      thunkAPI.dispatch(getContenttype(contenttypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getsamplecontentfromadmin = createAsyncThunk(
  "content/getsamplecontentfromadmin",
  async (data, thunkAPI) => {
    try {
      let res = await contentServices.getSampleContentAPI({
        getplatformadminData: true,
        toImport: true,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const importsamplecontentfromadmin = createAsyncThunk(
  "content/importsamplecontentfromadmin",
  async ({ data, samplecontentPagination }, thunkAPI) => {
    try {
      let res = await contentServices.importsamplecontentfromadminAPI(data);
      thunkAPI.dispatch(getSampleContent(samplecontentPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteadminsamplecontent = createAsyncThunk(
  "content/deleteadminsamplecontent",
  async ({ data, samplecontentPagination }, thunkAPI) => {
    try {
      const res = await contentServices.deleteadminsamplecontentApi(data);
      thunkAPI.dispatch(getSampleContent(samplecontentPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const connecttoadobe = createAsyncThunk(
  "content/connecttoadobe",
  async (data, thunkAPI) => {
    try {
      const res = await contentServices.connecttoadobeApi(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const connecttodocuSign = createAsyncThunk(
  "content/connecttodocuSign",
  async (data, thunkAPI) => {
    try {
      const res = await contentServices.connecttodocuSignApi(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const SetConnectwithAdobe = createAsyncThunk(
  "content/SetConnectwithAdobe",
  async (data, thunkAPI) => {
    try {
      const res = await contentServices.SetConnectwithAdobeApi(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const SetConnectwithDocusign = createAsyncThunk(
  "content/SetConnectwithDocusign",
  async (data, thunkAPI) => {
    try {
      const res = await contentServices.SetConnectwithDocusignApi(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const SetdisconnectwithAdobe = createAsyncThunk(
  "content/SetdisconnectwithAdobe",
  async (data, thunkAPI) => {
    try {
      const res = await contentServices.SetdisconnectwithAdobeApi(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Setdisconnectwithdocusign = createAsyncThunk(
  "content/Setdisconnectwithdocusign ",
  async (data, thunkAPI) => {
    try {
      const res = await contentServices.SetdisconnectwithdocusignApi(data);
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getClausesList = createAsyncThunk(
  "content/getClausesList",
  async (data, thunkAPI) => {
    try {
     
      let res = await contentServices.getClausesListAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createAndUpdateClause = createAsyncThunk(
  "content/createAndUpdateClause",
  async ({ data, clausePagination }, thunkAPI) => {
    try {
      const res = await contentServices.createAndUpdateClauseAPI(data);
      thunkAPI.dispatch(getClausesList(clausePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteClause = createAsyncThunk(
  "content/deleteClause",
  async ({ data, clausePagination }, thunkAPI) => {
    try {
      const res = await contentServices.deleteClauseApi(data);
      thunkAPI.dispatch(getClausesList(clausePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
