import { createSlice } from "@reduxjs/toolkit";
import { getcontractVerify } from "./Verify.Action";

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  issubmitted: false,
  ispdfVerifying: false,
  contractDetails: {},
  isContractTempored: false,
  isContractNotFound: false,
  contractMgmtStatus: {
    8: {
      name: "Active",
      value: 0,
      color: "green",
    },
    7: {
      name: "Inactive", // "About to expire in duration",
      value: 1,
      color: "#01aef0",
    },
    9: {
      name: "Expired",
      value: 2,
      color: "red",
    },
    10: {
      name: "Terminated",
      value: 3,
      color: "#800000",
    },
    11: {
      name: "Renewed",
      value: 4,
      color: "#9689E6",
    },
  },
  isContractVerified: null,
};
const verifySlice = createSlice({
  name: "vendor",
  initialState: initialState,
  reducers: {
    verifydatareset: (state, action) => {
      return initialState;
    },
    setissubmitted: (state, action) => {
      state.issubmitted = action.payload;
      if (!action.payload) {
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getcontractVerify.pending, (state, { payload }) => {
        state.ispdfVerifying = true;
        state.contractDetails = {};
        state.isContractVerified = false;
      })
      .addCase(getcontractVerify.fulfilled, (state, { payload }) => {
        state.ispdfVerifying = false;
        state.contractDetails =
          payload?.data?.length > 0 ? payload?.data[0] : [];
        state.isContractVerified = true;
      })
      .addCase(getcontractVerify.rejected, (state, { payload }) => {
        state.ispdfVerifying = false;
        state.contractDetails = {};
        state.isContractVerified = false;

      
        state.isContractNotFound = payload?.STATUS_CODE === 404 ? true : false;
        state.isContractTempored = payload?.STATUS_CODE === 406 ? true : false;
      });
  },
});
export const { verifydatareset, setissubmitted } = verifySlice.actions;

export default verifySlice.reducer;
