import { createSlice } from "@reduxjs/toolkit";
import {
 


  getObligationFormlist,
  createObligationForm,
  deleteObligationForm,
 
} from "./Obligationservices.Action";

const initialState = {
  isLoading: false,
  isCreateLoading: false,
 
  isSuccess: false,
  isError: false,
  isMessage: null,

  workflowTableData: [],
  isVisible: false,

  editRecord: {},
 
  type: "",
  totalCount: null,

  ObligationformPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
 
  Obligationlistdata:[],
  ObligationFormTableData:[],
  newobligationformdata:{},
};
const ObligationserviceSlice = createSlice({
  name: "Obligationservice",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.type = payload?.type;
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
   
    onPageChangesFilter: (state, action) => {
      state.ObligationformPagination = {
        ...state.ObligationformPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.ObligationformPagination = {
        ...state.ObligationformPagination,
        search: action.payload.search || "",
      };
    },
    setnewobligationformdatareset: (state, action) => {
      state.newobligationformdata={}
    },
    obligationservicesdatareset: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getObligationFormlist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getObligationFormlist.fulfilled, (state, { payload }) => {
        state.ObligationFormTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.total;
        state.ObligationformPagination = {
          ...state.ObligationformPagination,
          total: payload.total,
        };
      })
      .addCase(getObligationFormlist.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createObligationForm.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createObligationForm.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        if (state.type === "createfromobligation") {
          state.newobligationformdata = payload.data;
        }
      })
      .addCase(createObligationForm.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
     
      .addCase(deleteObligationForm.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(deleteObligationForm.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(deleteObligationForm.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      
  },
});
export const {
  setModalVisible,
  setObligationlistdata,
  setworkflowtypeVisible,
  onPageChangesFilter,
  workflowtypeonPageChangesFilter,
  onSearchList,
  workflowtypeonSearchList,
  workflowServicedatareset,
  setworkflowchecklistdatareset,
  setselectforworkflowtype,
  setselectforchecklistvisible,
  setnewobligationformdatareset,
  obligationservicesdatareset
} = ObligationserviceSlice.actions;
export default ObligationserviceSlice.reducer;
