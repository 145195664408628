import { createSlice } from "@reduxjs/toolkit";
import {
  getTemplates,
  createTemplate,
  updateTemplate,
  deletetemplate,
} from "./template.Action";
const initialState = {
  isLoading: false,
  isTemplatecreating: false,
  isCreateLoading: false,
  cretaeTempData: {},
  workflownewtemplatedata: {},
  createTempModal: false,
  isSuccess: false,
  isError: false,
  isVisible: false,
  isMessage: "",
  data: [],
  type: "",
  details: [],
  emailDetails: [],
  isDrawerVisible: "",
  templateTableData: [],
  templateData: "",
  EdittemplateData: {},
  pagination: { page: 1, limit: 10 },
  Attribute: [],
  Visible: false,
  isRadioData: [],
  isSelectData: [],
  editVisibletype: "",
  Edata: [],
  textEditor: false,
  templatePagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  createddata: {},
  Tasktrackeddatastore: [],
  locationList: [],
  ExtraRelationFields: [],
  contentwithtemplate: "",
  isTemplateinlimit:{},
};
const templateSlice = createSlice({
  name: "template",
  initialState: initialState,
  reducers: {
    createTemplateModal: (state, { payload }) => {
    
      state.createTempModal = payload.visible;
      state.templateData = payload.record || {};
      state.taskDetailsdata = payload?.taskDetailsdata || {};
      state.type = payload.type;

      state.contentwithtemplate = payload?.content || "";
      if (payload.visible) {
        state.isSuccess = false;
      }
    },
    templateDrawer: (state, { payload }) => {
      state.isDrawerVisible = payload.visible;
      state.DrawerData = payload.record;
    },
    getExtraRelationFields: (state, { payload }) => {
      state.ExtraRelationFields = payload.value;
    },
    addNewRelationship: (state, { payload }) => {
      let reqObj = {
        isLocal: true,
        editable: true,
        edit_aid: state.locationList.length + 1,
        add_aid: Math.floor(Math.random() * 100000000).toString(),
        ot_area_id: "a" + state.locationList.length + 1,
        comp_rel_id: payload.Formdata.comp_rel_id,
        extra: state.ExtraRelationFields,
      };
      let arraList = [...state.locationList, reqObj];
      state.locationList = arraList;
    },

    setTemplateData: (state, { payload }) => {
      state.templateTableData = [...state.templateTableData, payload];
      state.Visible = false;
    },
    EdittemplateData: (state, { payload }) => {
      const data = state.templateTableData.map((pre) => {
        if (pre.title === payload.title) {
          return payload;
        } else {
          return pre;
        }
      });

      state.templateTableData = data;
      state.Visible = false;
    },
    onPageChangesFilter: (state, action) => {
      state.templatePagination = {
        ...state.templatePagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.templatePagination = {
        ...state.templatePagination,
        search: action.payload.search || "",
      };
    },
    Templatedatareset: (state, action) => {
      return initialState;
    },
    setworkflowtemplatedatareset: (state, action) => {
      state.workflownewtemplatedata = {};
    },
    settrackdatastore: (state, action) => {
      state.workflownewtemplatedata = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTemplates.fulfilled, (state, { payload }) => {
        state.templateTableData = payload.data;
        state.isTemplateinlimit=payload.licenseQuota?.template
        state.isLoading = false;
        state.totalCount = payload.total;
        state.templatePagination = {
          ...state.templatePagination,
          total: payload.total,
        };
      })
      .addCase(getTemplates.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createTemplate.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createTemplate.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        state.createTempModal = false;

        // state.cretaeTempData = payload.data;
        if (state.type === "createfromworkflow") {
          state.workflownewtemplatedata = payload.data;
        }
      })
      .addCase(createTemplate.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updateTemplate.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updateTemplate.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.createTempModal = false;
        state.isSuccess = true;
        state.isVisible = false;
        state.templateData = {};
      })
      .addCase(updateTemplate.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(deletetemplate.pending, (state) => {
        //conditions
      })
      .addCase(deletetemplate.fulfilled, (state, { payload }) => {
        //conditions
      })
      .addCase(deletetemplate.rejected, (state, { payload }) => {
        //conditions
      });
  },
});
export const {
  createTemplateModal,
  templateDrawer,
  setTemplateData,
  EdittemplateData,
  onPageChangesFilter,
  onSearchList,
  Templatedatareset,
  setworkflowtemplatedatareset,
  addNewRelationship,
  getExtraRelationFields,
} = templateSlice.actions;
export default templateSlice.reducer;
