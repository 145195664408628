import { createSlice } from "@reduxjs/toolkit";
import {
  createchecklist,
  deletechecklist,
  getchecklists,
  updatechecklist,
  deleteworkflowType,
  createworkflowType,
  getworkflowType,
  getworkflowTypefromadmin,
  importworkflowTypefromadmin,
  getchecklistfromadmin,
  importchecklistfromadmin,
} from "./Workflowservice.Action";

const initialState = {
  isLoading: false,
  isCreateLoading: false,
  workflownewchecklistdata: {},
  isSuccess: false,
  isError: false,
  isMessage: null,
  workflowtypeTableData: [],
  workflowTableData: [],
  isVisible: false,
  isworkflowtypeVisible: false,
  editRecord: {},
  workflowtypeeditRecord: {},
  type: "",
  totalCount: null,
  workflowPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  workflowtypePagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  ischoosechecklistmodalVisible: false,
  ischoosemodalVisible: false,
  workflowtypedatafromadmin: [],
  workflowtypefromadminloading: false,
  ischecklistfromadminloading: false,
  checklistdatafromadmin: [],
  isimportworkflowtypefromadminLoading: false,
  ischecklistimportloading: false,

};
const WorkflowserviceSlice = createSlice({
  name: "workservice",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.type = payload?.type;
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    setselectforworkflowtype: (state, { payload }) => {
      state.ischoosemodalVisible = payload.visible;

      if (!payload.visible) {
      }
    },
    setselectforchecklistvisible: (state, { payload }) => {
      state.ischoosechecklistmodalVisible = payload.visible;

      if (!payload.visible) {
      }
    },
    onPageChangesFilter: (state, action) => {
      state.workflowPagination = {
        ...state.workflowPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.workflowPagination = {
        ...state.workflowPagination,
        search: action.payload.search || "",
      };
    },
    setworkflowtypeVisible: (state, { payload }) => {
      state.type = payload?.type;
      state.isworkflowtypeVisible = payload.visible;
      state.workflowtypeeditRecord = payload.record || {};
      if (!payload.visible) {
        state.workflowtypeeditRecord = {};
      }
    },
    workflowtypeonPageChangesFilter: (state, action) => {
      state.workflowtypePagination = {
        ...state.workflowtypePagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    workflowtypeonSearchList: (state, action) => {
      state.workflowtypePagination = {
        ...state.workflowtypePagination,
        search: action.payload.search || "",
      };
    },
    workflowServicedatareset: (state, action) => {
      return initialState;
    },
    setworkflowchecklistdatareset: (state, action) => {
      state.workflownewchecklistdata = {};
    },
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(getchecklists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getchecklists.fulfilled, (state, { payload }) => {
        state.workflowTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.total;
        state.workflowPagination = {
          ...state.workflowPagination,
          total: payload.total,
        };
      })
      .addCase(getchecklists.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createchecklist.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createchecklist.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        if (state.type === "createfromworkflow") {
          state.workflownewchecklistdata = payload.data;
        }
      })
      .addCase(createchecklist.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updatechecklist.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updatechecklist.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(updatechecklist.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(deletechecklist.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(deletechecklist.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(deletechecklist.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(getworkflowType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getworkflowType.fulfilled, (state, { payload }) => {
        state.workflowtypeTableData = payload.data;
        state.isworkflowtypeVisible = false;
        state.isLoading = false;
        state.totalCount = payload.total;
        state.workflowtypePagination = {
          ...state.workflowtypePagination,
          total: payload.total,
        };
      })
      .addCase(getworkflowType.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createworkflowType.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createworkflowType.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(createworkflowType.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(deleteworkflowType.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(deleteworkflowType.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(deleteworkflowType.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })

      .addCase(getworkflowTypefromadmin.pending, (state) => {
        state.workflowtypefromadminloading = true;
      })
      .addCase(getworkflowTypefromadmin.fulfilled, (state, { payload }) => {
        state.workflowtypedatafromadmin = payload.data;
        state.workflowtypefromadminloading = false;
      })
      .addCase(getworkflowTypefromadmin.rejected, (state, { payload }) => {
        state.workflowtypefromadminloading = false;
      })
      .addCase(importworkflowTypefromadmin.pending, (state) => {
        state.isimportworkflowtypefromadminLoading = true;
      })
      .addCase(importworkflowTypefromadmin.fulfilled, (state, { payload }) => {
        state.ischoosemodalVisible = false;
        state.isimportworkflowtypefromadminLoading = false;
      })
      .addCase(importworkflowTypefromadmin.rejected, (state, { payload }) => {
        state.isimportworkflowtypefromadminLoading = false;
      })
      .addCase(getchecklistfromadmin.pending, (state) => {
        state.ischecklistfromadminloading = true;
      })
      .addCase(getchecklistfromadmin.fulfilled, (state, { payload }) => {
        state.checklistdatafromadmin = payload.data;
        state.ischecklistfromadminloading = false;
      })
      .addCase(getchecklistfromadmin.rejected, (state, { payload }) => {
        state.ischecklistfromadminloading = false;
      })
      .addCase(importchecklistfromadmin.pending, (state) => {
        state.ischecklistimportloading = true;
      })
      .addCase(importchecklistfromadmin.fulfilled, (state, { payload }) => {
        state.ischoosechecklistmodalVisible = false;
        state.ischecklistimportloading = false;
      })
      .addCase(importchecklistfromadmin.rejected, (state, { payload }) => {
        state.ischecklistimportloading = false;
      });
  },
});
export const {
  setModalVisible,

  setworkflowtypeVisible,
  onPageChangesFilter,
  workflowtypeonPageChangesFilter,
  onSearchList,
  workflowtypeonSearchList,
  workflowServicedatareset,
  setworkflowchecklistdatareset,
  setselectforworkflowtype,
  setselectforchecklistvisible,
} = WorkflowserviceSlice.actions;
export default WorkflowserviceSlice.reducer;
