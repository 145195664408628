import { createAsyncThunk } from "@reduxjs/toolkit";
import flowServices from "./../../Services/flowServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { getChatRoomApi } from "../users/userActions";
import { ClearnewhistoricalData } from "../historicalFlowSettings/historicalservice.Slice";
export const getTaskdetails = createAsyncThunk(
  "flow/getTaskdetails",
  async (data, thunkAPI) => {
    try {
      let res = await flowServices.getHistoricalListAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      // thunkAPI.dispatch(getChatRoomApi())

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const historicalFlowCreate = createAsyncThunk(
  "flow/createricalFlow",
  async (payload, thunkAPI) => {
    try {
      const { form_data, historicalTaskPagination } = payload;
      const res = await flowServices.createHistoricalContract(form_data);
      thunkAPI.dispatch(ClearnewhistoricalData());
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatehistoricalFlow = createAsyncThunk(
  "flow/updatehistoricalFlow",
  async ({ data, historicalTaskPagination }, thunkAPI) => {
    try {
      const res = await flowServices.createstaffAPI(data);
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "flow/deleteTask",
  async ({ data, historicalTaskPagination }, thunkAPI) => {
    try {
      const res = await flowServices.deleteTaskApi(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Resetpassword = createAsyncThunk(
  "flow/Resetpassword",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.ResetpasswordApi(data);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHistoricaljsondata = createAsyncThunk(
  "flow/getHistoricaljsondata",
  async (data, thunkAPI) => {
    try {
      let res = await flowServices.getHistoricaljsondataAPI({});
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateHistoricaljsondefaultdata = createAsyncThunk(
  "flow/updateHistoricaljsondata",
  async (data, thunkAPI) => {
    try {
      let res = await flowServices.updateHistoricaljsondataAPI(data);
      thunkAPI.dispatch(getHistoricaljsondata());
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreatehistoricalContracts = createAsyncThunk(
  "flow/CreatehistoricalContracts",
  async (payload, thunkAPI) => {
    try {
      const { form_data, historicalTaskPagination } = payload;
      const res = await flowServices.CreatehistoricalContractsAPI(form_data);
      thunkAPI.dispatch(ClearnewhistoricalData());
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GetbufferdatafromUrl = createAsyncThunk(
  "flow/GetbufferdatafromUrl",
  async (data, thunkAPI) => {
    try {
      
      const res = await flowServices.GetbufferdatafromUrlAPI(data);
     
   
      var file = new Blob([res], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      
      return fileURL;
    
      
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const CreatehistoricalContractsAddendum = createAsyncThunk(
  "flow/CreatehistoricalContractsAddendum",
  async (payload, thunkAPI) => {
    try {
      const { form_data, historicalTaskPagination } = payload;
      const res = await flowServices.CreatehistoricalContractsAPI(form_data);
      thunkAPI.dispatch(ClearnewhistoricalData());
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHistoricalDatabyId = createAsyncThunk(
  "flow/getHistoricalDatabyId",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getHistoricalListAPI(data);
      
      
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);