import React from "react";
import { Button } from "antd";
import "./button.less";
const CustomButton = (props) => {
  return (
    <Button className="custom-button" {...props}>
      {props.children}
    </Button>
  );
};

export default CustomButton;
