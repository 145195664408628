import { createSlice } from "@reduxjs/toolkit";
import {
  getCategory,
  createCategory,
  updateCategory,
  getCategoryfromadmin,
  importCategoryfromadmin,
} from "./template.Action";

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  categoryTableData: [],
  isVisible: false,
  editRecord: {},
  type: "",
  totalCount: null,
  categoryPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  iscategoryfromadminLoading: false,
  categoryTableDatafromadmin: [],
  iscategoryimportLoading: false,
};
const TemplateserviceSlice = createSlice({
  name: "tempservice",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.categoryPagination = {
        ...state.categoryPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.categoryPagination = {
        ...state.categoryPagination,
        search: action.payload.search || "",
      };
    },
    templateServicedatareset: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, { payload }) => {
        state.categoryTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.total;
     
        state.categoryPagination = {
          ...state.categoryPagination,
          total: payload.total,
        };
      })
      .addCase(getCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createCategory.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createCategory.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(createCategory.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updateCategory.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(updateCategory.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(getCategoryfromadmin.pending, (state) => {
        state.iscategoryfromadminLoading = true;
      })
      .addCase(getCategoryfromadmin.fulfilled, (state, { payload }) => {
        state.categoryTableDatafromadmin = payload.data;
        state.iscategoryfromadminLoading = false;
        // state.isVisible = false;
        // state.totalCount = payload.totalCount;
        // state.categoryPagination = {
        //   ...state.categoryPagination,
        //   total: payload.totalCount,
        // };
      })
      .addCase(getCategoryfromadmin.rejected, (state, { payload }) => {
        state.iscategoryfromadminLoading = false;
      })
      .addCase(importCategoryfromadmin.pending, (state) => {
        state.iscategoryimportLoading = true;
      })
      .addCase(importCategoryfromadmin.fulfilled, (state, { payload }) => {
        // state.categoryTableDatafromadmin = payload.data;
        state.iscategoryimportLoading = false;
        state.isVisible = false;
        // state.totalCount = payload.totalCount;
        // state.categoryPagination = {
        //   ...state.categoryPagination,
        //   total: payload.totalCount,
        // };
      })
      .addCase(importCategoryfromadmin.rejected, (state, { payload }) => {
        state.iscategoryimportLoading = false;
      });
  },
});
export const {
  setModalVisible,
  onPageChangesFilter,
  onSearchList,
  templateServicedatareset,
} = TemplateserviceSlice.actions;
export default TemplateserviceSlice.reducer;
