import { createAsyncThunk } from "@reduxjs/toolkit";
import companyotherService from "../../Services/companyotherService";
import { responseMessageHandler } from "../../globals/components/notification";
import { getChatRoomApi } from "../users/userActions";

export const getstoragetype = createAsyncThunk(
  "other/getstoragetype",
  async (data, thunkAPI) => {
    try {
      let res = await companyotherService.getstoragetypeAPI(data);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setstoragetype = createAsyncThunk(
  "other/setstoragetype",
  async (data, thunkAPI) => {
    try {
      let res = await companyotherService.setstoragetypeAPI({
        ...data,
      });
      thunkAPI.dispatch(getstoragetype(data));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletestorageType = createAsyncThunk(
  "other/deletestorageType",
  async (data, thunkAPI) => {
    try {
      let res = await companyotherService.deletestorageTypeAPI();
      thunkAPI.dispatch(getstoragetype());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLicencencestoragedata = createAsyncThunk(
  "other/getLicencencestoragedata",
  async (data, thunkAPI) => {
    try {
      const res = await companyotherService.getLicencencestoragedataApi();
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setadobeStatus = createAsyncThunk(
  "other/setadobeStatus",
  async (data, thunkAPI) => {
    try {
      let res = await companyotherService.setadobeStatusAPI({
        ...data,
      });
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

