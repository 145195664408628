import { createSlice, nanoid } from "@reduxjs/toolkit";
import {
  getWorkflowdetails,
  createworkflow,
  updateworkflow,
  Nextstep,
  getApprovadetails,
  getcomments,
  getworkdetails,
  createApproval,
  getContentByTemplate,
  Approveworkflow,
  Assignworkflowtocompany,
  getAssignableCompanydetails,
} from "./Workflow.action";
const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  workflowTableData: [],
  workflowdetails: {},
  isVisible: false,
  step3isVisible: false,
  editRecord: {},
  step3editRecord: {},
  step3TableData: [
    
  ],
  current: 0,
  type: "",
  workflowmDetails: {},
  totalCount: null,
  workflowPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
    assinged: true,
  },
  isstep3Loading: true,
  step3totalCount: null,
  approvalPagination: {
    //total: 0,
    // pageSize: 20,
    // defaultCurrent: 1,
    // current: 1,
    // sortBy: -1,
    // showSizeChanger: true,
    //search: "",
    // orderBy: "",
  },
  commentData: [],
  commentPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  Templatedata: [],
  Template_ids: [],
  steploading: false,
  contentData: [],
  ischecklistVisible: false,
  iscompanyassign: false,
  iscompanyassignrecord: {},
  iscompanyassignSuccess: false,
  iscompanyassigning: false,
  isworkflowsuccessVisible: false,
  companyassignabledata: [],
  alreadyassignedcompanies: [],
  workflowMgmtStatus: {
    0: {
      name: "Draft",
      color: "#e17b27",
      id: 1,
    },
    1: {
      name: "In Progress",
      color: "#01aef0",
      id: 4,
    },
    2: {
      name: "In Progress",
      color: "#01aef0",
      id: 4,
    },
    3: {
      name: "Completed",
      color: "#8ec63f",
      id: 4,
    },

    4: {
      name: "Completed",
      color: "#8ec63f",
      id: 5,
    },
    Approved: {
      name: "Completed",
      color: "#8ec63f",
      id: 5,
    },
  },
  isNextloading: false,
  isstep3CreateLoading: false,
  obligationassignModalvisible: false,
  Obligationlistdata: [],
  obligationassignModaltype: "",
  obligationassignModalrecord: {},
  is_reviewCountEdit: false,
  reviewerCount: 1,
  workflowmodalType: "",
  workflowpreviousRecord: {},
  isWorkflowDatainlimit: {},
  ContentDataisLoading: false,
  isAssignableCompanydetailsloading: false,
};
const workflowSlice = createSlice({
  name: "workflow",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    step3ModalVisible: (state, { payload }) => {
      state.step3isVisible = payload.visible;
      state.step3editRecord = payload.record || {};
      if (!payload.visible) {
        state.step3editRecord = {};
      }
    },
    setPreviousstate: (state, { payload }) => {
      state.current =payload?state.current - payload: state.current - 1;
    },
    setNextstate: (state, { payload }) => {
      state.current = payload;
    },
    setDrawerVisible: (state, { payload }) => {
      state.isDrawerVisible = payload.visible;
      state.workflowdetails = {};
      state.current = payload?.current || 0;
      state.editRecord = payload.record || {};
      state.workflowpreviousRecord = payload.record || {};
      state.workflowmodalType = payload?.type || "";
      state.reviewerCount = payload?.record?.reviewerCount || 2;
      state.approvalPagination = { workflow_id: payload?.record?._id };
      if (!payload.visible) {
        state.editRecord = {};
        state.current = 0;
        state.approvalPagination = {};
        state.Obligationlistdata = [];
        state.workflowpreviousRecord = {};
      }
    },
    setChecklistModal: (state, { payload }) => {
      state.ischecklistVisible = payload.visible;
    },

    onPageChangesFilter: (state, action) => {
      state.workflowPagination = {
        ...state.workflowPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.workflowPagination = {
        ...state.workflowPagination,
        search: action.payload.search || "",
      };
    },
    workflowdatareset: (state, action) => {
      return initialState;
    },
    setcompanyassignModalVisible: (state, { payload }) => {
      state.iscompanyassign = payload.visible;
      state.iscompanyassignrecord = payload.record;
      if (!payload.visible) {
        state.iscompanyassign = false;
        state.iscompanyassignrecord = {};
      }
    },
    setworkflowsuccessModal: (state, { payload }) => {
      state.isworkflowsuccessVisible = payload;
    },
    setobligationassignModal: (state, { payload }) => {
      state.obligationassignModalvisible = payload.visible;
      state.obligationassignModaltype = payload.type;
      state.obligationassignModalrecord = payload?.record || {};
    },
    setObligationlistdata: (state, { payload }) => {
      state.Obligationlistdata.push({ ...payload, _id: nanoid() });

      if (!payload.visible) {
      }
    },
    editObligationlistdata: (state, { payload }) => {
     

      const newdata = state.Obligationlistdata.map((item, index) => {
        if (item._id === payload.id) {
          return { ...payload, _id: payload.id };
        } else {
          return item;
        }
      });
     
      state.Obligationlistdata = newdata;
    },
    deleteObligationlistdataitem: (state, { payload }) => {
    
      const newdata = state.Obligationlistdata.filter(
        (item, index) => item._id !== payload
      );

      state.Obligationlistdata = newdata;
    },
    setObligationdatareset: (state, { payload }) => {
      state.Obligationlistdata = [];
    },
    setobligationlistdata: (state, { payload }) => {
      state.Obligationlistdata = payload || [];
    },
    setValue: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowdetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkflowdetails.fulfilled, (state, { payload }) => {
        state.workflowTableData = payload.data;
        state.isWorkflowDatainlimit = payload?.licenseQuota?.workflow;
        state.isLoading = false;
        state.totalCount = payload.total;
        state.workflowPagination = {
          ...state.workflowPagination,
          total: payload.total,
        };
      })
      .addCase(getWorkflowdetails.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createworkflow.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createworkflow.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.drawerVisible = false;
        state.isVisible = false;
        // state.contentTableData = [payload.data]
      })
      .addCase(createworkflow.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updateworkflow.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updateworkflow.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.drawerVisible = false;
        state.isVisible = false;
      })
      .addCase(updateworkflow.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })

      .addCase(Nextstep.pending, (state) => {
        state.isNextloading = true;
      })
      .addCase(Nextstep.fulfilled, (state, { payload }) => {
        if (!localStorage.getItem("companyId") && state.current === 1) {
          state.current = 2;
          state.isNextloading = false;

        } else {
          state.current = state.current < 2 ? state.current + 1 : 2;
          state.isNextloading = false;
        }
      })
      .addCase(Nextstep.rejected, (state, { payload }) => {
        state.isNextloading = false;
      })
      .addCase(getworkdetails.pending, (state) => {
        state.steploading = true;
        //conditions
      })
      .addCase(getworkdetails.fulfilled, (state, { payload }) => {
        state.workflowdetails = payload.data;
        state.Templatedata = payload?.data?.templete_ids[0]?._id;
        state.steploading = false;
        state.Obligationlistdata = payload?.data?.obligations || [];
        if (state.current > 1) {
          state.workflowpreviousRecord = payload?.data;
        }
      })
      .addCase(getworkdetails.rejected, (state, { payload }) => {
        state.steploading = false;
      })
      .addCase(getApprovadetails.pending, (state) => {
        state.isstep3Loading = true;
      })
      .addCase(getApprovadetails.fulfilled, (state, { payload }) => {
        state.step3TableData = payload.data;
        state.isstep3Loading = false;
        state.step3totalCount = payload.total;
        state.approvalPagination = {
          ...state.approvalPagination,
          // total: payload.total,
        };
      })
      .addCase(getApprovadetails.rejected, (state, { payload }) => {
        state.isstep3Loading = false;
      })
      .addCase(getcomments.pending, (state) => {
        //conditions
      })
      .addCase(getcomments.fulfilled, (state, { payload }) => {
        state.commentData = payload.data;
      })
      .addCase(getcomments.rejected, (state, { payload }) => {
        //conditions
      })
      .addCase(createApproval.pending, (state) => {
        state.isstep3CreateLoading = true;
      })
      .addCase(createApproval.fulfilled, (state, { payload }) => {
        state.step3isVisible = false;
        state.isstep3CreateLoading = false;
      })
      .addCase(createApproval.rejected, (state, { payload }) => {
        state.isstep3CreateLoading = false;
      })
      .addCase(getContentByTemplate.pending, (state) => {
        state.ContentDataisLoading = true;
        //conditions
      })
      .addCase(getContentByTemplate.fulfilled, (state, { payload }) => {
        state.ContentDataisLoading = false;

        state.contentData = payload.data;
      })
      .addCase(getContentByTemplate.rejected, (state, { payload }) => {
        state.ContentDataisLoading = false;

        //conditions
      })
      .addCase(Approveworkflow.pending, (state) => {
        state.isapprovalpending = true;
      })
      .addCase(Approveworkflow.fulfilled, (state, { payload }) => {
        state.isapprovalpending = false;
      })
      .addCase(Approveworkflow.rejected, (state, { payload }) => {
        state.isapprovalpending = false;
      })
      .addCase(Assignworkflowtocompany.pending, (state) => {
        state.iscompanyassigning = true;
      })
      .addCase(Assignworkflowtocompany.fulfilled, (state, { payload }) => {
        state.iscompanyassigning = false;
        state.iscompanyassign = false;
      })
      .addCase(Assignworkflowtocompany.rejected, (state, { payload }) => {
        state.iscompanyassigning = false;
      })
      .addCase(getAssignableCompanydetails.pending, (state) => {
        state.isAssignableCompanydetailsloading = true;
      })
      .addCase(getAssignableCompanydetails.fulfilled, (state, { payload }) => {
        state.companyassignabledata = payload.data.assignableCompanies;
        state.alreadyassignedcompanies = payload.data.assignedCompanies;
        state.isAssignableCompanydetailsloading = false;
      })
      .addCase(getAssignableCompanydetails.rejected, (state, { payload }) => {
        state.isAssignableCompanydetailsloading = false;
      });
  },
});
export const {
  step3ModalVisible,
  setDrawerVisible,
  setModalVisible,
  onPageChangesFilter,
  onSearchList,
  setPreviousstate,
  setNextstate,
  workflowdatareset,
  setChecklistModal,
  setcompanyassignModalVisible,
  setworkflowsuccessModal,
  setobligationassignModal,
  setObligationlistdata,
  deleteObligationlistdataitem,
  editObligationlistdata,
  setObligationdatareset,
  setobligationlistdata,
  setValue,
} = workflowSlice.actions;

export default workflowSlice.reducer;
