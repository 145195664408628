import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/company";
class FreeTrialService {
  companySignupAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/signup`, data, headers);
 
}
// eslint-disable-next-line
export default new FreeTrialService();