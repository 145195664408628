import { createSlice } from "@reduxjs/toolkit";
import {
  profileupdate,
  getotpforupdate,
  Vendorprofileupdate,
  setuserSignature,
  setVendorSignature,
  profilepasswordupdate,
  Vendorpasswordprofileupdate,
  companyprofileupdate,
  deleteUserSignature,
  deletevendorSignature
} from "./Profile.Action";
const initialState = {
  isLoading: false,
  issuccess: false,
  isformdisabled: true,
  ispasswordVisible: false,
  isdetailsSaving: false,
  otpformvisible: false,
  userrecord: {},
  propValue: {},
  isSignatureModalvisible: false,
  isSignatureuploading: false,
  editingtype: "",
  iscompanyformdisabled: true,
  iscompanydetailsupdating: false,
  isSignaturedeleting:false
};
const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setisformdisabled: (state, { payload }) => {
      state.isformdisabled = payload;
    },
    setiscompanyformdisabled: (state, { payload }) => {
      state.iscompanyformdisabled = payload;
    },
    Otpmodalvisible: (state, { payload }) => {
      if (payload.type === "resetpassword") {
        state.ispasswordVisible = false;
        state.editingtype = "resetpassword";
      }
      state.otpformvisible = payload?.visible;
      state.userrecord = payload?.record;
    },
    setPasswordmodal: (state, { payload }) => {
      state.ispasswordVisible = payload.visible;
    },
    setSignatureModalvisible: (state, { payload }) => {
      state.isSignatureModalvisible = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(profileupdate.pending, (state) => {
        state.isdetailsSaving = true;
      })
      .addCase(profileupdate.fulfilled, (state, { payload }) => {
        state.isdetailsSaving = false;
        state.issuccess = true;
        state.isformdisabled = true;
        state.otpformvisible = false;
      })
      .addCase(profileupdate.rejected, (state, { payload }) => {
        state.isdetailsSaving = false;
      })
      .addCase(profilepasswordupdate.pending, (state) => {
        state.isdetailsSaving = true;
      })
      .addCase(profilepasswordupdate.fulfilled, (state, { payload }) => {
        state.isdetailsSaving = false;
        state.issuccess = true;
        state.isformdisabled = true;
        state.otpformvisible = false;
      })
      .addCase(profilepasswordupdate.rejected, (state, { payload }) => {
        state.isdetailsSaving = false;
      })
      .addCase(getotpforupdate.pending, (state) => {
        state.isotpsending = true;
      })
      .addCase(getotpforupdate.fulfilled, (state, { payload }) => {
        state.isotpsending = false;
      })
      .addCase(getotpforupdate.rejected, (state, { payload }) => {
        state.isotpsending = false;
      })
      .addCase(Vendorprofileupdate.pending, (state) => {
        state.isdetailsSaving = true;
      })
      .addCase(Vendorprofileupdate.fulfilled, (state, { payload }) => {
        state.isdetailsSaving = false;
        state.issuccess = true;
        state.isformdisabled = true;
        state.otpformvisible = false;
        state.ispasswordVisible = false;
      })
      .addCase(Vendorprofileupdate.rejected, (state, { payload }) => {
        state.isdetailsSaving = false;
      })
      .addCase(Vendorpasswordprofileupdate.pending, (state) => {
        state.isdetailsSaving = true;
      })
      .addCase(Vendorpasswordprofileupdate.fulfilled, (state, { payload }) => {
        state.isdetailsSaving = false;
        state.issuccess = true;
        state.isformdisabled = true;
        state.otpformvisible = false;
        state.ispasswordVisible = false;
      })
      .addCase(Vendorpasswordprofileupdate.rejected, (state, { payload }) => {
        state.isdetailsSaving = false;
      })
      .addCase(setuserSignature.pending, (state) => {
        state.isSignatureuploading = true;
      })
      .addCase(setuserSignature.fulfilled, (state, { payload }) => {
        state.isSignatureuploading = false;
        state.isSignatureModalvisible = false;
      })
      .addCase(setuserSignature.rejected, (state, { payload }) => {
        state.isSignatureuploading = false;
      })
      .addCase(setVendorSignature.pending, (state) => {
        state.isSignatureuploading = true;
      })
      .addCase(setVendorSignature.fulfilled, (state, { payload }) => {
        state.isSignatureuploading = false;
        state.isSignatureModalvisible = false;
      })
      .addCase(setVendorSignature.rejected, (state, { payload }) => {
        state.isSignatureuploading = false;
      })
      .addCase(companyprofileupdate.pending, (state) => {
        state.iscompanydetailsupdating = true;
      })
      .addCase(companyprofileupdate.fulfilled, (state, { payload }) => {
        state.iscompanydetailsupdating = false;
        // state.iscpmpanyupdatesuccessful = true;
        state.iscompanyformdisabled = true;
      })
      .addCase(companyprofileupdate.rejected, (state, { payload }) => {
        state.iscompanydetailsupdating = false;
      })
      .addCase(deleteUserSignature.pending, (state) => {
        state.isSignaturedeleting = true;
      })
      .addCase(deleteUserSignature.fulfilled, (state, { payload }) => {
        state.isSignaturedeleting = false;
        
      })
      .addCase(deleteUserSignature.rejected, (state, { payload }) => {
        state.isSignaturedeleting = false;
      })
      .addCase(deletevendorSignature.pending, (state) => {
        state.isSignaturedeleting = true;
      })
      .addCase(deletevendorSignature.fulfilled, (state, { payload }) => {
        state.isSignaturedeleting = false;
        
      })
      .addCase(deletevendorSignature.rejected, (state, { payload }) => {
        state.isSignaturedeleting = false;
      })
  },
});

export const {
  setisformdisabled,
  Otpmodalvisible,
  setPasswordmodal,
  setSignatureModalvisible,
  setiscompanyformdisabled,
} = profileSlice.actions;

export default profileSlice.reducer;
