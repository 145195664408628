import { Button, Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setselectedItems } from '../../EmailGuestlogin.slice'
import { LeftOutlined } from '@ant-design/icons';
import Activities from '../../../Taskmanagement/Drawer/Activities/Activities';
import CustomCard from '../../../../globals/components/Card';
import { getTaskactivitylist } from '../../../TaskmanagementVendor/TaskmanagementVendor.Action';
const ActivitiesDetail = (props) => {
    const dispatch=useDispatch()
    const {
     
      taskmanagementVendor,
    
      intl,
    
    } = props;
    const {
     
      taskTicketDetails = {},
      taskactivityData = [],
     
      taskMgmtStatus,
     
    } = taskmanagementVendor;
    useEffect(() => {
     
      dispatch(
        getTaskactivitylist({
          taskId: taskTicketDetails?._id,
  
          companyId: taskTicketDetails?.companyId,
        })
      );
    
      // eslint-disable-next-line
    }, [dispatch]);
  return (
    <Row>

    <Col className='extra-sec-3' span={24}>
    <Button onClick={()=>{
    dispatch(setselectedItems({item:"parent"}))
    }} block type='text' className='extra-sec-item4-content'  icon={<LeftOutlined />} >
            Activities
        </Button>
    </Col>
    <Col span={24}>

    <CustomCard
                headStyle={{
                  background: "#F7F7FA",
                  height: "40px",
                  alignItems: "center",
                  display: "flex",
                 
                }}
                scrollable={{ y: true }}
                bodyStyle={{ overflowY: "auto", height: "550px",  }}
                style={{width:"360px",marginTop:"10px"}}
                // title={"Activities"}
                bordered
              >
                <div className="activities-task">
                  <Activities
                    dataSource={taskactivityData}
                    taskMgmtStatus={taskMgmtStatus}
                    intl={intl}
                    {...props}
                    taskTicketDetails={taskTicketDetails}
                  />
                </div>
              </CustomCard>
    </Col>
    
    
            </Row>
  )
}

export default ActivitiesDetail