import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
 
  isVisible: false,
  

  editRecord: {},
  type: "",
 
  totalCount: null,
  contentPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  CurrentselectedItems:"parent",

 
};
const EmailGuestloginSlice = createSlice({
  name: "EmailGuestlogin",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.taskDetailsdata = payload.taskDetailsdata;
      state.type = payload.type || "";
      state.editRecord = payload.record || {};
      state.editRecordContent = payload.editRecordContent
        ? Array.isArray(payload.editRecordContent) &&
          payload.editRecordContent.length
          ? payload.editRecordContent[0].content
          : payload.editRecordContent.content
        : payload.record
        ? payload.record.content
        : "";
      if (!payload.visible) {
        state.editRecord = {};
        state.taskDetailsdata = {};
        state.contentPreviewData = "";
      }
    },
    setselectedItems: (state, { payload }) => {
        state.CurrentselectedItems = payload.item;
     
      },
   
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(getContent.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(getContent.fulfilled, (state, { payload }) => {
    //     state.contentTableData = payload.data;
    //     state.isLoading = false;
    //     state.totalCount = payload.totalCount;
    //     state.contentPagination = {
    //       ...state.contentPagination,
    //       total: payload.total,
    //     };
    //   })
    //   .addCase(getContent.rejected, (state, { payload }) => {
    //     state.isLoading = false;
    //   })
     
      
  },
});
export const {
    setselectedItems
} = EmailGuestloginSlice.actions;

export default EmailGuestloginSlice.reducer;
