import { Avatar, Button, Col, Row, Timeline } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setselectedItems } from '../../EmailGuestlogin.slice'
import { LeftOutlined,EyeOutlined } from '@ant-design/icons';
import { dateFormatter } from '../../../../utils/helper';
import "./approvaldetail.less"
const ApproverDetails = (props) => {
  const {
    // intl: { formatMessage },
   
    taskmanagementVendor:{taskTicketDetails = {}},
  } = props;
  const dispatch=useDispatch()
  return (
    <Row>

    <Col className='extra-sec-3' span={24}>
    <Button onClick={()=>{
    dispatch(setselectedItems({item:"parent"}))
    }} block type='text' className='extra-sec-item4-content'  icon={<LeftOutlined />} >
            Approvers
        </Button>
    </Col>
    <Col span={24}>
    <div style={{ marginTop: "20px" }}>
                  {taskTicketDetails.approvals.map((parRecord, parIndex) => (
                    <div
                      key={parIndex}
                      className="parent_grouping"
                      style={{ marginTop: "15px" }}
                    >
                      <div className="extra_chile_group_text_font footer_less">
                        <span>{parRecord.labelName}</span>
                      </div>
                      <div className="extra_no_child">
                        <Timeline mode={"left"}>
                          {parRecord.vendorApproval.map((ert, ertIndex) => (
                            <Timeline.Item
                              key={ertIndex}
                              color={
                                ert.vendorApprovalStatus === 2
                                  ? "green"
                                  : ert.vendorApprovalStatus === 3
                                  ? "red"
                                  : "orange"
                              }
                              // label={dateFormatter(ert.createdAt)}
                            >
                              <div className="avatarlvldetail-icon-name">
                                <Avatar
                                  onClick={() => {
                                    // dispatch(
                                    //   setprofilemodal({
                                    //     visible: true,
                                    //     record: ert,
                                    //   })
                                    // );
                                  }}
                                  shape="circle"
                                  size={36}
                                  style={{ backgroundColor: "#fdgdgb" }}
                                  className="profile-icon-name2"
                                >
                                  <span className="shortprofilelvldetail-username">
                                    {ert?.firstName.charAt(0)}
                                    {ert?.lastName.charAt(0)}
                                  </span>
                                </Avatar>
                                <span className="user-lvl-detail-name">
                                  {" "}
                                  {ert.firstName
                                    ? ert.firstName
                                    : ert.email}{" "}
                                  {ert?.lastName || ``}
                                </span>
                              </div>
                              <div
                                style={{
                                  paddingTop: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                <Timeline mode={"left"}>
                                  <Timeline.Item
                                    key={ertIndex}
                                    color={
                                      ert.vendorApprovalStatus === 2
                                        ? "green"
                                        : ert.vendorApprovalStatus === 3
                                        ? "red"
                                        : "orange"
                                    }
                                    // label={dateFormatter(ert.createdAt)}
                                  >
                                    <div>
                                      &nbsp;
                                      <span
                                        style={{
                                          color: "orange",
                                        }}
                                      >
                                        {"Assigned"}
                                      </span>
                                      &nbsp;
                                      <span className="date-lvl-task">
                                        ({dateFormatter(ert.createdAt)})
                                      </span>
                                    </div>
                                  </Timeline.Item>
                                  {ert.approvalDate && (
                                    <Timeline.Item
                                      key={ertIndex}
                                      color={
                                        ert.vendorApprovalStatus === 2
                                          ? "green"
                                          : ert.vendorApprovalStatus === 3
                                          ? "red"
                                          : "orange"
                                      }
                                      // label={dateFormatter(ert.createdAt)}
                                    >
                                      <div>
                                        &nbsp;
                                        <span
                                          style={{
                                            color:
                                              ert.vendorApprovalStatus === 2
                                                ? "green"
                                                : ert.vendorApprovalStatus === 3
                                                ? "red"
                                                : "orange",
                                          }}
                                        >
                                          {ert.vendorApprovalStatus === 2
                                            ? "Approved"
                                            : ert.vendorApprovalStatus === 3
                                            ? "Rejected"
                                            : "Pending"}
                                        </span>
                                        &nbsp;
                                        <span className="date-lvl-task">
                                          ({dateFormatter(ert.approvalDate)})
                                        </span>
                                      </div>
                                    </Timeline.Item>
                                  )}
                                  {(ert.signDate||ert.isAdobeSigned) && (
                                    <Timeline.Item
                                      key={ertIndex}
                                      color={
                                        ert.vendorApprovalStatus === 2
                                          ? "green"
                                          : ert.vendorApprovalStatus === 3
                                          ? "red"
                                          : "orange"
                                      }
                                      // label={dateFormatter(ert.createdAt)}
                                    >
                                      <div>
                                        &nbsp;
                                        <span style={{ fontWeight: 500 }}>
                                          {"Signed"}
                                        </span>
                                        {ert.isAdobeSigned?<span></span>: <span
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => {
                                            // dispatch(
                                            //   setSignatureViewModal({
                                            //     visible: true,
                                            //     record: ert,
                                            //   })
                                            // );
                                          }}
                                        >
                                          <EyeOutlined />
                                        </span>}
                                        &nbsp;
                                        <span className="date-lvl-task">
                                          ({dateFormatter(ert.signDate)})
                                        </span>
                                      </div>
                                    </Timeline.Item>
                                  )}
                                </Timeline>
                              </div>
                            </Timeline.Item>
                          ))}
                        </Timeline>
                      </div>
                    </div>
                  ))}

                  {/* <Timeline.Item label="2015-09-01">
                      Admin 1 Approved
                    </Timeline.Item>
                    <Timeline.Item label="2015-09-01 09:12:11">
                      Himansu verma Approved
                    </Timeline.Item>
                    <Timeline.Item color="red">
                      {" "}
                      Himansu verma Rejected
                    </Timeline.Item>
                    <Timeline.Item label="2015-09-01 09:12:11">
                      Himansu verma Approved
                    </Timeline.Item> */}
                </div>
    </Col>
   
    
            </Row>
  )
}

export default ApproverDetails