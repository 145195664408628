import React from "react";
import CustomButton from "../../../globals/components/button";
import CustomModal from "../../../globals/components/Modal";
import { useDispatch } from "react-redux";
import { setContractViewModal } from "../TaskmanagementVendor.Slice";
import { Spin } from "antd";

// import CustomCard from "../../../globals/components/Card";
import "./Signature.less";

const ContractViewModal = (props) => {
  const dispatch = useDispatch();
  const {
    intl: { formatMessage },
    taskmanagementVendor,
  } = props;
  const { isContractViewVisible, contractViewDetails, isContractLoading } =
    taskmanagementVendor;
  const modelClose = async () => {
    dispatch(setContractViewModal({ visible: false, record: {} }));
  };
  return (
    <CustomModal
      width={1500}
      maskClosable={false}
      title={formatMessage({ id: "contract" })}
      placement="right"
      onCancel={() => {
        modelClose();
      }}
      className={"create-report-view"}
      open={isContractViewVisible}
      footer={
        <div>
          <CustomButton
            // type="primary"
            onClick={() => {
              modelClose();
            }}
          >
            {formatMessage({ id: "cancel" })}
          </CustomButton>
        </div>
      }
    >
      {/* <CustomCard
        bordered
        title={formatMessage({ id: "contract" })}
        headStyle={{ background: "#EBEDEF" }}
        bodyStyle={{
          background: "#F5F5F5",
          height: "500px",
          overFlow: "auto",
          padding: "15px",
        }}
      > */}
      <Spin spinning={isContractLoading}>
        <div className="embed-responsive embed-responsive-21by9">
          <embed src={contractViewDetails.contractUrl} />
        </div>
      </Spin>
      {/* </CustomCard> */}
    </CustomModal>
  );
};

export default ContractViewModal;
