import { createSlice } from "@reduxjs/toolkit";
// import { message } from "antd";

const globalState = {
  isMobile: false,
  reload: false,
  match: {},
  history: {},
};
export const GlobalSlice = createSlice({
  name: "global",
  initialState: globalState,
  reducers: {
    setRoute: (state, action) => {
      state.location = action.payload.location;
      state.match = action.payload.match;
      state.history = action.payload.history;
    },
    handleBreakpointSet: (state, action) => {
     
      state.isMobile = action.payload;
    },
    chaeckRoutePermission: (pathname) => {
      // if (this.service_selection && localStorage.getItem("customer_id")) {
      //   return pathname === "/user/service-selection";
      // }
      return localStorage.getItem("customerToken");
    },
  },
});

export const { setRoute, handleBreakpointSet, responseMessageHandler } =
  GlobalSlice.actions;
// export const selectUser = (state) => state.user.isAuthenticated;
export default GlobalSlice.reducer;
