import React,{useEffect} from "react";
import CustomModal from "../../globals/components/Modal";

import { useDispatch } from "react-redux";
import { Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { SetforgetpasswordModal } from "./user.slice";
import CustomButton from "../../globals/components/button";
import { Forgetuserpassword } from "./userActions";

// import { setFormstate } from "../../redux/slice/loginSlice";

const ForgetPasswordPage = (props) => {
    const {
        user,
        intl: { formatMessage },
      } = props;
      const {  forgetpasswordmodal,forgetloading,isforgetsuccess } = user;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const modelClose = async (form) => {
    form.resetFields();
    dispatch(SetforgetpasswordModal({ visible: false }));
  };
  // // send otp on email ----------------------------->
  const ForgetemailSubmit=async(form)=>{
    var values = await form.validateFields();
    let data={
        email: values?.email?.toLowerCase()?.trim()
    }
    dispatch(Forgetuserpassword(data))
    
  }
  
  useEffect(() => {
    if (isforgetsuccess) {
      form.resetFields();
    }
  }, [isforgetsuccess,form]);
  return (
    <CustomModal
      maskClosable={false}
      title={formatMessage({ id: "forgot_password_" })}
      onCancel={() => {
        modelClose(form);
      }}
      open={forgetpasswordmodal}
      footer={
        <div>
          <CustomButton
            // type="primary"
            onClick={() => {
              modelClose(form);
            }}
          >
            {formatMessage({ id: "cancel" })}
          </CustomButton>
          <CustomButton
            type="primary"
            onClick={() => ForgetemailSubmit(form)}
            key={"submit"}
            loading={forgetloading}
          >
           
              { formatMessage({ id: "submit" })}
          </CustomButton>
        </div>
      }
    >
      <Form
      form={form}
        style={{ width: "100%" }}
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message:formatMessage({ id: "email_rule" }),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined  />}
            placeholder={formatMessage({ id: "plce_email" })}
          />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default ForgetPasswordPage;
