import {
  Button,
  Carousel,
  Checkbox,
  Col,
  Form,
  Input,
  Result,
  Row,
} from "antd";
import React, { useEffect } from "react";

import whiteLogo from "../../../../src/assets/images/white-logo.png";
import clm1 from "../../../../src/assets/images/clm.jpg";
import clm2 from "../../../../src/assets/images/clm2.png";
import clm3 from "../../../../src/assets/images/clm3.jpg";
import clm4 from "../../../../src/assets/images/clm4.jpg";

import "./index.less";
import { useState } from "react";
import { connect } from "react-redux";

import { injectIntl } from "react-intl";

const Index = (props) => {
  const [isPagerender, setisPagerender] = useState(false);
  const [form] = Form.useForm();


  const {
    FreeTrial,
    intl: { formatMessage },
  } = props;
  const { isLoading = false, isFormSubmitted = false } = FreeTrial;
  useEffect(() => {
    setisPagerender(true);

    return () => {
      // dispatch(freetrialDatareset());
    };
  }, []);

  const contentStyle = {
    height: "300px",
    color: "#fff",
    lineHeight: "300px",
    textAlign: "center",
    background: "#efefef",
    width: "100%",
    borderRadius: "15px",
  };
  const onSubmit = async () => {
    // const values = await form.validateFields();
   
    // dispatch(CompanySignup(values));
  };
 
  return (
    <Row>
      <Col className="free-trial-imgContainer" md={12} xs={24}>
        <div className="free_trial_left_logo">
          
           <img  alt="" src={whiteLogo}></img>
          <div className="logo_name">
          VerifiableContract
          </div>
        </div>
        <div className="free_trial_left_infobox">
          <div className="free_trial_left_title">
            Start Your 14-Day Free Trial of VerifiableContract{" "}
          </div>
          <div
            className={
              isPagerender
                ? "free_trial_left_extra_info active"
                : "free_trial_left_extra_info"
            }
          >
            <Carousel autoplay>
              <div>
                <img style={contentStyle} alt="" src={clm1}></img>
              </div>
              <div>
                <img style={contentStyle} alt="" src={clm2}></img>
              </div>
              <div>
                <img style={contentStyle} alt="" src={clm3}></img>
              </div>
              <div>
                <img style={contentStyle} alt="" src={clm4}></img>
              </div>
            </Carousel>
          </div>
        </div>
      </Col>
      <Col md={12} xs={24} className="free-trial-formcontainer">
        {!isFormSubmitted && (
          <div className="free-trial-formbox">
            <div className="free-trial-boxtitle">
            Sign up for demo
            </div>
            {/* <div className="free-trial-boxtitle_info">
              No Commitments needed
            </div> */}
            <div
              className={
                isPagerender ? "free-trial-form active" : "free-trial-form"
              }
            >
              <Form form={form} size="large">
            
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: "email_rule" }),
                    },
                    {
                      whitespace: true,
                    },
                    {
                      type: "email",
                      message: formatMessage({ id: "email_must_rule" }),
                    },
                  ]}
                  name="email"
                >
                  <Input placeholder="Business Email" />
                </Form.Item>
             
               
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>
                  By checking this box, you agree to receive email communications about Ironclad products and services.
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => {
                      onSubmit();
                    }}
                    loading={isLoading}
                    block
                    type="primary"
                  >
                   Request Demo
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
        {isFormSubmitted && (
          <div className="free-trial-success">
            <Result
              status="success"
              title=" Thank you for your interest in our product!"
              subTitle="Check your inbox for a link to get your account activated"
              extra={[
                <Button href="/" key="console">
                  Back Home
                </Button>,
              ]}
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default connect((state) => ({
  user: state.user,
  global: state.global,
  historical_services: state.historical_services,
  EmailGuestlogin: state.EmailGuestlogin,
  taskmanagementVendor: state.taskmanagementVendor,
  vendor: state.vendor,
  FreeTrial: state.FreeTrial,
}))(injectIntl(Index));
