import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/task";
const subUrllocal1 = "/api/v1/workflow";
const taskurllocal = "/api/v1/taskcomment";
const subUrllocal2 = "/api/v1/notification";
const subUrlvendorlocal = "/api/v1/vendor";
const subUrlstafflocal = "/api/v1/user";
const taskaddendum = "/api/v1/addendum";
const taskRenew = "/api/v1/renewal";
class taskService {
  getTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/tasks`, data, headers);
  createTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/createV2`, data, headers);
  deleteTaskApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  createCommentAPI = (data) =>
    RestfulProvider.post(`${taskurllocal}/create`, data, headers);

  getCommentAPI = (data) =>
    RestfulProvider.post(`${taskurllocal}/comments`, data, headers);

  deletCommentAPI = (data) =>
    RestfulProvider.deleteget(`${taskurllocal}/delete`, data, headers);

  getDetailApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/taskdetail`, data, headers);
  createTemplateTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/submittask`, data, headers);
  taskAssigneeApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/assigneVendor`, data, headers);
  taskContentApi = (data) =>
    RestfulProvider.post(
      `${subUrllocal}/taskParametetiseContent`,
      data,
      headers
    );
  editTaskContentTemplateAPI = (data) =>
    RestfulProvider.post(
      `${subUrllocal}/changeTaskContentTemplate`,
      data,
      headers
    );
  getnotificationsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal2}`, data, headers);
  getfileforbulktask = (data) =>
    RestfulProvider.downloadMethod(`${subUrllocal}/download-sample`, data, headers);
  createbulktaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/upload-task`, data, headers);
  getWorkflowdetailsfortaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/workflow/list`, data, headers);
  changeforrequestActionAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/task-action`, data, headers);
  tracktemplatehistoryAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/task-history`, data, headers);
  getTaskactivitylistAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/task-activity`, data, headers);
  getVendordetiailsAPI = (data) =>
    RestfulProvider.post(`${subUrlvendorlocal}/vendors`, data, headers);
  getStaffdetiailsAPI = (data) =>
    RestfulProvider.post(`${subUrlstafflocal}/users`, data, headers);
  setAbortTaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/abort`, data, headers);
  getreOpentaskAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/reopen`, data, headers);
  createTaskaddendumAPI = (data) =>
    RestfulProvider.post(`${taskaddendum}/create`, data, headers);
  TaskaddendumstepAPI = (data) =>
    RestfulProvider.post(`${taskaddendum}/proceed`, data, headers);
  getaddendumsteplistanddetailsAPI = (data) =>
    RestfulProvider.post(`${taskaddendum}`, data, headers);
  gettaskaddendumstepdetailsAPI = (data) =>
    RestfulProvider.post(`${taskaddendum}/details`, data, headers);
  TerminatecontractAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/terminate`, data, headers);

  createTaskRenewAPI = (data) =>
    RestfulProvider.post(`${taskRenew}/create`, data, headers);
  getRenewsteplistanddetailsAPI = (data) =>
    RestfulProvider.post(`${taskRenew}/details`, data, headers);
  TaskRenewstepAPI = (data) =>
    RestfulProvider.post(`${taskRenew}/proceed`, data, headers);
  getReviewerListAPI = (data) =>
    RestfulProvider.post(`/api/v1/task/get-reviewer-list`, data, headers);
  // getReviewerListAPI = (data) =>
  //   RestfulProvider.post(`/api/v1/task/staff/reviewer-list`, data, headers);
  ReviewerSubmitAPI = (data) =>
    RestfulProvider.post(`/api/v1/task/add-reviewer`, data, headers);
  getChecklistData = (data) =>
    RestfulProvider.post(`/api/v1/task/getChecklistData`, data, headers);
  getVersionListApi = (data) =>
    RestfulProvider.post(`/api/v1/task/contentVersionlist`, data, headers);
  saveVersionListApi = (data) =>
    RestfulProvider.post(`/api/v1/task/updateVersionContent`, data, headers);
  getCommentRequestApi = (data) =>
    RestfulProvider.post(`/api/v1/task/getUpdateRequestData`, data, headers);

  getnotificationsListAPI = (data) =>
    RestfulProvider.post(`/api/v1/user/get-event-alert`, data, headers);
  getReviewerEmailIdsApi = (data) =>
    RestfulProvider.post(`/api/v1/task/get-reviewer-emailIds`, data, headers);
  sendReviewerInvitaionApi = (data) =>
    RestfulProvider.post(`/api/v1/task/review-invite`, data, headers);

  completeReviewerInvitaionApi = (data) =>
    RestfulProvider.formDatapost(
      `/api/v1/task/complete-review/upload-doc`,
      data,
      headers
    );
  getReviewDetailsApi = (data) =>
    RestfulProvider.post(`/api/v1/task/get-reviewer_status`, data, headers);
  sendMailToPartiesApi = (data) =>
    RestfulProvider.post(`/api/v1/task/mail-to-parties`, data, headers);
  completeReviewPartiesApi = (data) =>
    RestfulProvider.formDatapost(
      `/api/v1/task/parties-doc-upload`,
      data,
      headers
    );
  checkEmailregisteredorNotApi = (data) =>
    RestfulProvider.post(`/api/v1/vendor/validateVendorEmail`, data, headers);
  SubmiteditTemplateValuesAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/parameterizeAndupdate`, data, headers);
}

// eslint-disable-next-line
export default new taskService();
