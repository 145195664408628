import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/category";
class categoryService {
  getCategoryAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/categories`, data, headers);
  createCategoryAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);

  deletecategoryApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  importCategoryfromadminAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/import`, data, headers);
  deleteadmincategoryApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/remove`, data, headers);
}
// eslint-disable-next-line
export default new categoryService();
