import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/dashboard";
class dashboardServices {
  dashboardetailsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}`, data, headers);
  getvendordashboarddetailsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/vendor`, data, headers);
  getAdmindashboarddetailsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/admin`, data, headers);
  getTaskStatusListDataAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/contractByStatusCount`, data, headers);
  getStatusByListDataAPI = (data) =>
    RestfulProvider.post(
      `${subUrllocal}/contractListByStatusCount`,
      data,
      headers
    );
  getContractOtherStatusAPI = (data) =>
    RestfulProvider.post(
      `${subUrllocal}/getContractOtherStatusPieChart`,
      data,
      headers
    );
  getDeeplinkingDataApi = (data) =>
    RestfulProvider.post(
      `${subUrllocal}/deeplinking/getContractOtherStatusPieChartList`,
      data,
      headers
    );
  contractTypeVsContractCountApi = (data) =>
    RestfulProvider.post(
      `${subUrllocal}/getContractTypeBaseCount`,
      data,
      headers
    );

  dashboardModuleWisedCountApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/moduleWiseCount`, data, headers);
  contractTypeBasedCountApi = (data) =>
    RestfulProvider.post(
      `${subUrllocal}/getContractTypeBaseCount`,
      data,
      headers
    );
    contractByStatusCountApi = (data) =>
    RestfulProvider.post(
      `${subUrllocal}/contractByStatusCount`,
      data,
      headers
    );  
    getHistoricalContractStatusBaseCountApi= (data) =>
    RestfulProvider.post(
      `${subUrllocal}/getHistoricalContractStatusBaseCount`,
      data,
      headers
    );  
}
// eslint-disable-next-line
export default new dashboardServices();
