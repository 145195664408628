import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/user";
const subUrllocal1 = "/api/v1/vendor";
const subUrllocal2 = "/api/v1/company";
class ProfileServices {
  profileupdateAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/updateUserProfile`, data, headers);

  ResetpasswordApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/updateUserProfile`, data, headers);
  getprofileDetailsAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/users`, data, headers);
  getotpforupdateAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/otp`, data, headers);
  VendorprofileupdateAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/updatePassword`, data, headers);
  setuserSignatureAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/addSignature`, data, headers);
  setVendorSignatureAPI = (data) =>
    RestfulProvider.post(`${subUrllocal1}/addSignature`, data, headers);
  companyprofileupdateAPI = (data) =>
    RestfulProvider.post(`${subUrllocal2}/create`, data, headers);
}
// eslint-disable-next-line
export default new ProfileServices();
