import { createAsyncThunk } from "@reduxjs/toolkit";
import taskService from "../../Services/myTaskService";
import { responseMessageHandler } from "../../globals/components/notification";
import { setcontractsuccessModal } from "../myContracts/myContract.Slice";
import { getDetails as getTaskManagementDetails } from "../Taskmanagement/Taskmanagement.Action";
import { SetAdobesignModalvisible, setDrawerVisible } from "./MyTask.Slice";
// import { setDrawerVisible as setvendorDrawerVisible } from "../TaskmanagementVendor/TaskmanagementVendor.Slice";
import { message } from "antd";
export const getTaskdetails = createAsyncThunk(
  "task/getTaskdetails",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getTaskAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
        // orderBy :"updatedAt"
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTask = createAsyncThunk(
  "task/createTask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.createTaskAPI(data);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "task/deleteTask",
  async ({ data, taskPagination }, thunkAPI) => {
    try {
      const res = await taskService.deleteTaskApi(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getTaskdetails(taskPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTaskcomments = createAsyncThunk(
  "task/getTaskcomments",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getCommentAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTaskcomment = createAsyncThunk(
  "task/createTaskcomment",
  async ({ data, TaskcommentPagination }, thunkAPI) => {
    try {
      const res = await taskService.createCommentAPI(data);
      thunkAPI.dispatch(getTaskcomments(TaskcommentPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTaskComment = createAsyncThunk(
  "task/deleteTaskComment",
  async ({ data, TaskcommentPagination }, thunkAPI) => {
    try {
      const res = await taskService.deletCommentAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getTaskcomments(TaskcommentPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDetails = createAsyncThunk(
  "staff/getDetails",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getDetailApi(data);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createTemplateTask = createAsyncThunk(
  "task/createTemplateTask",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.createTemplateTaskAPI(data);
      // thunkAPI.dispatch(getTaskdetails(taskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const taskAssignee = createAsyncThunk(
  "template/taskAssignee",
  async (recData, thunkAPI) => {
    try {
      const res = await taskService.taskAssigneeApi(recData);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const taskConnect = createAsyncThunk(
  "template/taskConnect",
  async (recData, thunkAPI) => {
    try {
      const res = await taskService.taskContentApi(recData);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Verifygdrive = createAsyncThunk(
  "task/Verifygdrive",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.VerifygdriveApi(data);

      return res;
    } catch (error) {
      // const message =
      //   (error.response && error.response.data && error.response.data.error) ||
      //   error.message ||
      //   error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const taskActions = createAsyncThunk(
  "task/taskActions",
  async ({ rscData, taskReq, newtaskPagination }, thunkAPI) => {
    try {
      const res = await taskService.taskActionApi(rscData);
      thunkAPI.dispatch(getDetails(taskReq));
      thunkAPI.dispatch(getTaskdetails(newtaskPagination));
      thunkAPI.dispatch(Verifygdrive());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      // const message =
      //   (error.response && error.response.data && error.response.data.error) ||
      //   error.message ||
      //   error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const checklistSubmit = createAsyncThunk(
  "task/checklistSubmit",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.checklistApi(data);
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const signatureApicall = createAsyncThunk(
  "task/signatureApicall",
  async ({ data, taskReq, islastsignature, newtaskPagination }, thunkAPI) => {
    try {
      const res = await taskService.signApi(data);
      await thunkAPI.dispatch(getDetails(taskReq));
      thunkAPI.dispatch(getTaskdetails(newtaskPagination));
      islastsignature && thunkAPI.dispatch(setcontractsuccessModal(true));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const reportDownload = createAsyncThunk(
  "task/reportDownload",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.signApiDownload(data);
      var file = new Blob([res], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return fileURL;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const uploadrequestforChange = createAsyncThunk(
  "task/uploadrequestforChange",
  async ({ data, newtaskPagination, detailObj }, thunkAPI) => {
    try {
      let res = await taskService.uploadrequestforChangAPI(data);
      thunkAPI.dispatch(getDetails(detailObj));
      thunkAPI.dispatch(getTaskdetails(newtaskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTaskactivitylist = createAsyncThunk(
  "task/getTaskactivitylist",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getTaskactivitylistAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getReviewTaskcomments = createAsyncThunk(
  "task/getReviewTaskcomments",
  async (data, thunkAPI) => {
    try {
      let res = await taskService.getReviewCommentAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createReviewTaskcomment = createAsyncThunk(
  "task/createReviewTaskcomment",
  async ({ data, rstd }, thunkAPI) => {
    try {
      const res = await taskService.createReviewCommentAPI(data);
      thunkAPI.dispatch(getReviewTaskcomments(rstd));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteReviewTaskComment = createAsyncThunk(
  "task/deleteReviewTaskComment",
  async ({ data, reviewTaskcommentPagination }, thunkAPI) => {
    try {
      const res = await taskService.deletReviewCommentAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getReviewTaskcomments(reviewTaskcommentPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addContenReviewTaskcomment = createAsyncThunk(
  "task/addContenReviewTaskcomment",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.reviewAddComment(data);
      thunkAPI.dispatch(reviewCommentslist({ taskId: data.taskId ,vendorId:data?.vendorId,companyId: data?.companyId,}));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reviewCommentslist = createAsyncThunk(
  "task/reviewCommentslist",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.reviewerCommentListApi(data);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const reviewerCommentAction = createAsyncThunk(
  "task/reviewerCommentAction",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.reviewerCommentActionAPI(data);
      thunkAPI.dispatch(reviewCommentslist({ taskId: data.taskId }));
      thunkAPI.dispatch(getTaskManagementDetails({ taskId: data.taskId }));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const trackTaskhistory = createAsyncThunk(
  "task/trackTaskhistory",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.trackTaskhistoryAPI(data);
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteCommentHistory = createAsyncThunk(
  "task/deleteCommentHistory",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.deleteCommentHistoryAPI(data);
      thunkAPI.dispatch(reviewCommentslist({ taskId: data.taskId,vendorId:data?.vendorId }));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Createagreementwithadobe = createAsyncThunk(
  "task/Createagreementwithadobe",
  async (data, thunkAPI) => {
    try {
      const res = await taskService.CreateagreementwithadobeAPI(data);
      thunkAPI.dispatch(SetAdobesignModalvisible({ visible:true}))
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Createagreementgetsignwithadobe = createAsyncThunk(
  "task/Createagreementgetsignwithadobe",
  async ({data,taskPagination}, thunkAPI) => {
    try {
      const res = await taskService.CreateagreementgetsignwithadobeAPI(data);
   if(res.data.signed_by_company){
    message.success("Signature submit successfully")

    thunkAPI.dispatch(setcontractsuccessModal(true))
    thunkAPI.dispatch(SetAdobesignModalvisible({visible:false,record:{}}))

    thunkAPI.dispatch(getTaskdetails(taskPagination));
    thunkAPI.dispatch(setDrawerVisible({ visible: false }));
   }
  
   
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
