import { createAsyncThunk } from "@reduxjs/toolkit";
import ChecklistServices from "./../../Services/ChecklistServices";
import workflowTypeservices from "./../../Services/workflowTypeservices";
import { responseMessageHandler } from "../../globals/components/notification";
export const getchecklists = createAsyncThunk(
  "checklist/getchecklists",
  async (data, thunkAPI) => {
    try {
      let res = await ChecklistServices.getchecklistAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createchecklist = createAsyncThunk(
  "checklist/createchecklist",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await ChecklistServices.createchecklistAPI(data);
      thunkAPI.dispatch(getchecklists(workflowPagination));
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatechecklist = createAsyncThunk(
  "checklist/updatechecklist",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await ChecklistServices.createchecklistAPI(data);
      thunkAPI.dispatch(getchecklists(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deletechecklist = createAsyncThunk(
  "checklist/deletechecklist",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await ChecklistServices.deletechecklistApi(data);
      thunkAPI.dispatch(getchecklists(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getworkflowType = createAsyncThunk(
  "workflowType/getworkflowType",
  async (data, thunkAPI) => {
    try {
      let res = await workflowTypeservices.getworkflowTypeAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createworkflowType = createAsyncThunk(
  "workflowType/createworkflowType",
  async ({ data, workflowtypePagination }, thunkAPI) => {
    try {
      const res = await workflowTypeservices.createworkflowTypeAPI(data);
      thunkAPI.dispatch(getworkflowType(workflowtypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteworkflowType = createAsyncThunk(
  "workflowType/deleteworkflowType",
  async ({ data, workflowtypePagination }, thunkAPI) => {
    try {
      const res = await workflowTypeservices.deleteworkflowTypeApi(data);
      thunkAPI.dispatch(getworkflowType(workflowtypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getchecklistfromadmin = createAsyncThunk(
  "checklist/getchecklistfromadmin",
  async (data, thunkAPI) => {
    try {
      let res = await ChecklistServices.getchecklistAPI({
        getplatformadminData: true,
        toImport: true,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const importchecklistfromadmin = createAsyncThunk(
  "checklist/importchecklistfromadmin",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      let res = await ChecklistServices.importchecklistfromadminAPI(data);
      thunkAPI.dispatch(getchecklists(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteadminchecklist = createAsyncThunk(
  "checklist/deleteadminchecklist",
  async ({ data, workflowPagination }, thunkAPI) => {
    try {
      const res = await ChecklistServices.deleteadminchecklistApi(data);
      thunkAPI.dispatch(getchecklists(workflowPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getworkflowTypefromadmin = createAsyncThunk(
  "workflowType/getworkflowTypefromadmin",
  async (data, thunkAPI) => {
    try {
      let res = await workflowTypeservices.getworkflowTypeAPI({
        getplatformadminData: true,
        toImport: true,
      });
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const importworkflowTypefromadmin = createAsyncThunk(
  "workflowType/importworkflowTypefromadmin",
  async ({ data, workflowtypePagination }, thunkAPI) => {
    try {
      let res = await workflowTypeservices.importworkflowTypefromadminAPI(data);
      thunkAPI.dispatch(getworkflowType(workflowtypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteadminworkflowType = createAsyncThunk(
  "workflowType/deleteadminworkflowType",
  async ({ data, workflowtypePagination }, thunkAPI) => {
    try {
      const res = await workflowTypeservices.deleteadminworkflowTypeApi(data);
      thunkAPI.dispatch(getworkflowType(workflowtypePagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
