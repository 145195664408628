import { createSlice } from "@reduxjs/toolkit";
import { getnotifications, getnotificationsList } from "./layout.action";
import sideMenu from "./sider/side-menu.config";

const layoutState = {
  collapsed: false,
  selectSideMenu: [],
  notificationData: [],
  sideMenuListdata: sideMenu,
  PermissionRoutes: [],
  layout_store: null,
  isvendormodal: false,
  popovervisible: false,
  isVisible: false,
  isnotificationloading: false,
  notificationPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },

  isNotificationModalVisible: false,
  isnotificationModalloading: false,
  getnotificationsListData: [],
  notificationModalPagination: {
    total: 0,
    pageSize: 10,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
    start_date: "",
    end_date: "",
  },
};
export const layoutSlice = createSlice({
  name: "layout_store",
  initialState: layoutState,
  reducers: {
    setvendormodal: (state, action) => {
      state.isvendormodal = action.payload;
    },
    onCollapse: (state, action) => {
      state.collapsed = action.payload;
    },
    handleSideBarChange: (state, action) => {
      state.layout_store = action.payload;
    },
    MenuClick: (state, action) => {
  
      localStorage.setItem("selectSideMenu", [
        action.payload.keyPath[action.payload.keyPath.length - 1],
      ]);
      state.selectSideMenu = [
        action.payload.keyPath[action.payload.keyPath.length - 1],
      ];
    },
    onPageChangesFilter: (state, { payload }) => {
      state.notificationPagination = {
        ...state.notificationPagination,
        current: payload.current,
        pageSize: payload.size,
      };
    },
    onPageChangesFilterModal: (state, { payload }) => {
      state.notificationModalPagination = {
        ...state.notificationModalPagination,
        current: payload.page,
        pageSize: payload.limit,
        start_date: payload.start_date,
        end_date: payload.end_date,
      };
    },
    onMenuClickchanges: (state, action) => {
      localStorage.setItem("selectSideMenu", action.payload);
      state.selectSideMenu = action.payload;
    },
    resetCurrentSideMenu: (state, action) => {
      state.selectSideMenu = [];
    },
    setCurrentSideMenu: (state, action) => {
      // state.layout_store = action.payload;
      if (!localStorage.getItem("selectSideMenu")) {
        localStorage.setItem("selectSideMenu", "dashboard");
        state.selectSideMenu = ["dashboard"];
        // history.push("/templates");
      } else {
        state.selectSideMenu = [localStorage.getItem("selectSideMenu")];
      }
    },
    getActiveKey: (state, action) => {
      // let currentActiveKey = [];
      state.layout_store = null;
      state.isAuthenticated = false;
    },
    handleKeyWithMenuId: (state, action) => {
      // const { allow_routes } = this.allStore.globals;
      const allow_routes = [999];
      let PermissionRoutes = [];
      sideMenu.forEach((content) => {
        let contentShowIds = allow_routes.filter(
          (e) => content.menuIds.indexOf(e) !== -1
        );
        if (!content.children.length && !!contentShowIds.length) {
          let route = content.route.split("/")[1];
          PermissionRoutes.push(route);
        } else {
          content.children.forEach((children) => {
            let childrenShowIds = allow_routes.filter(
              (e) => children.menuIds.indexOf(e) !== -1
            );
            if (!!childrenShowIds.length) {
              let ChildRoute = children.route.replace("/", "");
              PermissionRoutes = PermissionRoutes.concat(ChildRoute);
            }
            return null;
          });
        }

        return null;
      });
      PermissionRoutes.push("page-not-found");
      PermissionRoutes.push("user");
      state.PermissionRoutes = PermissionRoutes;
    },
    sidebardatafunc: (state, action) => {},
    updateSideMenuCount: (state, action) => {},
    popoverhandleOpenChange: (state, action) => {
      state.popovervisible = action.payload;
    },
    SetNotification: (state, action) => {
      state.isVisible = action.payload.visible;
      if (!action.payload.visible) {
        state.notificationPagination = {
          total: 0,
          pageSize: 20,
          defaultCurrent: 1,
          current: 1,
          sortBy: "DESC",
          showSizeChanger: true,
          search: "",
          orderBy: "createdAt",
        };
      }
    },
    SetNotificationModal: (state, action) => {
      state.isNotificationModalVisible = action?.payload?.visible;
      if (!action.payload.visible) {
        state.notificationModalPagination = {
          total: 0,
          pageSize: 10,
          defaultCurrent: 1,
          current: 1,
          sortBy: "DESC",
          showSizeChanger: true,
          search: "",
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getnotifications.pending, (state) => {
        state.isnotificationloading = true;
      })
      .addCase(getnotifications.fulfilled, (state, { payload }) => {
        state.notificationData = payload.data;
        state.notificationPagination = {
          ...state.notificationPagination,
          total: payload.total,
        };
        state.isnotificationloading = false;
      })
      .addCase(getnotifications.rejected, (state, { payload }) => {
        state.isnotificationloading = false;
      })
      .addCase(getnotificationsList.pending, (state) => {
        state.isnotificationloading = true;
      })
      .addCase(getnotificationsList.fulfilled, (state, { payload }) => {
    
        state.getnotificationsListData = payload?.data ? payload.data : [];
       
        state.notificationModalPagination = {
          ...state.notificationModalPagination,
          total: payload.count,
        };
        state.isnotificationloading = false;
      })
      .addCase(getnotificationsList.rejected, (state, { payload }) => {
        state.isnotificationloading = false;
      });
  },
});

export const {
  onCollapse,
  handleSideBarChange,
  getActiveKey,
  MenuClick,
  resetCurrentSideMenu,
  setCurrentSideMenu,
  handleKeyWithMenuId,
  sidebardatafunc,
  updateSideMenuCount,
  setvendormodal,
  popoverhandleOpenChange,
  SetNotification,
  onMenuClickchanges,
  onPageChangesFilter,
  SetNotificationModal,
  onPageChangesFilterModal,
} = layoutSlice.actions;
export default layoutSlice.reducer;
