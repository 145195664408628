import { createAsyncThunk } from "@reduxjs/toolkit";
import licenceService from "../../Services/licenceServices";
import { responseMessageHandler } from "../../globals/components/notification";

export const getLicencingData = createAsyncThunk(
  "licence/getLicencingData",
  async (data, thunkAPI) => {
    try {
      let res = await licenceService.getLicencingDataAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createLicencingData = createAsyncThunk(
  "licence/createLicencingData",
  async ({ data, LicencePagination }, thunkAPI) => {
    try {
      let res = await licenceService.createLicencingDataAPI({
        ...data,
      });
      thunkAPI.dispatch(getLicencingData(LicencePagination));
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteLicencingData = createAsyncThunk(
  "licence/deleteLicencingData",
  async ({data,LicencePagination}, thunkAPI) => {
    try {
      let res = await licenceService.deleteLicencingDataAPI({
        ...data,
      });
      thunkAPI.dispatch(getLicencingData(LicencePagination));

      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
