import { createAsyncThunk } from "@reduxjs/toolkit";
import UserServices from "./../../Services/userServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { message } from "antd";
import { setCurrentSideMenu } from "./../../globals/layout/layout.slice";
import { getnotificationsList } from "./../../globals/layout/layout.action";
import {
  getTaskcomments,
  getDetails,
  getTaskactivitylist,
  // reviewCommentslist,
} from "../TaskmanagementVendor/TaskmanagementVendor.Action";

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.login(data);
      localStorage.setItem("customerToken", res.data.accessToken);

      if (res.data && res.data.vendor && res.data.vendor._id) {
        localStorage.setItem("vendorToken", res.data.vendor._id);
      }
      if (res.data && res.data.user && res.data.user.companyId) {
        localStorage.setItem("companyId", res.data.user.companyId._id);
      } else if (res?.data?.companies?.length > 0) {
        localStorage.setItem("companyId", res?.data?.companies[0]?.companyId);
      } else if (res.data && res.data.user && res?.data?.user?.companyId?._id) {
        localStorage.setItem("companyId", res?.data?.user?.companyId?._id);
      }

      thunkAPI.dispatch(getChatRoomApi());
      const reqData = {
        search: "",
        orderBy: "",
        page: 1,
        limit: 20,
        // isDefault: true,
        // companyId: "6578024c8ea8f1709af71f53",
      };
      if (localStorage.getItem("companyId")) {
        thunkAPI.dispatch(getnotificationsList(reqData));
      }
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.response?.data?.errors?.[0]?.detail ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setPasswordFunc = createAsyncThunk(
  "login/setPasswordFunc",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.resetpasswordAPI(data);
      message.success("Your password has been reset successfully");
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      // <Redirect exact to="/login" />
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getChatRoomApi = createAsyncThunk(
  "login/getChatRoomApi",
  async (data, thunkAPI) => {
    try {
      let vendorId = localStorage.getItem("vendorToken");
      const res = await UserServices.chatRoomApiFunc({
        ...data,
        is_vendor: vendorId ? true : false,
        vendor_id: vendorId || "",
      });
      if (res?.data?.user?.role?.isCompany) {
        localStorage.setItem("companyId", res?.data?.user?.companyId?._id);
      }

      if (!vendorId) {
        thunkAPI.dispatch(getRoleData());
      }
      thunkAPI.dispatch(setCurrentSideMenu());

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const UpdateuserProfile = createAsyncThunk(
  "login/UpdateuserProfile",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.UpdateuserProfileAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Forgetuserpassword = createAsyncThunk(
  "login/Forgetpassword",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.ForgetpasswordAPI(data);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Setdefaultvendor = createAsyncThunk(
  "login/Setdefaultvendor",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.SetvendorsAPI(data);

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRoleData = createAsyncThunk(
  "login/getRoleData",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.getRoleApi();
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLicencencedata = createAsyncThunk(
  "login/getLicencencedata",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.getLicencencedataApi();
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getOtp = createAsyncThunk(
  "login/getOtp",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.getOtpApi(data);
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "login/verifyOtp",
  async ({ data, history }, thunkAPI) => {
    // const history = useHistory();
    try {
      localStorage.setItem("customerToken", "");
      const res = await UserServices.verifyOtpApi(data);
      localStorage.setItem("customerToken", res.data.accessToken);
      localStorage.setItem("otp", data?.otp);
      localStorage.setItem("encryptedData", data?.encryptedData);
      localStorage.setItem("taskId", res?.data?.taskData?._id);
      localStorage.setItem("companyId", res?.data?.taskData?.companyId);
      localStorage.setItem("vendorId", res?.data?.vendor._id);
     
      if (res.data.companies) {
        const idArray = res.data.companies.map((obj) => obj.companyId);
        if (history) {
          history.push("/basic_Details");
        }
        localStorage.setItem("company_ids", idArray);
        thunkAPI.dispatch(
          getDetails({
            taskId: res?.data?.taskData?._id,
            companyIds: idArray,
            companyId: res?.data?.taskData?.companyId,
            vendorId: res?.data?.vendor._id,
          })
        );
        thunkAPI.dispatch(
          getTaskcomments({
            limit: "10",
            page: "1",
            orderBy: "createdAt",
            sortBy: "DESC",
            is_vendor: true,
            taskId: res?.data?.taskData?._id,
          })
        );
        thunkAPI.dispatch(
          getTaskactivitylist({
            taskId: res?.data?.taskData?._id,
            companyId: res?.data?.taskData?.companyId,
          })
        );

        // thunkAPI.dispatch(
        //   reviewCommentslist({
        //     taskId: res?.data?.taskData?._id,
        //     vendorId: res?.data?.vendor._id,
        //   })
        // );
      }

    
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GuestgetChatRoomApi = createAsyncThunk(
  "login/GuestgetChatRoomApi",
  async (data, thunkAPI) => {
    try {
      let vendorId = localStorage.getItem("vendorGuestToken");
      const res = await UserServices.GuestgetChatRoomApiFunc({
        ...data,
        is_vendor: vendorId ? true : false,
        vendor_id: vendorId || "",
      });
      if (!vendorId) {
        thunkAPI.dispatch(getRoleData());
      }
      thunkAPI.dispatch(setCurrentSideMenu());

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const loginwithMicrosoft = createAsyncThunk(
  "login/loginwithMicrosoft",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.loginwithMicrosoft(data);
      localStorage.setItem("customerToken", res.data.accessToken);
      if (res.data && res.data.vendor && res.data.vendor._id) {
        localStorage.setItem("vendorToken", res.data.vendor._id);
      }
      if (res.data && res.data.user && res.data.user.companyId) {
        localStorage.setItem("companyId", res.data.user.companyId._id);
      } else if (res?.data?.companies?.length > 0) {
        localStorage.setItem("companyId", res?.data?.companies[0]?.companyId);
      } else if (res.data && res.data.user && res?.data?.user?.companyId?._id) {
        localStorage.setItem("companyId", res?.data?.user?.companyId?._id);
      }

      thunkAPI.dispatch(getChatRoomApi());
      const reqData = {
        search: "",
        orderBy: "",
        page: 1,
        limit: 20,
        // isDefault: true,
        // companyId: "6578024c8ea8f1709af71f53",
      };
      if (localStorage.getItem("companyId")) {
        thunkAPI.dispatch(getnotificationsList(reqData));
      }
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.response?.data?.errors?.[0]?.detail ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getmicrosoftLogout = createAsyncThunk(
  "login/getmicrosoftLogout",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.getmicrosoftLogoutApi();
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const checkUrlTokenValidity = createAsyncThunk(
  "login/checkUrlTokenValidity",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.checkUrlTokenValidityAPI(data);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      // <Redirect exact to="/login" />
      // responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const SetChangePassword = createAsyncThunk(
  "login/SetChangePassword",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.SetChangePasswordAPI(data);
      message.success("Your password has been reset successfully");
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      // <Redirect exact to="/login" />
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const contactSalesTeam = createAsyncThunk(
  "login/contactSalesTeam",
  async (data, thunkAPI) => {
    try {
      const res = await UserServices.contactSalesApi();
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);