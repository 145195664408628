import { createSlice } from "@reduxjs/toolkit";
import {
  getStaffdetails,
  staffCreate,
  updatestaff,
  Resetpassword,
  deletestaff,
  getdepartmentList,
} from "./staff.Action";

const initialState = {
  isLoading: true,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  staffTableData: [],
  isVisible: false,
  ispasswordVisible: false,
  ispasswordloading: false,
  ispasswordupdated: false,
  editRecord: {},
  type: "",
  staffmDetails: {},
  ispagination: false,
  totalCount: null,
  staffPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  deptListData: [],
  dept_loading: false,
};
const staffSlice = createSlice({
  name: "staff",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    setPasswordmodal: (state, { payload }) => {
      state.ispasswordVisible = payload.visible;
      state.editRecord = payload?.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.staffPagination = {
        ...state.staffPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.staffPagination = {
        ...state.staffPagination,
        search: action.payload.search || "",
      };
    },
    staffdatareset: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffdetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStaffdetails.fulfilled, (state, { payload }) => {
        state.staffTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.staffPagination = {
          ...state.staffPagination,
          total: payload.total,
        };
      })
      .addCase(getStaffdetails.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(staffCreate.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(staffCreate.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;

        // responseMessageHandler()
      })
      .addCase(staffCreate.rejected, (state) => {
        state.isCreateLoading = false;
      })

      .addCase(updatestaff.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updatestaff.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;

        // responseMessageHandler()
      })
      .addCase(updatestaff.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(deletestaff.pending, (state, { payload }) => {
        //conditions
        // responseMessageHandler()
      })
      .addCase(deletestaff.fulfilled, (state, { payload }) => {
        //conditions
      })
      .addCase(deletestaff.rejected, (state, { payload }) => {
        //conditions
      })
      .addCase(Resetpassword.pending, (state, { payload }) => {
        state.ispasswordloading = true;
      })
      .addCase(Resetpassword.fulfilled, (state, { payload }) => {
        state.ispasswordVisible = false;
        state.ispasswordloading = false;
        state.ispasswordupdated = true;
      })
      .addCase(Resetpassword.rejected, (state, { payload }) => {
        state.ispasswordloading = false;
      })
      .addCase(getdepartmentList.pending, (state, { payload }) => {
        state.dept_loading = true;
      })
      .addCase(getdepartmentList.fulfilled, (state, { payload }) => {
        state.deptListData = payload.data;
        state.dept_loading = false;
      })
      .addCase(getdepartmentList.rejected, (state, { payload }) => {
        state.dept_loading = false;
      });
  },
});
export const {
  setModalVisible,
  onPageChangesFilter,
  staffdatareset,
  onSearchList,
  setPasswordmodal,
} = staffSlice.actions;

export default staffSlice.reducer;
