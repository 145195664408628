import React from 'react'
import CustomCard from '../../../globals/components/Card'

import { ReloadOutlined } from '@ant-design/icons';

import "react-quill/dist/quill.snow.css";
import { Button, Result, Spin } from 'antd';
import "./Guestlayout.less"
const ContentSection = (props) => {
   
    const {
      intl: { formatMessage },
      taskmanagementVendor,
     
    
    } = props;
    const {
     
      taskTicketDetails = {},
     
      isDataLoading=false,

    } = taskmanagementVendor;
    const {  templateContent="" } = taskTicketDetails;
  
  return (
    <div>
    <CustomCard
    // title={<TemplateDropdown />}
    title={formatMessage({ id: "content_detail" })}
    headStyle={{ background: "#EBEDEF" }}
    bodyStyle={{
      background: "#F5F5F5",
      overflowY: "auto",
      height: "84vh",
    }}
    style={{ height: "84vh" }}
   
  >
  <Spin spinning={isDataLoading } >
    {templateContent||isDataLoading ? (
      <CustomCard
        headStyle={{
          background: "#F7F7FA",
          height: "30px",
        }}
        title={formatMessage({ id: "content" })}
        // extra={
        //   <Button
        //     onClick={(e) => {
        //       highlightSelection(e);
        //     }}
        //   >
        //     Add Comment
        //   </Button>
        // }
      >
        <span
        className='guest-content-preview-main'
          id="article-text"
          dangerouslySetInnerHTML={{
            __html: templateContent ? templateContent : "",
          }}
        ></span>
        {/* <ReactQuill
          value={templateContent}
          modules={modules}
          theme="snow"
          readOnly
        /> */}
      </CustomCard>
    ) : (
      <>
      <Result
      className='loadingPageerror'
    status="500"
    title="500"
    subTitle="Sorry, something went wrong."
    extra={<Button icon={<ReloadOutlined />} onClick={()=>{
           window.location.reload();
        }}  >Refresh</Button>}
  />
      </>
    )}
    </Spin>
  </CustomCard>
  </div>
  )
}

export default ContentSection