import { Badge, Button, Col, Modal, Row } from "antd";
import React from "react";
import "./Guestlayout.less";
import {
  RightOutlined,
  FormOutlined,
  DownloadOutlined,
  UserOutlined,
  FileProtectOutlined,
  HighlightOutlined,
  ExclamationCircleFilled,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setselectedItems } from "../EmailGuestlogin.slice";
import {
  setSignatureModal,
  setchecklistModal,
  setContractViewModal,
} from "../../TaskmanagementVendor/TaskmanagementVendor.Slice";
import { dateFormatter } from "../../../utils/helper";

import { setModalVisible } from "../../Vendor/Vendor.Slice";
import {
  GuesttaskActions,

} from "../../TaskmanagementVendor/TaskmanagementVendor.Action";
import isEmpty from "lodash/isEmpty";
const { confirm } = Modal;
const ExtraSection = (props) => {
  const {
    intl: { formatMessage },
    
    taskmanagementVendor: {
      taskTicketDetails = {},
      taskMgmtStatus,
      taskPagination,
      isTaskConnectLoading=false,
      isSignatureVisibleLoading=false
    },
    user: { vendor_details = {},},
    user,
  } = props;
  const {
   
    approvals = [],
   
    vendorDetails = {},
  } = taskTicketDetails;
  let invoiceStatus = 1;
  const dispatch = useDispatch();
  const showTicketStatus = (status) => {
    if (Boolean(status)) {
      return (
        taskMgmtStatus[status] && (
          <span style={{ color: taskMgmtStatus[status].color }}>
            {taskMgmtStatus[status].name}
          </span>
        )
      );
    }
    return "-";
  };
  const handleSignDynamicLevelData = (data, invoiceStatus) => {
    const cust_emp_id = vendor_details._id;
    let currentData = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let eve = data[i];
        let is_stratargy = eve.strategy;
        let approved_user_details = eve.signVendorIds
          ? eve.signVendorIds.length
          : 0;
        if (Number(is_stratargy) !== Number(approved_user_details)) {
          currentData.push(eve);
          break;
        }
      }
    }
    let rstData =
      currentData.length &&
      currentData.find((eve) => eve.vendor_ids.includes(cust_emp_id));
    if (rstData && rstData.strategy) {
      var approval_ids = rstData.signVendorIds ? rstData.signVendorIds : [];
      const staff_id =
        rstData.vendor_ids && rstData.vendor_ids.length > 0
          ? rstData.vendor_ids
          : [];
      if (
        approval_ids.length >= 0 &&
        !approval_ids.includes(cust_emp_id) &&
        approval_ids.length <= rstData.strategy &&
        staff_id.includes(cust_emp_id)
      ) {
        var lastOBj = data.length > 0 ? data[data.length - 1] : {};
        if (lastOBj && lastOBj.strategy === lastOBj.approvalVendorIds.length)
          if ([1, 2].includes(Number(invoiceStatus))) {
            return rstData;
          }
      }
    }
    return {};
  };
  const handleCheckLastUser = (data, invoiceStatus) => {
    const cust_emp_id = vendor_details._id;
    const lastData = data[data.length - 1];
    if (lastData && lastData.strategy) {
      const approval_ids = lastData.signVendorIds ? lastData.signVendorIds : [];
      const staff_id =
        lastData.vendor_ids && lastData.vendor_ids.length > 0
          ? lastData.vendor_ids
          : [];
      if (
        approval_ids.length > 0 &&
        !approval_ids.includes(cust_emp_id) &&
        approval_ids.length + 1 === lastData.strategy &&
        staff_id.includes(cust_emp_id)
      ) {
        if ([1, 2].includes(Number(invoiceStatus))) {
          return { ...lastData, is_lastBtn: true };
        }
      }
      if (lastData.strategy === 1 && staff_id.includes(cust_emp_id)) {
        if ([1, 2].includes(Number(invoiceStatus))) {
          return { ...lastData, is_lastBtn: true };
        }
      }
    }
    return {};
  };
  const handleDynamicLevelData = (data, invoiceStatus) => {
    const cust_emp_id = vendor_details._id;
    let currentData = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let eve = data[i];
        let is_stratargy = eve.strategy;
        let approved_user_details = eve.approvalVendorIds
          ? eve.approvalVendorIds.length
          : 0;
        if (Number(is_stratargy) !== Number(approved_user_details)) {
          currentData.push(eve);
          break;
        }
      }
    }
    let rstData =
      currentData.length &&
      currentData.find((eve) => eve.vendor_ids.includes(cust_emp_id));
    if (rstData && rstData.strategy) {
      var approval_ids = rstData.approvalVendorIds
        ? rstData.approvalVendorIds
        : [];
      const staff_id =
        rstData.vendor_ids && rstData.vendor_ids.length > 0
          ? rstData.vendor_ids
          : [];
      if (
        approval_ids.length >= 0 &&
        !approval_ids.includes(cust_emp_id) &&
        approval_ids.length <= rstData.strategy &&
        staff_id.includes(cust_emp_id)
      ) {
        if ([1, 2].includes(Number(invoiceStatus))) {
          return rstData;
        }
      }
    }
    return {};
  };
  const staticDetailData = handleDynamicLevelData(approvals, invoiceStatus);
  const handleInvoiceAction = async (record) => {
    let rscData = {
      taskId: taskTicketDetails._id,
      vendorId:localStorage.getItem("vendorGuestToken")?localStorage.getItem("vendorGuestToken"): vendor_details?._id,
      companyId: taskTicketDetails?.companyId,

      action: 2,
      approvalId: record._id || approvals[0]._id, //LevelId
      isGuestUser: true
    };
    const newtaskPagination = {
      ...taskPagination,
      isGuestUser: true,
      companyIds: user.company_ids,
      vendorId: localStorage.getItem("vendorGuestToken")?localStorage.getItem("vendorGuestToken"): vendor_details?._id,
    };
    confirm({
      title: formatMessage({ id: "Task_popupconfirm_title" }),
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: formatMessage({ id: "yes" }),
      okType: "primary",
      cancelText: formatMessage({ id: "no" }),
      onOk() {
        dispatch(
          GuesttaskActions({
            rscData,
            taskReq: {
              taskId: taskTicketDetails._id,
              companyIds: user.company_ids,
              companyId: taskTicketDetails.companyId,
              isGuestUser: true,
              vendorId:localStorage.getItem("vendorGuestToken")?localStorage.getItem("vendorGuestToken"): vendor_details?._id,
            },
            newtaskPagination,
          })
        );
      },
      onCancel() {},
    });
  };
  const signatureModelOpen = async (is_last_data) => {
   
    const staticSignDetailData = handleSignDynamicLevelData(
      approvals,
      invoiceStatus
    );
    await dispatch(
      setSignatureModal({
        visible: true,
        record: staticSignDetailData,
        isvendorlastsignature: is_last_data,
        type: "guest",
        isGuestUser: true
      })
    );
  };
  const findisVendorApproved = () => {
    const checkisApproved = taskTicketDetails.vendorApprovalData.find(
      (item) => item.isVendor === true
    );

    if (isEmpty(checkisApproved)) {
      return false;
    } else {
      return checkisApproved?.approvalDate ? false : true;
    }
  };
  const findisVendorSigned= () => {
    const checkisSigned = taskTicketDetails.vendorApprovalData.find(
      (item) => item.isVendor === true
    );

    if (isEmpty(checkisSigned)) {
      return false;
    } else {
      return checkisSigned?.signDate? false : true;
    }
  };
  const findisVendorThirdPartySigned= () => {
    const checkisSigned = taskTicketDetails.vendorApprovalData.find(
      (item) => item.isVendor === true
    );

    if (isEmpty(checkisSigned)) {
      return false;
    } else {
      return checkisSigned?.isThirdPartySigned ? true : false;
    }
  };
  const findisVendorfillchecklist= () => {
    const checkisSigned = taskTicketDetails.vendorApprovalData.find(
      (item) => item.isVendor === true
    );

    if (isEmpty(checkisSigned)) {
      return false;
    } else {
      return checkisSigned?.vendorChecklistWithValue?.length>0? false : true;
    }
  };
 
  
  return (
    <div>
      <Row>
        <Col className="extra-sec-1" span={24}>
          <div className="guest-tsk-name-parent">
            <div className="guest-tsk-name">
              {taskTicketDetails?.taskName || "---"}
            </div>
          </div>
          <div className="guest-tsk-status-parent">
            <div className="guest-tsk-status">Status:</div>
            {taskTicketDetails?.taskStatus
              ? showTicketStatus(taskTicketDetails.taskStatus)
              : "---"}
          </div>
          <div className="guest-tsk-status-parent">
            <div className="guest-tsk-status">StartDate:</div>
            {taskTicketDetails.startDate
              ? dateFormatter(taskTicketDetails.startDate)
              : "---"}
          </div>
          <div className="guest-tsk-status-parent">
            <div className="guest-tsk-status">EndDate:</div>
            {taskTicketDetails.endDate
              ? dateFormatter(taskTicketDetails.endDate)
              : "---"}
          </div>
          <div className="guest-tsk-status-parent">
            <div className="guest-tsk-status">Description:</div>
            {taskTicketDetails?.taskDescription || "---"}
          </div>
        </Col>
        <Col className="extra-sec-2" span={24}>
          <h2 className="email-guest-actions">Actions</h2>
          <Button
            onClick={() => {
              dispatch(
                setModalVisible({
                  type: "createfromemail",
                  visible: true,
                  record: taskTicketDetails?.vendorDetails || {},
                })
              );
            }}
            block
            type="text"
            className="extra-sec-item4-content eguest"
            icon={<UserOutlined />}
          >
            {vendorDetails?.firstName ? "Review details" : "Fill Details"}{" "}
            {vendorDetails?.firstName && (
              <CheckCircleOutlined style={{ color: "green" }} />
            )}
            &nbsp;
            {[1,8].includes(taskTicketDetails?.taskStatus)&&
              !vendorDetails?.firstName && <Badge status="processing" />}
          </Button>
          <Button
            disabled={
              [1, 8].includes(taskTicketDetails.taskStatus) &&findisVendorfillchecklist()&&
              vendorDetails?.firstName
                ? false
                : true
            }
            onClick={() => {
              dispatch(setchecklistModal({ visible: true,type:"checklistactionforemail" }));
            }}
            block
            type="text"
            className="extra-sec-item4-content eguest"
            icon={<FormOutlined />}
          >
            Checklist{" "}
            {[5, 4, 7, 8,1].includes(taskTicketDetails?.taskStatus) &&!findisVendorfillchecklist()&& (
              <CheckCircleOutlined style={{ color: "green" }} />
            )}
            &nbsp;{" "}
            {[8,1].includes(taskTicketDetails?.taskStatus)&& vendorDetails?.firstName&&findisVendorfillchecklist() && (
              <Badge status="processing" />
            )}
          </Button>
          <Button
          loading={isTaskConnectLoading}
            disabled={
              [1, 8].includes(taskTicketDetails?.taskStatus) &&
              vendorDetails
              ?.firstName&&findisVendorApproved()
                ? false
                : true
            }
            onClick={() => {
              handleInvoiceAction(staticDetailData);
            }}
            block
            type="text"
            className="extra-sec-item4-content eguest"
            icon={<FileProtectOutlined />}
          >
            Approve{" "}
            {[5, 4, 7, 8,1].includes(taskTicketDetails?.taskStatus) &&!findisVendorApproved()&& (
              <CheckCircleOutlined style={{ color: "green" }} />
            )}
            &nbsp;{" "}
            {[1,8].includes(taskTicketDetails?.taskStatus) && vendorDetails?.firstName &&findisVendorApproved()&& (
              <Badge status="processing" />
            )}
          </Button>
          <Button
          loading={isSignatureVisibleLoading}
            disabled={taskTicketDetails.taskStatus === 5 ? false : true}
            onClick={(e) => {
              var lastObj = handleCheckLastUser(approvals, invoiceStatus);
              const is_last_data =
                lastObj && Object.keys(lastObj).length
                  ? lastObj.is_lastBtn
                  : false;
              if (taskTicketDetails?.thirdPartySignatureUrl) {
                window.location.href =
                  taskTicketDetails?.thirdPartySignatureUrl;
                // dispatch(SetAdobesignModalvisible({ visible: true,record:taskTicketDetails}));
              } else {
                signatureModelOpen(is_last_data);
              }
            }}
            block
            type="text"
            className="extra-sec-item4-content eguest"
            icon={<HighlightOutlined />}
          >
            Signature{" "}
            {[7, 8].includes(taskTicketDetails.taskStatus) &&(!findisVendorSigned()||findisVendorThirdPartySigned())&& (
              <CheckCircleOutlined style={{ color: "green" }} />
            )}
            &nbsp;{" "}
            {taskTicketDetails.taskStatus === 5 && (
              <Badge status="processing" />
            )}
          </Button>
          <Button
            disabled={taskTicketDetails.taskStatus === 7 ? false : true}
            onClick={() => {
              dispatch(
                setContractViewModal({
                  visible: true,
                  record: taskTicketDetails,
                })
              );
            }}
            block
            type="text"
            className="extra-sec-item4-content eguest"
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
        </Col>
        <Col className="extra-sec-3" span={24}>
          <Button
            onClick={() => {
              dispatch(setselectedItems({ item: "approvers" }));
            }}
            block
            type="text"
            className="extra-sec-item4-content"
            icon={<RightOutlined />}
          >
            Approvers
          </Button>
        </Col>
        <Col className="extra-sec-4" span={24}>
          <Button
            onClick={() => {
              dispatch(setselectedItems({ item: "activities" }));
            }}
            block
            type="text"
            className="extra-sec-item4-content"
            icon={<RightOutlined />}
          >
            Activities
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ExtraSection;
