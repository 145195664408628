import { appServiceName } from "../utils/environment";

import axios from "axios";


class RestfulProvider {
  serialize = (obj) => {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        if (typeof obj[p] === "boolean" || obj[p] === 0 || obj[p]) {
          str.push(`${encodeURI(p)}=${encodeURI(obj[p])}`);
        }
      }
    }
    return str.join("&");
  };

  get(url, obj = {}) {
    const tokenString = localStorage.getItem("customerToken")
      ? localStorage.getItem("customerToken")
      : undefined;
    return axios({
      method: "GET",
      url: `${appServiceName + url}`,
      headers: {
        Authorization: !tokenString ? "" : `Bearer ${tokenString}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => response.data);
  }
  deleteget(url, obj = {}) {
    const tokenString = localStorage.getItem("customerToken")
      ? localStorage.getItem("customerToken")
      : undefined;
    let ObjUpdate = {
      ...obj,
    };
    if (localStorage.getItem("companyId")) {
      ObjUpdate.companyId = localStorage.getItem("companyId");
    }
    return axios({
      method: "GET",
      url: `${appServiceName + url}/${obj}`,
      headers: {
        Authorization: !tokenString ? "" : `Bearer ${tokenString}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => response.data);
  }
  post(url, data) {
    const tokenString = localStorage.getItem("customerToken")&&!data?.isGuestUser
      ? localStorage.getItem("customerToken")
      : localStorage.getItem("guestToken")? localStorage.getItem("guestToken")
      :undefined;
    let ObjUpdate = {
      ...data,
    };
   
    if (
      (localStorage.getItem("companyId") ||
        localStorage.getItem("company_ids")) &&
      !ObjUpdate.companyId
    ) {
      ObjUpdate.companyId =
        localStorage.getItem("companyId") ||
        localStorage.getItem("company_ids");
    }
    return axios({
      method: "POST",
      url: appServiceName + url,
      headers: {
        Authorization: !tokenString ? "" : `Bearer ${tokenString}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        //  CrossDomain: true,
        //  "Access-Control-Allow-Origin": "http://localhost:5000",
        //  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
        //  "Access-Control-Allow-Headers": "Content-Type",
        //  "Access-Control-Max-Age": 86400,
      },
      data: JSON.stringify(ObjUpdate),
    }).then((response) => response.data);
  }
  postWithOutCompanyId(url, data) {
    const tokenString = localStorage.getItem("customerToken")
      ? localStorage.getItem("customerToken")
      : undefined;
    let ObjUpdate = {
      ...data,
    };
    if (localStorage.getItem("companyId")) {
      ObjUpdate.companyId = localStorage.getItem("companyId");
    }
    return axios({
      method: "POST",
      url: appServiceName + url,
      headers: {
        Authorization: !tokenString ? "" : `Bearer ${tokenString}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        //  CrossDomain: true,
        //  "Access-Control-Allow-Origin": "http://localhost:5000",
        //  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
        //  "Access-Control-Allow-Headers": "Content-Type",
        //  "Access-Control-Max-Age": 86400,
      },
      data: JSON.stringify(ObjUpdate),
    }).then((response) => response.data);
  }
  formDatapost(url, data) {
    const tokenString = localStorage.getItem("customerToken")
      ? localStorage.getItem("customerToken")
      : undefined;
      
    return axios({
      method: "POST",
      url: appServiceName + url,
      headers: {
        Authorization: !tokenString ? "" : `Bearer ${tokenString}`,
        "Content-type": "multipart/form-data",
        // Accept: "application/json",
        type: "formData",
        //  CrossDomain: true,
        //  "Access-Control-Allow-Origin": "http://localhost:5000",
        //  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
        //  "Access-Control-Allow-Headers": "Content-Type",
        //  "Access-Control-Max-Age": 86400,
      },
      data: data,
    }).then((response) => response.data);
  }
  downloadMethod(url, data) {
    const tokenString = localStorage.getItem("customerToken")
      ? localStorage.getItem("customerToken")
      : undefined;
    let ObjUpdate = {
      ...data,
    };
    // if (localStorage.getItem("companyId")) {
    //   ObjUpdate.companyId = localStorage.getItem("companyId");
    // }
    return axios({
      method: "POST",
      url: appServiceName + url,
      headers: {
        Authorization: !tokenString ? "" : `Bearer ${tokenString}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
      responseType: "arraybuffer",
      data: JSON.stringify(ObjUpdate),
    }).then((response) => {
      return response.data;
    });
  }
  guestPost(url, data) {
    const tokenString = data.encryptedData;
    let ObjUpdate = {
      ...data,
    };
    // delete ObjUpdate.encryptedData;

    return axios({
      method: "POST",
      url: appServiceName + url,
      headers: {
        Authorization: !tokenString ? "" : `Bearer ${tokenString}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        //  CrossDomain: true,
        //  "Access-Control-Allow-Origin": "http://localhost:5000",
        //  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
        //  "Access-Control-Allow-Headers": "Content-Type",
        //  "Access-Control-Max-Age": 86400,
      },
      data: JSON.stringify(ObjUpdate),
    }).then((response) => response.data);
  }
}
// eslint-disable-next-line
export default new RestfulProvider();
