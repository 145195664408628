import { createAsyncThunk } from "@reduxjs/toolkit";
import MyobligationService from "../../Services/MyobligationServices";
import { responseMessageHandler } from "../../globals/components/notification";


export const getMyObligationdata = createAsyncThunk(
  "Obligation/getMyObligationdata",
  async (data, thunkAPI) => {
    try {
      let res = await MyobligationService.getMyObligationdataAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
        // orderBy :"updatedAt"
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createObligation = createAsyncThunk(
  "Obligation/createObligation",
  async ({ data, ObligationPagination }, thunkAPI) => {
    try {
      const res = await MyobligationService.createObligationAPI(data);
      thunkAPI.dispatch(getMyObligationdata(ObligationPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteObligation = createAsyncThunk(
  "Obligation/deleteObligation",
  async ({ data, ObligationPagination }, thunkAPI) => {
    try {
      const res = await MyobligationService.deleteObligationAPI(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getMyObligationdata(ObligationPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getMyObligationdataitemdetail = createAsyncThunk(
  "Obligation/getMyObligationdataitemdetail",
  async (data, thunkAPI) => {
    try {
      let res = await MyobligationService.getMyObligationdataitemdetailAPI({
        ...data,
        
        // orderBy :"updatedAt"
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Getsubobligationdata = createAsyncThunk(
  "Obligation/Getsubobligationdata",
  async (data, thunkAPI) => {
    try {
      let res = await MyobligationService.GetsubobligationdataAPI({
        ...data,
 
     
       
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Getsubobligationform = createAsyncThunk(
  "Obligation/Getsubobligationform",
  async (data, thunkAPI) => {
    try {
      let res = await MyobligationService.GetsubobligationformAPI({
        ...data,
 
        limit: data.pageSize,
        page: data.current,
       
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const submitObligationdata = createAsyncThunk(
  "Obligation/submitObligationdata",
  async ({data,currentsubobligationpagination}, thunkAPI) => {
    try {
      let res = await MyobligationService.submitObligationdataAPI({
        ...data,
        
        // orderBy :"updatedAt"
      });
thunkAPI.dispatch(Getsubobligationdata(currentsubobligationpagination))
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const submitObligationAttachmentdata = createAsyncThunk(
  "Obligation/submitObligationAttachmentdata",
  async (data, thunkAPI) => {
    try {
      let res = await MyobligationService.submitObligationsubmitObligationAttachmentdatadataAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);