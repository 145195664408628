import { createSlice } from "@reduxjs/toolkit";
// import _formatMessage from "format-message";
import EnJS from './../translations/en'
const localState = {
  _locale: 'en',
  translations: EnJS,
  language: "",
  languageName: "",
};
export const LocalSlice = createSlice({
  name: "local",
  initialState: localState,
  reducers: {
    setLocale: (state, action) => {
      state.languageName = action.payload.language;
      state._locale = action.payload._locale;
    },
    messages: (state, action) => {
      state.translations = state.translations[state._locale];
    },
    // formatMessage :(id, values) => {
    //     if (!(id in this.messages)) {
    //       return id;
    //     }
    //     return _formatMessage(this.messages[id], values);
    //   }
  },
});

export const { setLocale } = LocalSlice.actions;
export default LocalSlice.reducer;
