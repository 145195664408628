import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/checklist";
class ChecklistServices {
  getchecklistAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/checklists`, data, headers);
  createchecklistAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);

  deletechecklistApi = (data) =>
    RestfulProvider.deleteget(`${subUrllocal}/delete`, data, headers);
  importchecklistfromadminAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/import`, data, headers);
  deleteadminchecklistApi = (data) =>
    RestfulProvider.post(`${subUrllocal}/remove`, data, headers);
}
// eslint-disable-next-line
export default new ChecklistServices();
