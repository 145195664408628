import { createSlice } from "@reduxjs/toolkit";
import {
  createhistoricalContract,
  deletehistoricalContract,
  gethistoricalContractSetting,
  updatehistoricalContract,
  getchecklistfromadmin,
  importchecklistfromadmin,
} from "./Historicalservice.Action";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isVisible: false,
  workflownewchecklistdata: {},
  workflowTableData: [],
  editRecord: {},
  type: "",
  totalCount: null,
  workflowPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },

  ischoosechecklistmodalVisible: false,
  ischoosemodalVisible: false,
  workflowtypedatafromadmin: [],
  workflowtypefromadminloading: false,
  ischecklistfromadminloading: false,
  checklistdatafromadmin: [],
  ischecklistimportloading: false,
  newhistoricalchecklistdata: {},
};
const HistoricalserviceSlice = createSlice({
  name: "historical_services",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.type = payload?.type;
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
      }
    },

    setselectforchecklistvisible: (state, { payload }) => {
      state.ischoosechecklistmodalVisible = payload.visible;

      if (!payload.visible) {
      }
    },
    onPageChangesFilter: (state, action) => {
      state.workflowPagination = {
        ...state.workflowPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    onSearchList: (state, action) => {
      state.workflowPagination = {
        ...state.workflowPagination,
        search: action.payload.search || "",
      };
    },
    ClearnewhistoricalData: (state, action) => {
      state.newhistoricalchecklistdata = {};
    },
    historicalServicedatareset: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gethistoricalContractSetting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(gethistoricalContractSetting.fulfilled, (state, { payload }) => {
        state.workflowTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.workflowPagination = {
          ...state.workflowPagination,
          total: payload.totalCount,
        };
      })
      .addCase(gethistoricalContractSetting.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createhistoricalContract.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createhistoricalContract.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
        if (state.type === "createfromhistorical") {
          state.newhistoricalchecklistdata = payload.data;
        
        }
      })
      .addCase(createhistoricalContract.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(updatehistoricalContract.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(updatehistoricalContract.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(updatehistoricalContract.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })
      .addCase(deletehistoricalContract.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(deletehistoricalContract.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(deletehistoricalContract.rejected, (state, { payload }) => {
        state.isCreateLoading = false;
      })

      .addCase(getchecklistfromadmin.pending, (state) => {
        state.ischecklistfromadminloading = true;
      })
      .addCase(getchecklistfromadmin.fulfilled, (state, { payload }) => {
        state.checklistdatafromadmin = payload.data;
        state.ischecklistfromadminloading = false;
      })
      .addCase(getchecklistfromadmin.rejected, (state, { payload }) => {
        state.ischecklistfromadminloading = false;
      })
      .addCase(importchecklistfromadmin.pending, (state) => {
        state.ischecklistimportloading = true;
      })
      .addCase(importchecklistfromadmin.fulfilled, (state, { payload }) => {
        state.ischoosechecklistmodalVisible = false;
        state.ischecklistimportloading = false;
      })
      .addCase(importchecklistfromadmin.rejected, (state, { payload }) => {
        state.ischecklistimportloading = false;
      });
  },
});
export const {
  setModalVisible,
  onPageChangesFilter,
  onSearchList,
  historicalServicedatareset,
  setselectforchecklistvisible,
  ClearnewhistoricalData,
} = HistoricalserviceSlice.actions;
export default HistoricalserviceSlice.reducer;
