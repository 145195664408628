import React from "react";
import { Card } from "antd";
import "./card.less";

const CustomCard = (props) => {
  let {
    badge = null,
    extraRight = "",
    bordered = false,
    className = "",
    extra,
  } = props;
  let propsCopy = { ...props };
  delete propsCopy.extraRight;
  delete propsCopy.badge;
  delete propsCopy.className;
  return (
    <>
      {badge ? (
        <Card
          className={"custom-card-title"}
          size="small"
          bordered={bordered}
          {...propsCopy}
          title={badge}
          extra={extraRight || extra}
        >
          {this.props.children}
        </Card>
      ) : (
        <Card
          className={
            badge
              ? `custom-card custom-row ${className}`
              : `custom-card ${className}`
          }
          size="small"
          bordered={bordered}
          {...propsCopy}
        >
          {props.children}
        </Card>
      )}
    </>
  );
};

export default CustomCard;
