import {
  Button,
  Carousel,
  Checkbox,
  Col,
  Form,
  Input,
  Result,
  Row,
} from "antd";
import React, { useEffect } from "react";

import whiteLogo from "../../../src/assets/images/white-logo.png";
import clm1 from "../../../src/assets/images/clm.jpg";
import clm2 from "../../../src/assets/images/clm2.png";
import clm3 from "../../../src/assets/images/clm3.jpg";
import clm4 from "../../../src/assets/images/clm4.jpg";

import "./index.less";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { CompanySignup } from "./FreeTrial.Action";
import { injectIntl } from "react-intl";
import { freetrialDatareset } from "./FreeTrial.slice";
const Index = (props) => {
  const [isPagerender, setisPagerender] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const istrialtermsaccepted = Form.useWatch("remember", form);

  const {
    FreeTrial,
    intl: { formatMessage },
  } = props;
  const { isLoading = false, isFormSubmitted = false } = FreeTrial;
  useEffect(() => {
    setisPagerender(true);

    return () => {
      dispatch(freetrialDatareset());
    };
  }, [dispatch]);

  const contentStyle = {
    height: "300px",
    color: "#fff",
    lineHeight: "300px",
    textAlign: "center",
    background: "#efefef",
    width: "100%",
    borderRadius: "15px",
  };
  const onSubmit = async () => {
    const values = await form.validateFields();
  
    dispatch(CompanySignup(values));
  };
  const validateInput = (rule, value, callback) => {
    // Use a regular expression to allow letters, numbers, and spaces
    if (!/^[A-Za-z\s]+$/.test(value)) {
      callback("Only letters allowed.");
    } else {
      callback();
    }
  };
  return (
    <Row>
      <Col className="free-trial-imgContainer" md={12} xs={24}>
        <div className="free_trial_left_logo">
          
           <img  alt="" src={whiteLogo}></img>
          <div className="logo_name">
          VerifiableContract
          </div>
        </div>
        <div className="free_trial_left_infobox">
          <div className="free_trial_left_title">
            Start Your 14-Day Free Trial of VerifiableContract{" "}
          </div>
          <div
            className={
              isPagerender
                ? "free_trial_left_extra_info active"
                : "free_trial_left_extra_info"
            }
          >
            <Carousel autoplay>
              <div>
                <img style={contentStyle} alt="" src={clm1}></img>
              </div>
              <div>
                <img style={contentStyle} alt="" src={clm2}></img>
              </div>
              <div>
                <img style={contentStyle} alt="" src={clm3}></img>
              </div>
              <div>
                <img style={contentStyle} alt="" src={clm4}></img>
              </div>
            </Carousel>
          </div>
        </div>
      </Col>
      <Col md={12} xs={24} className="free-trial-formcontainer">
        {!isFormSubmitted && (
          <div className="free-trial-formbox">
            <div className="free-trial-boxtitle">
              Fill out the form below, and we’ll send you an email with a link
              to access your account.
            </div>
            <div className="free-trial-boxtitle_info">
              No Commitments needed
            </div>
            <div
              className={
                isPagerender ? "free-trial-form active" : "free-trial-form"
              }
            >
              <Form form={form} size="large">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: "fname_rule" }),
                    },
                    {
                      whitespace: true,
                    },
                    {
                      validator: validateInput,
                    },
                  ]}
                  name="firstName"
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: "lname_rule" }),
                    },
                    {
                      whitespace: true,
                    },
                    {
                      validator: validateInput,
                    },
                  ]}
                  name="lastName"
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: "email_rule" }),
                    },
                    {
                      whitespace: true,
                    },
                    {
                      type: "email",
                      message: formatMessage({ id: "email_must_rule" }),
                    },
                  ]}
                  name="email"
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: "cname_rule" }),
                    },
                    {
                      whitespace: true,
                    },
                  ]}
                  name="companyName"
                >
                  <Input placeholder="Company Name" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "Please enter designation!" },
                    {
                      whitespace: true,
                      message: "Designation cannot be an empty string",
                    },
                  ]}
                  name="designation"
                >
                  <Input placeholder="Designation" />
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>
                    I understand and agree to the Free Trial License Agreement,
                    Terms of Service, and Privacy Policy.
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => {
                      if(istrialtermsaccepted){
                        onSubmit();

                      }else{
                        // Please review and accept the agreements.
                        alert("Please review and accept the terms and conditions");
                      }
                    }}
                    loading={isLoading}
                    block
                    type="primary"
                  >
                    Start your free trial
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
        {isFormSubmitted && (
          <div className="free-trial-success">
            <Result
              status="success"
              title=" Thank you for your interest in our product!"
              subTitle="Check your inbox for a link to get your account activated"
              extra={[
                <Button href="/" key="console">
                  Back Home
                </Button>,
              ]}
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default connect((state) => ({
  user: state.user,
  global: state.global,
  historical_services: state.historical_services,
  EmailGuestlogin: state.EmailGuestlogin,
  taskmanagementVendor: state.taskmanagementVendor,
  vendor: state.vendor,
  FreeTrial: state.FreeTrial,
}))(injectIntl(Index));
