import { createSlice } from "@reduxjs/toolkit";

import {
  getstoragetype,
  setstoragetype,
  deletestorageType,
  getLicencencestoragedata,
  setadobeStatus
} from "./Otherservicescompany.Action";
const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  currentduration: 604800000,
  durationsetin: "week",
  currentstoragetypedata: {},
  storageId: "",
  isstoragedeleting: false,
  Licencestoragedata:{},
  AdobestatusLoading:false,
};
const OtherservicescompanySlice = createSlice({
  name: "OtherservicescompanySlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getstoragetype.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getstoragetype.fulfilled, (state, { payload }) => {
        state.currentstoragetypedata =
          payload.data.length > 0 ? payload?.data[payload.data.length - 1] : {};
        state.storageId =
          payload.data.length > 0
            ? payload.data[payload.data.length - 1]?._id
            : "";
        // state.isLoading = false;
      })
      .addCase(getstoragetype.rejected, (state, { payload }) => {
        // state.isLoading = false;
      })
      .addCase(setstoragetype.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setstoragetype.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(setstoragetype.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(deletestorageType.pending, (state) => {
        state.isstoragedeleting = true;
      })
      .addCase(deletestorageType.fulfilled, (state, { payload }) => {
        state.currentstoragetypedata = {};
        state.storageId = "";
        state.isstoragedeleting = false;
      })
      .addCase(deletestorageType.rejected, (state, { payload }) => {
        state.isstoragedeleting = false;
      })
      .addCase(getLicencencestoragedata.pending, (state) => {})
      .addCase(getLicencencestoragedata.fulfilled, (state, { payload }) => {
        state.Licencestoragedata = payload?.data || {};
      })
      .addCase(getLicencencestoragedata.rejected, (state, { payload }) => {})
      .addCase(setadobeStatus.pending, (state) => {
        state.AdobestatusLoading = true;
      })
      .addCase(setadobeStatus.fulfilled, (state, { payload }) => {
        state.AdobestatusLoading = false;
       
      })
      .addCase(setadobeStatus.rejected, (state, { payload }) => {
        state.AdobestatusLoading = false;

      });
  },
});
// export const {} = OtherservicesSlice.actions;

export default OtherservicescompanySlice.reducer;
