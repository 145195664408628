//-------------------------------------------------All Api-------------------------------------------------

export const encrypt = true;
export const environment_key="demo"
// export const appServiceName = "https://9d6f-27-57-127-77.ngrok-free.app";
// export const appServiceName = "https://dev.backend.verifiablecontract.com"; //API Address Abhisek
// export const appSocketService = "https://dev.backend.verifiablecontract.com";
// export const PortalUrl = "https://dev.verifiablecontract.com";
export const PortalUrl = "https://demo.verifiablecontract.com";
export const appServiceName = "https://demo.backend.verifiablecontract.com"; //API Address Abhisek
export const appSocketService = "https://demo.backend.verifiablecontract.com"; //API Address Abhisek
// export const appServiceName = "http://localhost:5000"; //API Address Abhisek
// export const appSocketService = "http://localhost:5000";
