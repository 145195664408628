import { createSlice } from "@reduxjs/toolkit";
import { CompanySignup } from "./FreeTrial.Action";



const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
 
  isVisible: false,
  

  editRecord: {},
  type: "",
 
  totalCount: null,
  contentPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "",
  },
  CurrentselectedItems:"parent",
  isFormSubmitted:false,

 
};
const FreeTrialSlice = createSlice({
  name: "FreeTrial",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.taskDetailsdata = payload.taskDetailsdata;
      state.type = payload.type || "";
      state.editRecord = payload.record || {};
      state.editRecordContent = payload.editRecordContent
        ? Array.isArray(payload.editRecordContent) &&
          payload.editRecordContent.length
          ? payload.editRecordContent[0].content
          : payload.editRecordContent.content
        : payload.record
        ? payload.record.content
        : "";
      if (!payload.visible) {
        state.editRecord = {};
        state.taskDetailsdata = {};
        state.contentPreviewData = "";
      }
    },
    setselectedItems: (state, { payload }) => {
        state.CurrentselectedItems = payload.item;
     
      },
      freetrialDatareset: (state, action) => {
        return initialState;
      },
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(CompanySignup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CompanySignup.fulfilled, (state, { payload }) => {
       
        state.isLoading = false;
        state.isFormSubmitted=true
      
      })
      .addCase(CompanySignup.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
     
      
  },
});
export const {
    setselectedItems,
    freetrialDatareset
} = FreeTrialSlice.actions;

export default FreeTrialSlice.reducer;
