import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseMessageHandler } from "../../globals/components/notification";
import verifyServices from "../../Services/verifyServices";
import { setissubmitted } from "./Verify.Slice";

export const getcontractVerify = createAsyncThunk(
  "verify/getcontractVerify",
  async (data, thunkAPI) => {
    try {
      const { form_data } = data;
      let res = await verifyServices.getgetcontractVerifyAPI(form_data);
      thunkAPI.dispatch(setissubmitted(true));
      responseMessageHandler(res);

      return res;
    } catch (error) {
     
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setissubmitted(true));

      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
