import { createSlice } from "@reduxjs/toolkit";

import {
  getTaskdetails,
  createTask,
  deleteTask,
  getTaskcomments,
  createTaskcomment,
  getDetails,
  createTemplateTask,
  taskAssignee,
  taskConnect,
  taskActions,
  checklistSubmit,
  signatureApicall,
  uploadrequestforChange,
  getTaskactivitylist,
  getReviewTaskcomments,
  createReviewTaskcomment,
  addContenReviewTaskcomment,
  reviewCommentslist,
  reviewerCommentAction,
  trackTaskhistory,
  deleteCommentHistory,
  Createagreementwithadobe,
} from "./MyTask.Action";
const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  taskTableData: [],
  isVisible: false,
  ispasswordVisible: false,
  ispasswordloading: false,
  isdrawerVisible: false,
  editRecord: {},
  type: "",
  staffmDetails: {},
  ispagination: false,
  totalCount: null,
  taskPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "updatedAt",
  },
  TaskcommentPagination: {
    limit: "10",
    page: "1",
    orderBy: "createdAt",
    sortBy: "DESC",
  },
  TaskCommentData: [],
  Templatefiltereddata: [],
  Checklistfiltereddata: [],
  currenttaskStep: 0,
  commentLoading: false,
  isDataLoading: false,
  taskTicketDetails: {},
  taskMgmtStatus: {
    1: {
      name: "Open",
      color: "#e17b27",
      id: 1,
    },
    4: {
      name: "Approval InProgress",
      color: "#01aef0",
      id: 4,
    },

    5: {
      name: "Approved",
      color: "#8ec63f",
      id: 5,
    },
    6: {
      name: "Rejected",
      color: "#800000",
      id: 6,
    },
    7: {
      name: "Completed",
      color: "green",
      id: 6,
    },
    8: {
      name: "Signature InProgress",
      color: "#077E8C",
      id: 6,
    },
    9: {
      name: "Expired",
      color: "red",
      id: 6,
    },
    10: {
      name: "Update Request",
      color: "#5BC0DE",
      id: 7,
    },
    11: {
      name: "Update Accepted",
      color: "green",
      id: 8,
    },
    12: {
      name: "Update Rejected",
      color: "red",
      id: 9,
    },
    13: {
      name: "Update Completed",
      color: "#5CB85C",
      id: 10,
    },
    14: {
      name: "Aborted",
      color: "cyan",
      id: 11,
    },
    15: {
      name: "Internal review",
      color: "#01aef0",
      id: 15,
      tagcolor: "processing",
    },
    16: {
      name: "Review Completed",
      color: "green",
      id: 16,
    },
  },
  isTaskSubmitLoading: false,
  DrawerRecord: "",
  isTaskAssigneeLoading: false,
  isTaskConnectLoading: false,
  taskContentString: "",
  iscoommentmodalVisible: false,
  iscommentSuccess: false,
  iscommentLoading: false,
  editcommentdata: {},
  isChecklistVisible: false,
  isConfirmComment: false,
  isConfirmCommentLoading: false,
  commentDetails: {},
  isChecklistLoading: false,

  isSignatureVisible: false,
  signatureDetails: {},
  isSignatureVisibleLoading: false,

  isSignatureViewVisible: false,
  signatureViewDetails: {},

  isContractViewVisible: false,
  contractViewDetails: {},
  isContractLoading: false,
  iscommentmodalVisible: false,
  isInvoiceActionBtnLoading: false,
  selecteduserdetails: {},
  isvendoruserdetailvisible: false,
  isuploadrequestforChangeLoading: false,
  taskactivityData: [],
  checklistModalType: "",
  isChecklistPreview: false,
  checklistRecordData: {},

  reviewTaskcommentPagination: {
    limit: "10",
    page: "1",
    orderBy: "createdAt",
    sortBy: "DESC",
  },
  reviewTaskCommentData: [],

  isReviewConfirmComment: false,
  isReviewConfirmCommentLoading: false,
  reviewerModalType: "",
  reviewCommentDetails: {},

  reviewerCommentList: [],
  isReviewCommentLoading: false,

  reviewerCommentActionLoading: false,
  istrackhistoryModalvisible: false,
  trackTaskhistoryLoading: false,
  trackHistoryDetail: {},
  trackHistoryResponseDetail: {},
  adobesignData: {},
  adobesignDataloading: false,
  isAdobesignModalvisible: false,
  adobesignDatarecord: false,
  ismytaskFilterapplied: false,
};
const MyTaskSlice = createSlice({
  name: "MyTaskSlice",
  initialState: initialState,
  reducers: {
    setchecklistModal: (state, { payload }) => {
      state.isChecklistVisible = payload.visible;
      state.checklistModalType = payload.type || "";
    },
    setReviewCommentConfirmModel: (state, { payload }) => {
      state.isReviewConfirmComment = payload.visible;
      state.reviewerModalType = payload.type;
      state.isReviewConfirmCommentLoading = false;
      state.reviewCommentDetails = payload.record || "";
    },
    showChecklisytPreview: (state, { payload }) => {
      state.isChecklistPreview = payload.visible;
      state.checklistRecordData = payload.record || "";
    },
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
        state.currenttaskStep = 0;
      }
    },
    SetCommentmodal: (state, { payload }) => {
      state.iscommentmodalVisible = payload.visible;
      state.DrawerRecord = payload.record ? payload.record : state.DrawerRecord;
      state.TaskcommentPagination = {
        ...state.TaskcommentPagination,
        taskId: payload?.record?._id,
      };
      if (!payload.visible) {
        // state.DrawerRecord = {};
      }
    },
    setDrawerVisible: (state, { payload }) => {
      state.isdrawerVisible = payload.visible;
      state.DrawerRecord = payload.record;
      state.currenttaskStep = 0;
      state.TaskcommentPagination = {
        ...state.TaskcommentPagination,
        taskId: payload?.record?._id,
      };
      if (!payload.visible) {
        state.DrawerRecord = {};
        state.taskTicketDetails = {};
      }
    },
    onPageChangesFilter: (state, action) => {
      state.taskPagination = {
        ...state.taskPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    mytaskfilterpopoverhandleOpenChange: (state, { payload }) => {
      state.ismytaskfilterpopovervisible = payload;
    },
    mytaskdatareset: (state, action) => {
      return initialState;
    },
    onSearchList: (state, action) => {
      state.taskPagination = {
        ...state.taskPagination,
        search: action.payload.search || "",
      };
    },
    onChangetaskStep: (state, { payload }) => {
      state.currenttaskStep = payload;
    },
    // valueaddedonChange: (state, { payload }) => {
    //   const { parentindex, childindex, value } = payload;
    //   const updatedFields = [...state.Templatefiltereddata[parentindex].fields];
    //   updatedFields[childindex] = {
    //     ...updatedFields[childindex],
    //     value,
    //   };
    //   const updatedTemplatefiltereddata = [...state.Templatefiltereddata];
    //   updatedTemplatefiltereddata[parentindex] = {
    //     ...updatedTemplatefiltereddata[parentindex],
    //     fields: updatedFields,
    //   };
    //   return {
    //     ...state,
    //     Templatefiltereddata: updatedTemplatefiltereddata,
    //   };
    // },
    CheclistvalueonChange: (state, { payload }) => {
      const { parentindex, childindex, value } = payload;

      const updatedFields = [
        ...state.Checklistfiltereddata[parentindex].checkListData,
      ];

      updatedFields[childindex] = {
        ...updatedFields[childindex],
        value,
      };
      const updatedChecklistfiltereddata = [...state.Checklistfiltereddata];
      updatedChecklistfiltereddata[parentindex] = {
        ...updatedChecklistfiltereddata[parentindex],
        checkListData: updatedFields,
      };
      return {
        ...state,
        Checklistfiltereddata: updatedChecklistfiltereddata,
      };
    },
    ResetCheclistvalueoncancel: (state, { payload }) => {
      return {
        ...state,
        Checklistfiltereddata: [],
      };
    },
    setEditCommentData: (state, { payload }) => {
      state.iscoommentmodalVisible = payload.visible;
      state.editcommentdata = payload.record || {};
    },
    setSignatureModal: (state, { payload }) => {
      state.isSignatureVisible = payload.visible;
      state.signatureDetails = payload.record || {};
      state.islastsignature = payload.islastsignature || false;
    },
    setSignatureViewModal: (state, { payload }) => {
      state.isSignatureViewVisible = payload.visible;
      state.signatureViewDetails = payload.record || {};
    },
    openconformationModal: (state, { payload }) => {
      state.isConfirmComment = payload.visible;
      state.commentDetails = payload.record || {};
    },

    setContractViewModal: (state, { payload }) => {
      // state.isContractLoading = true;
      state.isContractViewVisible = payload.visible;
      // setTimeout(() => {
      // state.isContractLoading = false;
      // }, 1000);
      state.contractViewDetails = payload.record || {};
    },
    setprofileusermodal: (state, { payload }) => {
      state.isvendoruserdetailvisible = payload.visible;
      state.selecteduserdetails = payload.record;
    },
    settrackhistorydataModal: (state, { payload }) => {
      state.istrackhistoryModalvisible = payload.visible;
      state.trackHistoryDetail = payload.record;
    },
    SetAdobesignModalvisible: (state, { payload }) => {
      state.isAdobesignModalvisible = payload.visible;
      state.adobesignDatarecord = payload?.record || {};
    },
    getmytaskFilterActive: (state, { payload }) => {
      state.ismytaskFilterapplied = payload;
    },
    setReviewcommmentListEmpty: (state, { payload }) => {
      state.reviewerCommentList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskdetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTaskdetails.fulfilled, (state, { payload }) => {
        state.taskTableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.taskPagination = {
          ...state.taskPagination,
          total: payload.total,
        };
      })
      .addCase(getTaskdetails.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createTask.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createTask.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(createTask.rejected, (state) => {
        state.isCreateLoading = false;
      })
      .addCase(deleteTask.pending, (state, { payload }) => {
        //conditions
        // responseMessageHandler()
      })
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        //conditions
      })
      .addCase(deleteTask.rejected, (state, { payload }) => {
        //conditions
      })
      .addCase(getTaskcomments.pending, (state, { payload }) => {
        state.commentLoading = true;
      })
      .addCase(getTaskcomments.fulfilled, (state, { payload }) => {
        state.commentLoading = false;
        state.TaskCommentData = payload.data;
      })
      .addCase(getTaskcomments.rejected, (state, { payload }) => {
        state.commentLoading = false;
      })
      .addCase(createTaskcomment.pending, (state, { payload }) => {
        state.iscommentLoading = true;
      })
      .addCase(createTaskcomment.fulfilled, (state, { payload }) => {
        state.iscommentSuccess = true;
        state.iscommentLoading = false;
        state.iscoommentmodalVisible = false;
      })
      .addCase(createTaskcomment.rejected, (state, { payload }) => {
        state.iscommentLoading = false;
      })
      .addCase(getDetails.pending, (state) => {
        state.isDataLoading = true;
        state.taskTicketDetails = {};
      })
      .addCase(getDetails.fulfilled, (state, { payload }) => {
        if (payload.data && payload.data && payload.data.templateMainDetail) {
          state.Templatefiltereddata = [payload.data.templateMainDetail] || [];
        }
        if (
          payload.data &&
          payload.data.checklistWithValue &&
          payload.data.checklistWithValue.length > 0
        ) {
          state.Checklistfiltereddata = payload.data.checklistWithValue;
        }
        state.DrawerRecord = payload.data;
        state.taskTicketDetails = payload.data;
        state.isDataLoading = false;
      })
      .addCase(getDetails.rejected, (state, { payload }) => {
        state.isDataLoading = false;
      })
      .addCase(createTemplateTask.pending, (state) => {
        state.isTaskSubmitLoading = true;
      })
      .addCase(createTemplateTask.fulfilled, (state, { payload }) => {
        state.isTaskSubmitLoading = false;
        if (state.currenttaskStep === 1) {
          state.currenttaskStep = 0;
          state.isdrawerVisible = false;
        }
      })
      .addCase(createTemplateTask.rejected, (state, { payload }) => {
        state.isTaskSubmitLoading = false;
      })
      .addCase(taskAssignee.pending, (state) => {
        state.isTaskAssigneeLoading = true;
      })
      .addCase(taskAssignee.fulfilled, (state, { payload }) => {
        state.isTaskAssigneeLoading = false;
      })
      .addCase(taskAssignee.rejected, (state, { payload }) => {
        state.isTaskAssigneeLoading = false;
      })
      .addCase(taskConnect.pending, (state) => {
        state.isTaskConnectLoading = true;
        state.isInvoiceActionBtnLoading = true;
      })
      .addCase(taskConnect.fulfilled, (state, { payload }) => {
        state.taskContentString = payload.data.template;
        state.isTaskConnectLoading = false;
        state.isInvoiceActionBtnLoading = false;
      })
      .addCase(taskConnect.rejected, (state, { payload }) => {
        state.isTaskConnectLoading = false;
        state.isInvoiceActionBtnLoading = false;
      })
      .addCase(taskActions.pending, (state) => {
        state.isTaskConnectLoading = true;
      })
      .addCase(taskActions.fulfilled, (state, { payload }) => {
        // state.taskContentString = payload.data.template;
        state.isTaskConnectLoading = false;
        state.isConfirmComment = false;
        state.isdrawerVisible = false;
        state.DrawerRecord = {};
      })
      .addCase(taskActions.rejected, (state, { payload }) => {
        state.isTaskConnectLoading = false;

        if (
          [
            "Checklist required value not exist.",
            "Checklist's required value is not selected",
          ].includes(payload?.message?.en)
        ) {
          state.isChecklistVisible = true;
          state.checklistModalType = "action";
        }
      })
      .addCase(checklistSubmit.pending, (state) => {
        state.isChecklistLoading = true;
      })
      .addCase(checklistSubmit.fulfilled, (state, { payload }) => {
        // state.taskContentString = payload.data.template;
        state.isChecklistLoading = false;
      })
      .addCase(checklistSubmit.rejected, (state, { payload }) => {
        state.isChecklistLoading = false;
      })
      .addCase(signatureApicall.pending, (state) => {
        state.isSignatureVisibleLoading = true;
      })
      .addCase(signatureApicall.fulfilled, (state, { payload }) => {
        // state.taskContentString = payload.data.template;
        state.isSignatureVisibleLoading = false;
        state.isdrawerVisible = false;
        state.DrawerRecord = {};
        state.isSignatureVisible = false;
        state.signatureDetails = {};
      })
      .addCase(signatureApicall.rejected, (state, { payload }) => {
        state.isSignatureVisibleLoading = false;
      })
      .addCase(uploadrequestforChange.pending, (state) => {
        state.isuploadrequestforChangeLoading = true;
      })
      .addCase(uploadrequestforChange.fulfilled, (state, { payload }) => {
        // state.taskContentString = payload.data.template;
        state.isuploadrequestforChangeLoading = false;
        state.isdrawerVisible = false;
        state.DrawerRecord = {};
        state.currenttaskStep = 0;
        state.isConfirmComment = false;
      })
      .addCase(uploadrequestforChange.rejected, (state, { payload }) => {
        state.isuploadrequestforChangeLoading = false;
      })
      .addCase(getTaskactivitylist.pending, (state, { payload }) => {})
      .addCase(getTaskactivitylist.fulfilled, (state, { payload }) => {
        state.taskactivityData = payload.data;
      })
      .addCase(getTaskactivitylist.rejected, (state, { payload }) => {})

      .addCase(getReviewTaskcomments.pending, (state, { payload }) => {
        state.commentLoading = true;
      })
      .addCase(getReviewTaskcomments.fulfilled, (state, { payload }) => {
        state.commentLoading = false;
        state.reviewTaskCommentData = payload.data;
      })
      .addCase(getReviewTaskcomments.rejected, (state, { payload }) => {
        state.commentLoading = false;
      })
      .addCase(createReviewTaskcomment.pending, (state, { payload }) => {
        state.iscommentLoading = true;
      })
      .addCase(createReviewTaskcomment.fulfilled, (state, { payload }) => {
        state.iscommentSuccess = true;
        state.iscommentLoading = false;
        state.iscoommentmodalVisible = false;
      })
      .addCase(createReviewTaskcomment.rejected, (state, { payload }) => {
        state.iscommentLoading = false;
      })

      .addCase(addContenReviewTaskcomment.pending, (state, { payload }) => {
        state.isReviewConfirmCommentLoading = true;
      })
      .addCase(addContenReviewTaskcomment.fulfilled, (state, { payload }) => {
        state.isReviewConfirmCommentLoading = false;
        state.isReviewConfirmComment = false;
        state.reviewCommentDetails = {};
        state.reviewerModalType = "";
      })
      .addCase(addContenReviewTaskcomment.rejected, (state, { payload }) => {
        state.isReviewConfirmCommentLoading = false;
        state.isReviewConfirmComment = false;
        state.reviewCommentDetails = {};
      })
      .addCase(reviewCommentslist.pending, (state, { payload }) => {
        state.reviewerCommentList = [];
        state.isReviewCommentLoading = true;
      })
      .addCase(reviewCommentslist.fulfilled, (state, { payload }) => {
        state.isReviewCommentLoading = false;
        state.reviewerCommentList = payload.data;
      })
      .addCase(reviewCommentslist.rejected, (state, { payload }) => {
        state.isReviewCommentLoading = false;
        state.reviewerCommentList = [];
      })
      .addCase(reviewerCommentAction.pending, (state, { payload }) => {
        state.reviewerCommentActionLoading = true;
      })
      .addCase(reviewerCommentAction.fulfilled, (state, { payload }) => {
        state.reviewerCommentActionLoading = false;
        state.isReviewConfirmCommentLoading = false;
        state.isReviewConfirmComment = false;
        state.reviewCommentDetails = {};
        state.reviewerModalType = "";
      })
      .addCase(reviewerCommentAction.rejected, (state, { payload }) => {
        state.reviewerCommentActionLoading = false;
      })
      .addCase(trackTaskhistory.pending, (state, { payload }) => {
        state.trackTaskhistoryLoading = true;
      })
      .addCase(trackTaskhistory.fulfilled, (state, { payload }) => {
        state.trackTaskhistoryLoading = false;
        state.trackHistoryResponseDetail = payload.data;
      })
      .addCase(trackTaskhistory.rejected, (state, { payload }) => {
        state.trackTaskhistoryLoading = false;
      })
      .addCase(deleteCommentHistory.pending, (state, { payload }) => {
        state.reviewerCommentActionLoading = true;
      })
      .addCase(deleteCommentHistory.fulfilled, (state, { payload }) => {
        state.reviewerCommentActionLoading = false;
      })
      .addCase(deleteCommentHistory.rejected, (state, { payload }) => {
        state.reviewerCommentActionLoading = false;
      })
      .addCase(Createagreementwithadobe.pending, (state, { payload }) => {
        state.adobesignDataloading = true;
      })
      .addCase(Createagreementwithadobe.fulfilled, (state, { payload }) => {
        state.adobesignData = payload.data;
        state.adobesignDataloading = false;
      })
      .addCase(Createagreementwithadobe.rejected, (state, { payload }) => {
        state.adobesignDataloading = false;
      });
  },
});
export const {
  setModalVisible,
  SetCommentmodal,
  onPageChangesFilter,
  onSearchList,
  setDrawerVisible,
  valueaddedonChange,
  onChangetaskStep,
  CheclistvalueonChange,
  setEditCommentData,
  setchecklistModal,
  openconformationModal,
  setSignatureModal,
  setSignatureViewModal,
  setContractViewModal,
  mytaskdatareset,
  mytaskfilterpopoverhandleOpenChange,
  setprofileusermodal,
  showChecklisytPreview,
  setReviewCommentConfirmModel,
  ResetCheclistvalueoncancel,
  settrackhistorydataModal,
  SetAdobesignModalvisible,
  getmytaskFilterActive,
  setReviewcommmentListEmpty,
} = MyTaskSlice.actions;

export default MyTaskSlice.reducer;
