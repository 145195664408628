import { createSlice } from "@reduxjs/toolkit";

import { createObligation, deleteObligation, getObligation } from "./Obligation.Action";
const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
 
  isVisible: false,
 
  isdrawerVisible: false,
  editRecord: {},
  type: "",
  
  totalCount: null,
  ObligationPagination: {
    total: 0,
    pageSize: 20,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "updatedAt",
  },
  ObligationtableData:[]
 
};
const ObligationSlice = createSlice({
  name: "ObligationSlice",
  initialState: initialState,
  reducers: {
    setchecklistModal: (state, { payload }) => {
      state.isChecklistVisible = payload.visible;
      state.checklistModalType = payload.type || "";
    },
  
  
    setObligationModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      state.type = payload.type || "";
      if (!payload.visible) {
        state.editRecord = {};
        state.currenttaskStep = 0;
      }
    },
    onPageChangesFilter: (state, action) => {
      state.ObligationPagination = {
        ...state.ObligationPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    // createObligation: (state, { payload }) => {
    //  state.ObligationtableData.push(payload)
    // },
   
    setDrawerVisible: (state, { payload }) => {
      state.isdrawerVisible = payload.visible;
      state.DrawerRecord = payload.record;
      state.currenttaskStep = 0;
      state.TaskcommentPagination = {
        ...state.TaskcommentPagination,
        taskId: payload?.record?._id,
      };
      // if (!payload.visible) {
      // }
    },
  
  
    obligationdatareset: (state, action) => {
      return initialState;
    },
    onSearchList: (state, action) => {
      state.ObligationPagination = {
        ...state.ObligationPagination,
        search: action.payload.search || "",
      };
    },
 
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getObligation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getObligation.fulfilled, (state, { payload }) => {
        state.ObligationtableData = payload.data;
        state.isLoading = false;
        state.totalCount = payload.totalCount;
        state.ObligationPagination = {
          ...state.ObligationPagination,
          total: payload.total,
        };
      })
      .addCase(getObligation.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(createObligation.pending, (state) => {
        state.isCreateLoading = true;
      })
      .addCase(createObligation.fulfilled, (state, { payload }) => {
        state.isCreateLoading = false;
        state.isSuccess = true;
        state.isVisible = false;
      })
      .addCase(createObligation.rejected, (state) => {
        state.isCreateLoading = false;
      })
      .addCase(deleteObligation.pending, (state, { payload }) => {
        //conditions
        // responseMessageHandler()
      })
      .addCase(deleteObligation.fulfilled, (state, { payload }) => {
        //conditions
      })
      .addCase(deleteObligation.rejected, (state, { payload }) => {
        //conditions
      })
 
  }
});
export const {
  setObligationModalVisible,
  onPageChangesFilter,
  // createObligation,
  obligationdatareset,
  onSearchList,

} = ObligationSlice.actions;

export default ObligationSlice.reducer;
